import { useRecoilValue } from "recoil";
import {
  currentPage,
  languageSelected,
  cursusIdSelected,
} from "../recoil/Atomes";
import { textTranslation } from "../config/Multilangues";
import { Card } from "react-bootstrap";
import Students from "../img/home_students.svg";
import { stylizeJsonText } from "../services/stylizeJsonText";

export const HomePage = () => {
  // Page d'accueil
  const current_page = useRecoilValue(currentPage);
  const language = useRecoilValue(languageSelected);
  const cursusId = useRecoilValue(cursusIdSelected);

  const valueTextCursus =
    cursusId && current_page > 3
      ? textTranslation[language][cursusId][current_page]
      : textTranslation[language]["general"][current_page];

  return (
    <>
      <div className="col-sm-12">
        <img src={Students} alt="..." className="w-75" />
      </div>
      <div className="col-sm-12 text-start m-0 px-4">
        {valueTextCursus.default_career.filter((value, key) => key !== 2).map((value, key) => (
          <Card bsPrefix="home-card" className="mt-3 py-3 px-3" key={key}>
            <Card.Body>
              <Card.Title
                className={`home-card-title mb-2 ${key === 0
                    ? "home-card-title-color-a"
                    : "home-card-title-color-b"
                  }`}
                dangerouslySetInnerHTML={{ __html: stylizeJsonText(value.title) }}
              />
              <Card.Text className="text-white" dangerouslySetInnerHTML={{ __html: stylizeJsonText(value.paragraph) }} />
            </Card.Body>
          </Card>
        ))}
      </div>
    </>
  );
};
