import { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  currentPage,
  languageSelected,
  cursusSelected,
  yearSelected,
  cursusThirdYear,
  cursusFourthYear,
  afterDegree,
  cursusIdSelected,
  engineeringCourse,
  studentJourney,
  cursusFifthYear,
  selectDiplome,
  selectDoubleDiplome,
  selectDaFourthYear,
  isDoingTBS,
} from "../recoil/Atomes";
import { textTranslation } from "../config/Multilangues";
import { Card } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { MdClose } from "react-icons/md";
import { switchDisplayLogo } from "./Pictures";
import { stylizeJsonText } from "../services/stylizeJsonText";

export const SelectCursus = () => {
  const current_page = useRecoilValue(currentPage);
  const language = useRecoilValue(languageSelected);
  const [cursusId, setCursusId] = useRecoilState(cursusIdSelected);
  const [cursus, setCursus] = useRecoilState(cursusSelected);
  const setCursusThird = useSetRecoilState(cursusThirdYear);
  const setCursusFourth = useSetRecoilState(cursusFourthYear);
  const setEngineerCourse = useSetRecoilState(engineeringCourse);
  const setAfterDegree = useSetRecoilState(afterDegree);
  const setYear = useSetRecoilState(yearSelected);
  const setStudentJourney = useSetRecoilState(studentJourney);
  const setCursusFifth = useSetRecoilState(cursusFifthYear);
  const setSelectedDiplome = useSetRecoilState(selectDiplome);
  const setSelectDD = useSetRecoilState(selectDoubleDiplome);
  const setSelectDaFourth = useSetRecoilState(selectDaFourthYear);
  const setisTBS = useSetRecoilState(isDoingTBS);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const valueTextCursus =
    cursusId && current_page > 3
      ? textTranslation[language][cursusId][current_page]
      : textTranslation[language]["general"][current_page];

  // Sélection d'un cursus
  return (
    <>
      <div className="px-4">
        {valueTextCursus.options.map((opt, key) => (
          <Card
            key={key}
            className={"text-white fw-bold home-card px-3 py-3 mt-3 cursor"}
            style={{
              backgroundColor: `${key === cursus ? opt.background + '99' : ""}`
            }}
            onClick={() => {
              if (key !== cursus) {
                setYear("");
                setCursusFourth("");
                setAfterDegree("");
                setEngineerCourse("");
                setCursusThird("");
                setStudentJourney({ years: [] });
                setCursusFifth("");
                setSelectedDiplome("");
                setSelectDD("");
                setSelectDaFourth("");
                setisTBS("");
              }
              setCursus(key);
              setCursusId(opt.id);
            }}
          >
            <Card.Body className="d-flex justify-content-between align-items-center text-start">
              <div className="d-flex align-items-center">
                <div
                  className={"p-4 image-card-bg"}
                  style={{
                    backgroundColor: `${opt.background}33`
                  }}>
                  <img src={switchDisplayLogo(opt.logo)} alt="..." />
                </div>
                <Card.Text className="m-0 p-2 w-75 text-start">{opt.title}</Card.Text>
              </div>
              <Modal
                show={show === key}
                onHide={handleClose}
                animation={true}
                centered
                size='lg'
                dialogClassName='popin-bg'
                contentClassName='popin-content'
              >
                <Modal.Header className='border-0'>
                  <Modal.Title
                    className='fw-bold'
                    as={"p"}
                    dangerouslySetInnerHTML={{ __html: stylizeJsonText(opt.tooltipTitle) }}
                  />
                  <MdClose size={27} color='#FFC43E' className='cursor' onClick={() => setShow(false)} />
                </Modal.Header>
                <Modal.Body
                  className='pt-0'
                  dangerouslySetInnerHTML={{ __html: stylizeJsonText(opt.tooltip) }}
                />
              </Modal>
              {(opt.tooltipTitle || opt.tooltip) && (
                <div>
                  <img
                    src={switchDisplayLogo("tooltip")}
                    className='cursor'
                    alt="tooltip"
                    onClick={() => setShow(key)} />
                </div>

              )}
            </Card.Body>
          </Card>
        ))}
      </div>
      <div className="px-4">
        <div>
          {cursus !== "" ? (
            <div className="col-sm-12">
              <Card className="home-card text-white text-left mt-3 px-3 py-3">
                <Card.Body className="text-start">
                  <Card.Text dangerouslySetInnerHTML={{ __html: stylizeJsonText(valueTextCursus.options[cursus].paragraph) }} />
                </Card.Body>
              </Card>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
