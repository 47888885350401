import PURPANHALL from "../img/purpan_hall.png";
import PURPANEXT from "../img/purpan_ext.png";
import PURPANABROAD from "../img/purpan_abroad.png";
import PURPANGRADUATION from "../img/purpan_graduation.png";
import PURPANELLIPSE from "../img/purpan_ellipse.png";
import PURPANSOLIDARITY from "../img/purpan_solidarity.png"
import INTERNSHIP from "../img/internship.png";
import STARRIGHT from "../img/star_right.svg";
import STARLEFT from "../img/star_left.svg";
import PURPANFARM from "../img/purpan_farm.png";
import AGROBACHELOR from "../img/logo/agrobachelor.png";
import ENGINEER from "../img/logo/engineer.png";
import UN from "../img/logo/1.svg";
import DEUX from "../img/logo/2.svg";
import TROIS from "../img/logo/3.svg";
import INTERNSHIPFARM from "../img/logo/internship_farm.svg";
import PLANEYELLOW from "../img/logo/plane_yellow.svg";
import PLANEGREEN from "../img/logo/plane_green.svg";
import RESEARCH from "../img/logo/research.svg";
import RESEARCHGREEN from "../img/logo/research_green.svg";
import AGRICULTUREENV from "../img/logo/agriculture_environment.svg";
import FOODPRODUCTION from "../img/logo/foodproduction_supplychain.svg";
import FOODSALES from "../img/logo/food_sales.svg";
import AGROECO from "../img/logo/agroeco_transition.svg";
import LIVESTOCK from "../img/logo/livestock_consulting.svg";
import PURSUESTUDY from "../img/logo/pursue_study.svg";
import WORKFORCE from "../img/logo/workforce.svg";
import CLASSICCAREER from "../img/logo/classic_career.svg";
import ALTERNANCEYELLOW from "../img/logo/alternance_yellow.svg";
import ALTERNANCEGREEN from "../img/logo/alternance_green.svg";
import ALTERNANCEBLUE from "../img/logo/alternance_blue.svg";
import DATACOURSE from "../img/logo/data_course.svg";
import FOREIGNUNIVERSITY from "../img/logo/foreign_university.svg";
import MISSIONPED from "../img/logo/mission_ped.svg";
import GLOBALSEMESTER from "../img/logo/global_semester.svg";
import TSM from "../img/logo/tsm.svg";
import TBS from "../img/logo/tbs.svg";
import ENSFEA from "../img/logo/ensfea.svg";
import TOOLTIP from "../img/logo/tooltip.svg";
import RONDPOINT from "../img/logo/rond-point.svg";
import DD from "../img/logo/dd.svg";
import DC from "../img/logo/dc.svg";
import DDE from "../img/logo/dde.svg";
import UT2JENSFEA from "../img/logo/ut2jensfea.svg";
import DA from "../img/logo/da.svg";
import DA_ACIA from "../img/logo/da_acia.svg";
import DA_AN from "../img/logo/da_an.svg";
import DA_CEI from "../img/logo/da_cei.svg";
import DA_CI from "../img/logo/da_ci.svg";
import DA_E from "../img/logo/da_e.svg";
import DA_F from "../img/logo/da_f.svg";
import DA_IDEA from "../img/logo/da_idea.svg";
import DA_L from "../img/logo/da_l.svg";
import DA_MCPA from "../img/logo/da_mcpa.svg";
import DA_VALO from "../img/logo/da_valo.svg";
import DA_VV from "../img/logo/da_vv.svg";

// Affichage d'une image en fonction de la valeur récupérée dans le JSON
export const switchDisplayImage = (img) => {
  switch (img) {
    case "purpan_hall":
      return PURPANHALL;
    case "purpan_ext":
      return PURPANEXT;
    case "purpan_ellipse":
      return PURPANELLIPSE;
    case "purpan_abroad":
      return PURPANABROAD;
    case "purpan_farm":
      return PURPANFARM;
    case "purpan_graduation":
      return PURPANGRADUATION;
    case "purpan_solidarity":
      return PURPANSOLIDARITY;
    case "internship":
      return INTERNSHIP;
    case "star_right":
      return STARRIGHT;
    case "star_left":
      return STARLEFT;
    default:
      return "";
  }
};

export const switchDisplayLogo = (logo) => {
  switch (logo) {
    case "1":
      return UN;
    case "2":
      return DEUX;
    case "3":
      return TROIS;
    case "agriculture_environment":
      return AGRICULTUREENV;
    case "agrobachelor":
      return AGROBACHELOR;
    case "agroeco_transition":
      return AGROECO;
    case "livestock_consulting":
      return LIVESTOCK;
    case "internship_farm":
      return INTERNSHIPFARM;
    case "alternance_yellow":
      return ALTERNANCEYELLOW;
    case "alternance_green":
      return ALTERNANCEGREEN;
    case "alternance_blue":
      return ALTERNANCEBLUE;
    case "classic_career":
      return CLASSICCAREER;
    case "data_course":
      return DATACOURSE;
    case "engineer":
      return ENGINEER;
    case "rond-point":
      return RONDPOINT;
    case "food_sales":
      return FOODSALES;
    case "foodproduction_supplychain":
      return FOODPRODUCTION;
    case "foreign_university":
      return FOREIGNUNIVERSITY;
    case "global_semester":
      return GLOBALSEMESTER;
    case "mission_ped":
      return MISSIONPED;
    case "plane_green":
      return PLANEGREEN;
    case "plane_yellow":
      return PLANEYELLOW;
    case "research_green":
      return RESEARCHGREEN;
    case "tooltip":
      return TOOLTIP;
    case "research":
      return RESEARCH;
    case "ensfea":
      return ENSFEA;
    case "tbs":
      return TBS;
    case "tsm":
      return TSM;
    case "pursue_study":
      return PURSUESTUDY;
    case "workforce":
      return WORKFORCE;
    case "dc":
      return DC;
    case "dd":
      return DD;
    case "dde":
      return DDE;
    case "ut2jensfea":
      return UT2JENSFEA;
    case "da":
      return DA;
    case "da_acia":
      return DA_ACIA;
    case "da_an":
      return DA_AN;
    case "da_cei":
      return DA_CEI;
    case "da_ci":
      return DA_CI;
    case "da_elevage":
      return DA_E;
    case "da_foret":
      return DA_F;
    case "da_idea":
      return DA_IDEA;
    case "da_luxe":
      return DA_L;
    case "da_mcpa":
      return DA_MCPA;
    case "da_valo":
      return DA_VALO;
    case "da_vv":
      return DA_VV;
    default:
      return "";
  }
};
