import { useRecoilValue } from "recoil";
import {
  currentPage,
  languageSelected,
  cursusIdSelected,
} from "../recoil/Atomes";
import { textTranslation } from "../config/Multilangues";
import { formatExposant } from "../services/stylizeJsonText";

// Composant affichant le titre de la page
export const TitleComponent = () => {
  const cursusId = useRecoilValue(cursusIdSelected);
  const current_page = useRecoilValue(currentPage);
  const language = useRecoilValue(languageSelected);


  const valueTextCursus =
    cursusId && current_page > 3
      ? textTranslation[language][cursusId][current_page]
      : textTranslation[language]["general"][current_page];

  return (
    current_page !== 0 ? (
      // Si la page actuelle correspond à 15, le titre ne s'affiche pas
      <div className={`col-sm-12 px-0 ${current_page === 15 ? "d-none" : ""}`}> 
      <h2
        className={
          current_page !== 1
            ? "fs-4 fw-bold pb-2 px-4 text-white text-center"
            : "fs-6 px-3 py-4 text-white text-center"
        }
      >
        {(valueTextCursus &&
          valueTextCursus.title &&
          formatExposant(valueTextCursus.title)) ||
          ""}
      </h2>
    </div>
    
    ) : ("")
 
  );
};
