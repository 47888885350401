import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();

export const languageSelected = atom({
  // GENERAL : LA LANGUE
  key: "languageText",
  default: "FR",
  effects_UNSTABLE: [persistAtom],
});

export const parcours = atom({
  // GENERAL : Parcours réalisés à envoyer pour le PDF
  key: "parcours",
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const characterSelected = atom({
  // GENERAL : LE PERSONNAGE
  key: "character",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const currentPage = atom({
  // GENERAL : PAGE ACTUELLE
  key: "page",
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

export const cursusSelected = atom({
  // GENERAL : PARCOURS INGÉNIEUR OU AGRO
  key: "cursus",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const cursusIdSelected = atom({
  // GENERAL : PARCOURS INGÉNIEUR OU AGRO
  key: "cursusId",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const cursusThirdYear = atom({
  // INGE : PARCOURS FISE OU FISA POUR LA TROISIEME
  key: "cursusThirdYear",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const cursusFourthYear = atom({
  // INGE : PARCOURS FISE OU FISA POUR LA QUATRIEME OU AGRO : L'OPTION DU PARCOURS AGRICULTURE ET ENVIRONMENT
  key: "cursusFourthYear",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const cursusFifthYear = atom({
  // INGE : PARCOURS FISE OU FISA POUR LA CINQUIEME
  key: "cursusFifthYear",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const yearAlternance = atom({
  // INGE : Année de départ pour le parcours FISA
  key: "yearAlternance  ",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const selectDiplome = atom({
  // INGE : DIPLOME CLASSIQUE OU DOUBLE DIPLOME OU DOUBLE DIPLOME A L'ETRANGER
  key: "selectDiplome",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const selectDoubleDiplome = atom({
  // INGE : DOUBLE DIPLOME
  key: "selectDoubleDiplome",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const engineeringCourse = atom({
  // INGE : DOMAINE PRINCIPAL / ORIENTE DE L'ANNEE
  key: "engineeringCourse",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const isDoingTBS = atom({
  // INGE : PART EN TBS DONC POUR UNE 6EME ANNEE
  key: "isDoingTBS",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const selectDaFourthYear = atom({
  // INGE : DOMAINE D'APPROFONDISSEMENT 4 EME ANNEE
  key: "selectDaFourthYear",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const afterDegree = atom({
  // GENERAL : CONTINUER LES ETUDES OU TRAVAILLER
  key: "afterDegree",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const yearSelected = atom({
  // GENERAL : ANNEE DE DEPART
  key: "year",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const email = atom({
  key: "email",
  default: "",
});
export const rgpd = atom({
  key: "rgpd",
  default: false,
});
export const lname = atom({
  key: "lastname",
  default: "",
});
export const fname = atom({
  key: "firstname",
  default: "",
});

export const errorPdf = atom({
  key: "errorPdf",
  default: "",
});
export const studentJourney = atom({
  key: "studentJourney",
  default: { years: [] },
  effects_UNSTABLE: [persistAtom],
});
export const studentShortJourney = atom({
  key: "studentShortJourney",
  default: [],
  effects_UNSTABLE: [persistAtom],
});