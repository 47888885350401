import React from "react";
import { Button, Card } from "react-bootstrap";
import Content from "../components/Content";
import { stylizeJsonText } from "../services/stylizeJsonText";
import { MdOutlineExplore } from "react-icons/md";
import {
  currentPage,
  cursusSelected,
  yearSelected,
  cursusThirdYear,
  cursusFourthYear,
  afterDegree,
  email,
  rgpd,
  studentJourney,
  engineeringCourse,
  errorPdf,
  languageSelected,
  characterSelected,
  cursusIdSelected,
  selectDiplome,
  cursusFifthYear,
  selectDaFourthYear,
  isDoingTBS,
  selectDoubleDiplome,
  fname,
  lname,
  parcours,
  studentShortJourney,
} from "../recoil/Atomes";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Header from "../features/Header";
import BottomBar from "./BottomBar";
import { MdWest } from "react-icons/md";
import { MdEast } from "react-icons/md";
import { textTranslation } from "../config/Multilangues";

export default function Home() {
  const setErrorMessage = useSetRecoilState(errorPdf);
  const [current_page, setCurrentPage] = useRecoilState(currentPage);
  const [student_Journey, setStudentJourney] = useRecoilState(studentJourney);
  const [student_short_journey, setStudentShortJourney] =
    useRecoilState(studentShortJourney);
  const [parcoursEnCours, setParcoursEnCours] = useRecoilState(parcours);
  const cursus = useRecoilValue(cursusSelected);
  const character = useRecoilValue(characterSelected);
  const [year, setYear] = useRecoilState(yearSelected);
  const [cursusThird, setCursusThird] = useRecoilState(cursusThirdYear);
  const selectedDiplome = useRecoilValue(selectDiplome);
  const [cursusFourth, setCursusFourth] = useRecoilState(cursusFourthYear);
  const isTBS = useRecoilValue(isDoingTBS);
  const selectDD = useRecoilValue(selectDoubleDiplome);
  const selectDaFourth = useRecoilValue(selectDaFourthYear);
  const afDegree = useRecoilValue(afterDegree);
  const language = useRecoilValue(languageSelected);
  const firstname = useRecoilValue(fname);
  const lastname = useRecoilValue(lname);
  const emailStudent = useRecoilValue(email);
  const checked = useRecoilValue(rgpd);
  const cursusId = useRecoilValue(cursusIdSelected);
  const [engineerCourse, setEngineerCourse] = useRecoilState(engineeringCourse);
  const [cursusFifth, setCursusFifth] = useRecoilState(cursusFifthYear);

  // Matomo <

  const _mtm_push_navigation = (action, mtm_current_page) => {
    var mtm_valueTextCursus =
      cursusId && current_page > 3
        ? textTranslation[language][cursusId][mtm_current_page]
        : textTranslation[language]["general"][mtm_current_page];

    var mtm_valueTextCursus_title = "";
    if (mtm_valueTextCursus !== undefined) {
      mtm_valueTextCursus_title = mtm_valueTextCursus.title;
    }

    if (mtm_valueTextCursus_title !== "") {
      // Navigation next
      window._paq.push([
        "trackEvent",
        "Navigation",
        action,
        cursusId + " : " + mtm_valueTextCursus_title,
        null,
      ]);
    }
  };

  const _mtm_push_option = (choice) => {
    if (cursusId) {
      window._paq.push([
        "trackEvent",
        "Cursus",
        "Option",
        cursusId + " : " + choice,
        null,
      ]);
    }
  };

  // Matomo >

  // C'est ici qu'on ajoute les identifiants des pages et leur variable qui ne doivent pas pouvoir aller à la prochaine page sans avoir faits un choix
  // console.log(current_page, "current_page")
  // console.log(parcoursEnCours)
  const switchButtonDisabled = () => {
    switch (current_page) {
      case 1:
        return character === "";
      case 3:
        return cursus === "";
      case 4:
        return year === "";
      case 10:
      case 81:
      case 93:
        return cursusThird === "";
      case 11:
      case 94:
      case 111:
        return cursusFourth === "";
      case 14:
        return afDegree === "";
      case 15:
        // Motif du mail
        var patternEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Vérifier si les champs sur formulaires sont valides
        if (
          lastname === "" &&
          firstname === "" &&
          !patternEmail.test(emailStudent) === true &&
          !checked === true
        ) {
          return true;
        } else if (
          lastname !== "" &&
          firstname !== "" &&
          !patternEmail.test(emailStudent) === false &&
          !checked === false
        ) {
          return false;
        }
        return true;
      case 16:
        return true;
      case 17:
        return engineerCourse === "";
      case 171:
        return selectedDiplome === "";
      case 172:
        return selectDD === "";
      case 174:
        return selectDaFourth === "";
      case 180:
        return cursusFifth === "";
      default:
        return false;
    }
  };

  // Ici on gère les cas particuliers des pages lorsque les informations de la page précèdente est reliée à un choix
  const switchPreviousPage = () => {
    var tmpTab = [...parcoursEnCours];
    tmpTab.pop();
    setParcoursEnCours(tmpTab);
    setErrorMessage("");
    if (current_page <= 4) {
      setCursusThird("");
      setCursusFourth("");
      setEngineerCourse("");
      setEngineerCourse("");
      setCursusFifth("");
      setStudentShortJourney([]);
      setStudentJourney({
        years: [],
      });
      setYear("");
    }
    var valueTextCursus =
      cursusId && current_page > 3
        ? textTranslation[language][cursusId][current_page]
        : textTranslation[language]["general"][current_page];

    switch (current_page) {
      case 3:
        // Matomo <

        _mtm_push_navigation(
          "Previous",
          valueTextCursus.options[cursus || 0].previous
        );

        // Matomo >

        return setCurrentPage(valueTextCursus.options[cursus || 0].previous);
      case 4:
      case 7:
      case 9:
        var yearIndex = year ? year - 1 : "";

        // Matomo <

        _mtm_push_navigation(
          "Previous",
          valueTextCursus.options[yearIndex || 0].previous
        );

        // Matomo >

        return setCurrentPage(valueTextCursus.options[yearIndex || 0].previous);
      case 10:
      case 91:
      case 81:
        // Matomo <

        _mtm_push_navigation(
          "Previous",
          valueTextCursus.options[cursusThird || 0].previous
        );

        // Matomo >

        return setCurrentPage(
          valueTextCursus.options[cursusThird || 0].previous
        );
      case 80:
      case 82:
      case 12:
        // Matomo <

        _mtm_push_navigation(
          "Previous",
          valueTextCursus.options[cursusThird].previous
        );

        // Matomo >

        return setCurrentPage(valueTextCursus.options[cursusThird].previous);
      case 111:
        // Matomo <

        _mtm_push_navigation(
          "Previous",
          valueTextCursus.options[cursusFourth || 0].previous
        );

        // Matomo >

        return setCurrentPage(
          valueTextCursus.options[cursusFourth || 0].previous
        );
      case 13:
        if (isTBS && cursusFifth !== 1) {
          // Matomo <

          _mtm_push_navigation("Previous", valueTextCursus.tbs.previous);

          // Matomo >

          return setCurrentPage(valueTextCursus.tbs.previous);
        }
        if (cursus === 0) {
          // Matomo <

          _mtm_push_navigation(
            "Previous",
            valueTextCursus.options[cursusThird].previous
          );

          // Matomo >

          return setCurrentPage(valueTextCursus.options[cursusThird].previous);
        } else {
          // Matomo <

          _mtm_push_navigation(
            "Previous",
            valueTextCursus.options[cursusFifth || cursusFourth || cursusThird]
              .previous
          );

          // Matomo >

          return setCurrentPage(
            valueTextCursus.options[cursusFifth || cursusFourth || cursusThird]
              .previous
          );
        }
      case 14:
        // Matomo <

        _mtm_push_navigation(
          "Previous",
          valueTextCursus.options[afDegree || 0].previous
        );

        // Matomo >

        return setCurrentPage(valueTextCursus.options[afDegree || 0].previous);
      case 17:
        // Matomo <

        _mtm_push_navigation(
          "Previous",
          valueTextCursus[cursusFourth || cursusThird][engineerCourse || 0]
            .previous
        );

        // Matomo >

        return setCurrentPage(
          valueTextCursus[cursusFourth || cursusThird][engineerCourse || 0]
            .previous
        );
      case 171:
        // Matomo <

        _mtm_push_navigation(
          "Previous",
          valueTextCursus.options[selectedDiplome || 0].previous
        );

        // Matomo >

        return setCurrentPage(
          valueTextCursus.options[selectedDiplome || 0].previous
        );
      case 172:
        // Matomo <

        _mtm_push_navigation(
          "Previous",
          valueTextCursus.options[selectDD || 0].previous
        );

        // Matomo >

        return setCurrentPage(valueTextCursus.options[selectDD || 0].previous);
      case 173:
        // Matomo <

        _mtm_push_navigation(
          "Previous",
          valueTextCursus[cursusFourth || cursusThird || 0][
            selectedDiplome || 0
          ].previous
        );

        // Matomo >

        return setCurrentPage(
          valueTextCursus[cursusFourth || cursusThird || 0][
            selectedDiplome || 0
          ].previous
        );
      case 174:
        // Matomo <

        _mtm_push_navigation(
          "Previous",
          valueTextCursus.options[selectDaFourth || 0].previous
        );

        // Matomo >

        return setCurrentPage(
          valueTextCursus.options[selectDaFourth || 0].previous
        );
      case 175:
        // Matomo <

        _mtm_push_navigation(
          "Previous",
          valueTextCursus.options[isTBS || 0].previous
        );

        // Matomo >

        return setCurrentPage(valueTextCursus.options[isTBS || 0].previous);
      case 18:
        // Matomo <

        _mtm_push_navigation(
          "Previous",
          valueTextCursus.options[cursusThird || 0].previous
        );

        // Matomo >

        // Gérer l'affichage du parcours Mission Solidarité internationale lors d'un retour en arrière
        if (engineerCourse === 2) {
          return setCurrentPage(
            valueTextCursus.options[cursusThird || 0].previous2
          );
        } else {
          return setCurrentPage(
            valueTextCursus.options[cursusThird || 0].previous
          );
        }
      case 180:
        // Matomo <

        _mtm_push_navigation(
          "Previous",
          valueTextCursus.options[cursusFifth || 0].previous
        );

        // Matomo >

        return setCurrentPage(
          valueTextCursus.options[cursusFifth || 0].previous
        );
      case 181:
        // Matomo <

        _mtm_push_navigation(
          "Previous",
          valueTextCursus[isTBS || 0][0].previous
        );

        // Matomo >

        return setCurrentPage(valueTextCursus[isTBS || 0][0].previous);
      case 182:
        // Matomo <

        _mtm_push_navigation(
          "Previous",
          valueTextCursus.options[
            cursusFifth || cursusFourth || cursusThird || 0
          ].previous
        );

        // Matomo >

        return setCurrentPage(
          valueTextCursus.options[
            cursusFifth || cursusFourth || cursusThird || 0
          ].previous
        );
      case 183:
        // Matomo <

        _mtm_push_navigation(
          "Previous",
          valueTextCursus[cursusFifth || cursusFourth || cursusThird || 0][
            cursusFifth || 0
          ].previous
        );

        // Matomo >

        return setCurrentPage(
          valueTextCursus[cursusFifth || cursusFourth || cursusThird || 0][
            cursusFifth || 0
          ].previous
        );
      case 184:
        // Matomo <

        _mtm_push_navigation(
          "Previous",
          valueTextCursus.options[isTBS || 0].previous
        );

        // Matomo >

        return setCurrentPage(valueTextCursus.options[isTBS || 0].previous);
      default:
        // Matomo <

        _mtm_push_navigation("Previous", valueTextCursus.previous);

        // Matomo >

        return setCurrentPage(valueTextCursus.previous);
    }
  };

  // Ici on gère les cas particuliers des pages lorsque les informations de la page suivante est reliée à un choix
  const switchNextPage = () => {
    // Matomo <

    //console.log('current_page');
    //console.log(current_page);

    var mtm_character_name = "";
    if (character !== undefined) {
      mtm_character_name = character.name;
    }

    if (current_page === 1) {
      // Choix de l'Avatar
      window._paq.push([
        "trackEvent",
        "Avatar",
        "Choice",
        mtm_character_name,
        null,
      ]);
    }

    if (current_page === 3) {
      // Choix du cursus
      window._paq.push(["trackEvent", "Cursus", "Start", cursusId, null]);
    }

    if (current_page === 14) {
      // Fin du cursus
      window._paq.push(["trackEvent", "Cursus", "End", cursusId, null]);
    }

    // Matomo >

    setErrorMessage("");

    if (current_page < 4) {
      setCursusThird("");
      setCursusFourth("");
      setEngineerCourse("");
      setEngineerCourse("");
      setCursusFifth("");
      setStudentShortJourney([]);
      setStudentJourney({
        years: [],
      });
    }
    var valueTextCursus =
      cursusId && current_page > 3
        ? textTranslation[language][cursusId][current_page]
        : textTranslation[language]["general"][current_page];

    switch (current_page) {
      case 3:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            logo: valueTextCursus.options[cursus].logo,
            titleResponse: valueTextCursus.options[cursus].title,
            paragraph: valueTextCursus.options[cursus].paragraph,
          },
        ]);

        // Matomo <

        _mtm_push_option(valueTextCursus.options[cursus].title);
        _mtm_push_navigation("Next", valueTextCursus.options[cursus].next);

        // Matomo >

        return setCurrentPage(valueTextCursus.options[cursus].next);
      case 4:
        var yearIndex = year ? year - 1 : "";
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            logo: valueTextCursus.options[yearIndex].logo,
            titleResponse: valueTextCursus.options[yearIndex].title,
            paragraph: valueTextCursus.options[yearIndex].paragraph,
          },
        ]);

        // Matomo <

        _mtm_push_option(valueTextCursus.options[yearIndex].title);
        _mtm_push_navigation("Next", valueTextCursus.options[yearIndex].next);

        // Matomo >

        return setCurrentPage(valueTextCursus.options[yearIndex].next);
      case 7:
      case 9:
        var yearIndex = year ? year - 1 : "";
        if (
          yearIndex === 0 ||
          (yearIndex === 1 && current_page !== 7) ||
          (yearIndex === 2 && current_page !== 9)
        ) {
          setParcoursEnCours([
            ...parcoursEnCours,
            {
              page: current_page,
              title: valueTextCursus.title,
              logo: valueTextCursus.year,
              // titleResponse: valueTextCursus.title,
              paragraph: valueTextCursus.paragraph,
            },
          ]);
        }

        // Matomo <

        //_mtm_push_option();
        _mtm_push_navigation(
          "Next",
          valueTextCursus.options[yearIndex || 0].next
        );

        // Matomo >

        return setCurrentPage(valueTextCursus.options[yearIndex || 0].next);
      case 80:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title:
              valueTextCursus.title ||
              valueTextCursus.options[cursusThird].title,
            logo: valueTextCursus.year,
            titleResponse: valueTextCursus.title2,
            paragraph: valueTextCursus.paragraph,
          },
        ]);
        return setCurrentPage(valueTextCursus.options[cursusThird || 0].next);
      case 91:
      case 93:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            logo: valueTextCursus.options[cursusThird].logo,
            titleResponse: valueTextCursus.options[cursusThird].title,
            paragraph: valueTextCursus.paragraph,
          },
        ]);

        // Matomo <

        _mtm_push_option(valueTextCursus.options[cursusThird].title);
        _mtm_push_navigation(
          "Next",
          valueTextCursus.options[cursusThird || 0].next
        );

        // Matomo >

        return setCurrentPage(valueTextCursus.options[cursusThird || 0].next);
      case 92:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            logo: valueTextCursus.options[cursusFourth].logo,
            titleResponse: valueTextCursus.options[cursusFourth].title,
            paragraph: valueTextCursus.paragraph,
          },
        ]);

        // Matomo <

        _mtm_push_option(valueTextCursus.options[cursusFourth].title);
        _mtm_push_navigation("Next", valueTextCursus.next);

        // Matomo >

        return setCurrentPage(valueTextCursus.next);
      case 10:
      case 81:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            logo: valueTextCursus.options[cursusThird || 0].logo,
            titleResponse: valueTextCursus.options[cursusThird || 0].title,
            paragraph: valueTextCursus.options[cursusThird || 0].paragraph,
          },
        ]);

        // Matomo <

        _mtm_push_option(valueTextCursus.options[cursusThird || 0].title);
        _mtm_push_navigation(
          "Next",
          valueTextCursus.options[cursusThird || 0].next
        );

        // Matomo >

        return setCurrentPage(valueTextCursus.options[cursusThird || 0].next);
      case 82:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.options[cursusThird].title,
            logo: valueTextCursus.options[cursusThird].logo,
            paragraph: valueTextCursus.options[cursusThird].paragraph,
          },
        ]);

        // Matomo <

        //_mtm_push_option();
        _mtm_push_navigation("Next", valueTextCursus.options[cursusThird].next);

        // Matomo >

        return setCurrentPage(valueTextCursus.options[cursusThird].next);
      case 12:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            logo: valueTextCursus.logo,
            paragraph: valueTextCursus.title2,
          },
        ]);

        // Matomo <

        //_mtm_push_option();
        _mtm_push_navigation("Next", valueTextCursus.options[cursusThird].next);

        // Matomo >

        return setCurrentPage(valueTextCursus.options[cursusThird].next);
      case 11:
      case 94:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            logo: valueTextCursus.options[cursusFourth].logo,
            titleResponse: valueTextCursus.options[cursusFourth].title,
            paragraph: valueTextCursus.options[cursusFourth].paragraph,
          },
        ]);

        // Matomo <

        _mtm_push_option(valueTextCursus.options[cursusFourth].title);
        _mtm_push_navigation("Next", valueTextCursus.next);

        // Matomo >

        return setCurrentPage(valueTextCursus.next);
      case 111:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            logo: valueTextCursus.options[cursusFourth || 0].logo,
            titleResponse: valueTextCursus.options[cursusFourth || 0].title,
            paragraph: valueTextCursus.options[cursusFourth || 0].paragraph,
          },
        ]);

        // Matomo <

        _mtm_push_option(valueTextCursus.options[cursusFourth || 0].title);
        _mtm_push_navigation(
          "Next",
          valueTextCursus.options[cursusFourth || 0].next
        );

        // Matomo >

        return setCurrentPage(valueTextCursus.options[cursusFourth || 0].next);
      case 13:
        if (isTBS) {
          setParcoursEnCours([
            ...parcoursEnCours,
            {
              page: current_page,
              title: valueTextCursus.title,
              paragraph: valueTextCursus.tbsParagraph,
            },
          ]);

          // Matomo <

          //_mtm_push_option(valueTextCursus.title);
          _mtm_push_navigation("Next", valueTextCursus.tbs.next);

          // Matomo >

          return setCurrentPage(valueTextCursus.tbs.next);
        }
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            paragraph:
              cursus === 0
                ? cursusThird === 0
                  ? valueTextCursus.paragraph[cursusThird][cursusFourth]
                  : valueTextCursus.paragraph[cursusThird]
                : isTBS
                  ? valueTextCursus.tbsParagraph
                  : valueTextCursus.paragraph[engineerCourse],
          },
        ]);
        if (cursus === 0) {
          // Matomo <

          //_mtm_push_option(valueTextCursus.title);
          _mtm_push_navigation(
            "Next",
            valueTextCursus.options[cursusThird].next
          );

          // Matomo >

          return setCurrentPage(valueTextCursus.options[cursusThird].next);
        } else {
          // Matomo <

          //_mtm_push_option();
          _mtm_push_navigation(
            "Next",
            valueTextCursus.options[cursusFifth || cursusFourth || cursusThird]
              .next
          );

          // Matomo >

          return setCurrentPage(
            valueTextCursus.options[cursusFifth || cursusFourth || cursusThird]
              .next
          );
        }
      case 14:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            logo: valueTextCursus.options[afDegree || 0].logo,
            titleResponse: valueTextCursus.options[afDegree || 0].title,
            paragraph: valueTextCursus.options[afDegree || 0].paragraph,
          },
        ]);

        // Matomo <

        _mtm_push_option(valueTextCursus.options[afDegree || 0].title);
        _mtm_push_navigation(
          "Next",
          valueTextCursus.options[afDegree || 0].next
        );

        // Matomo >

        return setCurrentPage(valueTextCursus.options[afDegree || 0].next);
      case 17:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            logo: valueTextCursus[cursusFourth || cursusThird][
              engineerCourse || 0
            ].logo,
            titleResponse:
              valueTextCursus[cursusFourth || cursusThird][engineerCourse || 0]
                .title,
            paragraph:
              valueTextCursus[cursusFourth || cursusThird][engineerCourse || 0]
                .paragraph,
          },
        ]);

        // Matomo <

        _mtm_push_option(
          valueTextCursus[cursusFourth || cursusThird][engineerCourse || 0]
            .title
        );
        _mtm_push_navigation(
          "Next",
          valueTextCursus[cursusFourth || cursusThird][engineerCourse || 0].next
        );

        // Matomo >

        return setCurrentPage(
          valueTextCursus[cursusFourth || cursusThird][engineerCourse || 0].next
        );
      case 170:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            titleResponse: valueTextCursus.title,
            logo: valueTextCursus.year,
            paragraph: valueTextCursus.paragraph,
          },
        ]);

        // Matomo <

        _mtm_push_option(valueTextCursus.title);
        _mtm_push_navigation(
          "Next",
          valueTextCursus[cursusFourth || cursusThird].next
        );

        // Matomo >

        return setCurrentPage(
          valueTextCursus[cursusFourth || cursusThird].next
        );
      case 171:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            logo: valueTextCursus.options[selectedDiplome || 0].logo,
            titleResponse: valueTextCursus.options[selectedDiplome || 0].title,
            paragraph: valueTextCursus.options[selectedDiplome || 0].paragraph,
          },
        ]);

        // Matomo <

        _mtm_push_option(valueTextCursus.options[selectedDiplome || 0].title);
        _mtm_push_navigation(
          "Next",
          valueTextCursus.options[selectedDiplome || 0].next
        );

        // Matomo >

        return setCurrentPage(
          valueTextCursus.options[selectedDiplome || 0].next
        );
      case 172:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            logo: valueTextCursus.options[selectDD || 0].logo,
            titleResponse: valueTextCursus.options[selectDD || 0].title,
            paragraph: valueTextCursus.options[selectDD || 0].paragraph,
          },
        ]);

        // Matomo <

        _mtm_push_option(valueTextCursus.options[selectDD || 0].title);
        _mtm_push_navigation(
          "Next",
          valueTextCursus.options[selectDD || 0].next
        );

        // Matomo >

        return setCurrentPage(valueTextCursus.options[selectDD || 0].next);
      case 173:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            logo: valueTextCursus.logo,
            paragraph: valueTextCursus.paragraph,
          },
        ]);

        // Matomo <

        //_mtm_push_option();
        _mtm_push_navigation(
          "Next",
          valueTextCursus[cursusFourth || cursusThird || 0][
            selectedDiplome || 0
          ].next
        );

        // Matomo >

        return setCurrentPage(
          valueTextCursus[cursusFourth || cursusThird || 0][
            selectedDiplome || 0
          ].next
        );
      case 174:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            logo: valueTextCursus.options[selectDaFourth || 0].logo,
            titleResponse: valueTextCursus.options[selectDaFourth || 0].title,
            paragraph: valueTextCursus.options[selectDaFourth || 0].paragraph,
          },
        ]);

        // Matomo <

        _mtm_push_option(valueTextCursus.options[selectDaFourth || 0].title);
        _mtm_push_navigation(
          "Next",
          valueTextCursus.options[selectDaFourth || 0].next
        );

        // Matomo >

        // Si le parcours choisi est Mission de Solidarité internationale, on change l'affichage
        if (engineerCourse === 2) {
          return setCurrentPage(
            valueTextCursus.options[selectDaFourth || 0].next2
          );
        } else {
            return setCurrentPage(
              valueTextCursus.options[selectDaFourth || 0].next
            );
        }
      case 175:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            logo: valueTextCursus[cursusFourth || cursusThird][engineerCourse]
              .logo,
            titleResponse:
              valueTextCursus[cursusFourth || cursusThird][engineerCourse]
                .title,
            paragraph:
              valueTextCursus[cursusFourth || cursusThird][engineerCourse]
                .paragraph,
          },
        ]);

        // Matomo <

        _mtm_push_option(
          valueTextCursus[cursusFourth || cursusThird][engineerCourse].title
        );
        _mtm_push_navigation("Next", valueTextCursus.options[isTBS || 0].next);

        // Matomo >

        return setCurrentPage(valueTextCursus.options[isTBS || 0].next);
      case 18:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            logo: valueTextCursus.year,
            paragraph: valueTextCursus.paragraph[engineerCourse],
          },
        ]);

        // Matomo <

        //_mtm_push_option();
        _mtm_push_navigation(
          "Next",
          valueTextCursus.options[cursusFourth || cursusThird || 0].next
        );

        // Matomo >

        return setCurrentPage(
          valueTextCursus.options[cursusFourth || cursusThird || 0].next
        );
      case 180:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.options[cursusFifth || 0].title,
            logo: valueTextCursus.options[cursusFifth || 0].logo,
            paragraph: valueTextCursus.options[cursusFifth || 0].paragraph,
          },
        ]);

        // Matomo <

        //_mtm_push_option();
        _mtm_push_navigation(
          "Next",
          valueTextCursus.options[cursusFifth || 0].next
        );

        // Matomo >

        return setCurrentPage(valueTextCursus.options[cursusFifth || 0].next);
      case 181:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            titleResponse: valueTextCursus.options[selectedDiplome].title,
            logo: valueTextCursus.options[selectedDiplome].logo,
            paragraph: valueTextCursus.options[selectedDiplome].paragraph,
          },
        ]);

        // Matomo <

        _mtm_push_option(valueTextCursus.options[selectedDiplome].title);
        _mtm_push_navigation("Next", valueTextCursus[isTBS || 0][0].next);

        // Matomo >

        return setCurrentPage(valueTextCursus[isTBS || 0][0].next);
      case 182:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            logo: valueTextCursus.logo,
            paragraph: valueTextCursus.paragraph,
          },
        ]);

        // Matomo <

        //_mtm_push_option();
        _mtm_push_navigation(
          "Next",
          valueTextCursus.options[
            cursusFifth || cursusFourth || cursusThird || 0
          ].next
        );

        // Matomo >

        return setCurrentPage(
          valueTextCursus.options[
            cursusFifth || cursusFourth || cursusThird || 0
          ].next
        );
      case 183:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            paragraph: valueTextCursus.paragraph,
          },
        ]);

        // Matomo <

        //_mtm_push_option();
        _mtm_push_navigation(
          "Next",
          valueTextCursus[cursusFifth || cursusFourth || cursusThird || 0][
            cursusFifth || 0
          ].next
        );

        // Matomo >

        return setCurrentPage(
          valueTextCursus[cursusFifth || cursusFourth || cursusThird || 0][
            cursusFifth || 0
          ].next
        );
      case 184:
        setParcoursEnCours([
          ...parcoursEnCours,
          {
            page: current_page,
            title: valueTextCursus.title,
            titleResponse: valueTextCursus.title2,
            logo: valueTextCursus.logo,
            paragraph: valueTextCursus.paragraph,
          },
        ]);

        // Matomo <

        _mtm_push_option(valueTextCursus.title2);
        _mtm_push_navigation("Next", valueTextCursus.options[isTBS || 0].next);

        // Matomo >

        return setCurrentPage(valueTextCursus.options[isTBS || 0].next);
      default:
        if (current_page !== 5) {
          setParcoursEnCours([
            ...parcoursEnCours,
            {
              page: current_page,
              title: valueTextCursus.title,
              titleResponse: valueTextCursus.title2,
              logo: valueTextCursus.logo
                ? valueTextCursus.logo
                : valueTextCursus.year,
              paragraph: valueTextCursus.paragraph,
            },
          ]);
        }

        // Matomo <

        _mtm_push_option(valueTextCursus.title2);
        _mtm_push_navigation("Next", valueTextCursus.next);

        // Matomo >

        return setCurrentPage(valueTextCursus.next);
    }
  };

  React.useEffect(() => {
    switch (current_page) {
      case 7:
        if (!student_Journey.years.includes(2)) {
          setStudentJourney((prevState) => ({
            ...prevState,
            years: [...prevState.years, 2],
          }));
        }
        break;
      case 9:
        if (!student_Journey.years.includes(3)) {
          setStudentJourney((prevState) => ({
            ...prevState,
            years: [...prevState.years, 3],
          }));
        }
        break;
      case 170:
        if (!student_Journey.years.includes(4)) {
          setStudentJourney((prevState) => ({
            ...prevState,
            years: [...prevState.years, 4],
          }));
        }
        break;
      case 18:
        if (!student_Journey.years.includes(5)) {
          setStudentJourney((prevState) => ({
            ...prevState,
            years: [...prevState.years, 5],
          }));
        }
        break;
      case 185:
        if (!student_Journey.years.includes(6)) {
          setStudentJourney((prevState) => ({
            ...prevState,
            years: [...prevState.years, 6],
          }));
        }
        break;
      case 6:
        if (cursus === 1) {
          if (!student_short_journey.includes(1)) {
            setStudentShortJourney((prevState) => [...prevState, 1]);
          }
        } else {
          if (!student_short_journey.includes(2)) {
            setStudentShortJourney((prevState) => [...prevState, 2]);
          }
        }
        break;
      case 660:
        if (!student_short_journey.includes(2)) {
          setStudentShortJourney((prevState) => [...prevState, 2]);
        }
        break;
      case 770:
        if (!student_short_journey.includes(3)) {
          setStudentShortJourney((prevState) => [...prevState, 3]);
        }
        break;
      case 8:
        if (!student_short_journey.includes(4)) {
          setStudentShortJourney((prevState) => [...prevState, 4]);
        }
        break;
      case 101:
        if (!student_short_journey.includes(5)) {
          setStudentShortJourney((prevState) => [...prevState, 5]);
        }
        break;
      case 110:
        if (!student_short_journey.includes(6)) {
          setStudentShortJourney((prevState) => [...prevState, 6]);
        }
        break;
      case 111:
        if (!student_short_journey.includes(7)) {
          setStudentShortJourney((prevState) => [...prevState, 7]);
        }
        break;
      case 55:
        // Pour le cursus agrobachelor
        if (!student_short_journey.includes(1)) {
          setStudentShortJourney((prevState) => [...prevState, 1]);
        }
        break;
      case 77:
        // Pour le cursus agrobachelor
        if (!student_short_journey.includes(3)) {
          setStudentShortJourney((prevState) => [...prevState, 3]);
        }
        break;
      case 80:
        // Pour le cursus agrobachelor
        if (!student_short_journey.includes(5)) {
          setStudentShortJourney((prevState) => [...prevState, 5]);
        }
        break;
      case 89:
        // Pour le cursus agrobachelor
        if (!student_short_journey.includes(6)) {
          setStudentShortJourney((prevState) => [...prevState, 6]);
        }
        break;
      case 91:
      case 93:
        // Pour le cursus agrobachelor
        if (!student_short_journey.includes(7)) {
          setStudentShortJourney((prevState) => [...prevState, 7]);
        }
        break;
      case 184:
        // Pour le cursus ingenieur
        if (!student_short_journey.includes(8)) {
          setStudentShortJourney((prevState) => [...prevState, 8]);
        }
        break;
      case 175:
        // Pour le cursus ingenieur
        if (!student_short_journey.includes(9)) {
          setStudentShortJourney((prevState) => [...prevState, 9]);
        }
        break;
      case 183:
      case 1844:
        // Pour le cursus ingenieur
        if (!student_short_journey.includes(10)) {
          setStudentShortJourney((prevState) => [...prevState, 10]);
        }
        break;
      case 186:
        // Pour le cursus ingenieur
        if (!student_short_journey.includes(11)) {
          setStudentShortJourney((prevState) => [...prevState, 11]);
        }
        break;
      default:
    }
  }, [
    current_page,
    cursus,
    setStudentJourney,
    setStudentShortJourney,
    student_Journey.years,
    student_short_journey,
  ]);

  const sendEmail = () => {
    // Données à envoyer dans la requête
    const data = {
      lang: language,
      cursus: cursusId,
      email: emailStudent,
      lastname: lastname,
      firstname: firstname,
      avatar: character.name,
      datas: parcoursEnCours,
    };
    console.log(data);
    // Options de la requête
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    // URL du serveur distant
    //const url = "http://localhost:81/ecolepurpan/"; // LOCAL
    // const url = "https://bk7bpbakra.preview.infomaniak.website/pdf_generator/";
    const url = "https://monparcours.purpan.fr/pdf_generator/";

    // Effectuer la requête POST
    fetch(url, options)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setCurrentPage(current_page + 1);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        console.log("error", error);
      });
  };

  return (
    <div id="fullHeight">
      <Header />
      <div
        className={current_page === 16 ? "d-flex flex-column-reverse mt-4" : ""}
      >
        <Content />

        <div
          className={`col-12 d-flex px-4 ${current_page === 0
              ? "justify-content-end"
              : "justify-content-between"
            }`}
        >
          {current_page !== 0 && (
            <Button
              className="d-flex justify-content-center align-items-center previous-button"
              onClick={() => {
                switchPreviousPage();
              }}
            >
              <MdWest size={26} />
            </Button>
          )}
          {current_page !== 16 && (
            <div
              className={
                switchButtonDisabled() === true ? "opacity-25" : "opacity-100"
              }
            >
              <Button
                className={`d-flex justify-content-center align-items-center ${current_page === 15 ? "submit-button" : "next-button"
                  }`}
                disabled={switchButtonDisabled()}
                onClick={() => {
                  current_page === 15 ? sendEmail() : switchNextPage();

                  // Matomo <

                  if (current_page === 14) {
                    // Demande de recap
                    window._paq.push([
                      "trackEvent",
                      "Recap",
                      "Send",
                      cursusId,
                      null,
                    ]);
                  }

                  // Matomo >
                }}
              >
                {current_page === 15 ? "Envoyer →" : <MdEast size={26} />}
              </Button>


















            </div>
          )}



        </div>

        <div className="mx-3">

          {current_page === 0 ?

            textTranslation[language]["general"][current_page].default_career.filter((value, key) => key === 2).map((value, key) => (
              <Card bsPrefix="" className="mt-3 py-1 px-3 mx-3" style={{ background: 'white', color: 'black', borderRadius: '5px' }} key={key}>
                <Card.Body>
                  <div style={{ display: 'inline', 'paddingTop': '6px', 'paddingRight': '10px' }}>
                    <Card.Text className="d-inline" dangerouslySetInnerHTML={{ __html: stylizeJsonText(value.paragraph) }} />
                  </div>
                  <div style={{ display: 'inline' }}>
                    <MdOutlineExplore // Permet d'aller sur la carte du parcours en cours
                      color="black"
                      size={32}
                    />
                  </div>
                </Card.Body>
              </Card>
            ))
            : ''}
        </div>
        <BottomBar />
      </div>
    </div>
  );
}