import React, { useCallback } from 'react'
import carte_agrobachelor from "../AGRO-BACHELOR2/carte-agrobachelor.svg"
import ReactConfetti from 'react-confetti'
import "../Map.css";
import Header from '../features/Header';
import { filterYears } from '../services/filterYears';
import { textTranslation } from "../config/Multilangues";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    currentPage,
    cursusThirdYear,
    cursusFourthYear,
    studentJourney,
    studentShortJourney,
    parcours,
    yearSelected
} from "../recoil/Atomes";
export default function AgroBachelorMap({ languag }) {
    const [student_short_journey, setStudentShortJourney] = useRecoilState(studentShortJourney);
    const [student_journey, setStudentJourney] = useRecoilState(studentJourney);
    const [current_page, setcurrent_page] = useRecoilState(currentPage);
    const [cursus_fourth_year, setCursusFourthYear] = useRecoilState(cursusFourthYear)
    const [cursus_third_year, setCursusThirdYear] = useRecoilState(cursusThirdYear)
    const [parcoursEnCours, setParcoursEnCours] = useRecoilState(parcours);
    const selectedYear = useRecoilValue(yearSelected);
    const language = 'FR'

    // Colorier le parcours en cours
    const setColorParcours = useCallback((color) => {
        let cursusThirdYearAgro = document.querySelectorAll(`.parcour-3-options-${cursus_third_year}`)
        let cursusThirdYearAgroPanneau = document.querySelectorAll(`.parcour-3-options-${cursus_third_year}-panneau`)
        //Definir la couleur et la taille de text  dans le parcour et panneau
        student_journey.years.forEach((year) => {
            let parcour = document.querySelectorAll(`.parcour-${year}`)
            let PanneauStage = document.querySelectorAll(`.parcour-${year}-panneau`)
            let PanneauAnnée = document.querySelectorAll(`.parcour-${year}-panneau-année`)
            // console.log(parcour, "parcour");
            if (parcour) {
                parcour.forEach((element) => {
                    //couleur rouge
                    // console.log(element);
                    if (element) element.setAttribute('style', 'fill:#821F1D')
                })
            }
            if (PanneauStage) {
                PanneauStage.forEach((element) => {

                    if (element) {
                        if (element.innerHTML.includes("STAGE") || element.innerHTML.includes("INTERNSHIP")) {
                            element.setAttribute('class', 'parcour-panneau-stage ')
                        } else {
                            element.setAttribute('class', 'parcour-panneau-stage-details')
                        }
                        element.setAttribute('style', 'fill:white')
                    }
                })
            }

            if (PanneauAnnée) {
                PanneauAnnée.forEach((element) => {
                    element.addEventListener("click", () => {

                        // Lors du clic sur le panneau de 1a
                        if (element.className.baseVal.includes('panneau-1')) {
                            setStudentShortJourney([])
                            setCursusFourthYear("")
                            setCursusThirdYear("")
                            setStudentJourney({ years: [1] })
                            setcurrent_page(4)
                            setColorParcours()
                            if (document.querySelector(`.parcour-1-fin`)) document.querySelector(`.parcour-1-fin`).setAttribute('style', 'fill:#EFD992')
                            student_journey.years.forEach((year) => {

                                // console.log(document.querySelectorAll(`.parcour-${year}`), "parcour11")
                                document.querySelectorAll(`.parcour-${year}`).forEach((parcour) => {
                                    // verifie si c'est un panneau ou parcours

                                    if (parcour.className.baseVal.includes('panneau')) {
                                        console.log(parcour, "azazs")
                                        parcour.setAttribute('style', 'fill:#FBC13D')
                                    } else {
                                        parcour.setAttribute('style', 'fill:#EFD992')
                                    }

                                })
                                document.querySelectorAll(`#parcour-${year}-panneau`).forEach((parcourpanneau) => {
                                    parcourpanneau.setAttribute('style', 'fill:#213D33')


                                })
                            })
                        }
                        // Lors du clic sur le panneau de 2a
                        else if (element.className.baseVal.includes('panneau-3')) {
                            setStudentShortJourney([1, 2])
                            setCursusFourthYear("")
                            setStudentJourney({ years: filterYears([1, 2], selectedYear) })
                            setcurrent_page(7)
                            setColorParcours()
                            //document.querySelector(`.parcour-1-fin`).setAttribute('style', 'fill:#EFD992')
                            student_journey.years.filter((year) => year > 1).forEach((year) => {

                                document.querySelectorAll(`.parcour-${year}`).forEach((parcour) => {
                                    // verifie si c'est un panneau ou parcours
                                    if (parcour.className.baseVal.includes('panneau')) {
                                        console.log(parcour, "azazs")
                                        parcour.setAttribute('style', 'fill:#FBC13D')
                                    } else {
                                        parcour.setAttribute('style', 'fill:#EFD992')
                                    }

                                })
                                document.querySelectorAll(`#parcour-${year}-panneau`).forEach((parcourpanneau) => {
                                    // console.log(parcourpanneau, "parcourpanneau")
                                    parcourpanneau.setAttribute('style', 'fill:#213D33')

                                })
                            })
                        }
                        // Lors du clic sur le panneau de 3a
                        else if (element.className.baseVal.includes('panneau-5')) {
                            setStudentShortJourney([1, 2, 3, 4, 5, 6])
                            setStudentJourney({ years: filterYears([1, 2, 3], selectedYear) })
                            setcurrent_page(9)
                            student_journey.years.filter((year) => year >= 3).forEach((year) => {
                                document.querySelectorAll(`.parcour-${year}`).forEach((parcour) => {
                                    // verifie si c'est un panneau ou parcours

                                    if (parcour.className.baseVal.includes('panneau')) {
                                        console.log(parcour, "azazs")
                                        parcour.setAttribute('style', 'fill:#FBC13D')
                                    } else {
                                        parcour.setAttribute('style', 'fill:#EFD992')
                                    }

                                })
                                document.querySelectorAll(`#parcour-${year}-panneau`).forEach((parcourpanneau) => {
                                    parcourpanneau.setAttribute('style', 'fill:#213D33')
                                })
                            })
                        }
                    })
                })
            }
        });

        //Definir la couleur et la taille de text  dans les options de 3ème année et leur panneau
        cursusThirdYearAgro.forEach((element) => {
            if (element) element.setAttribute('style', 'fill:#821F1D')
        })
        cursusThirdYearAgroPanneau.forEach((element) => {
            console.log(element)
            if (element.innerHTML.includes(textTranslation[language]["map"].alternance)) {
                if (element) element.setAttribute('class', 'parcour-3-panneau-alternance')
            } else {
                if (element) element.setAttribute('class', 'parcour-3-panneau-details')
            }
            // element.setAttribute('style', 'fill:white')
        })
    }, [cursus_third_year,
        selectedYear,
        setCursusFourthYear,
        setCursusThirdYear,
        setStudentJourney,
        setStudentShortJourney,
        setcurrent_page,
        student_journey.years,]);


    React.useEffect(() => {
        setColorParcours()
    }, [setColorParcours])
    React.useEffect(() => { // Ceci permet de mettre à jour la var parcoursEnCours qui est envoyé au pdf lorsqu'on parcours les pages 
        // Trouver l'index de l'élément avec l'ID X
        const index = parcoursEnCours.findIndex(
            (item) => item.page === current_page
        );

        if (index !== -1) {
            // Si l'ID X existe dans le tableau, supprimer les éléments jusqu'à cet index
            const newData = parcoursEnCours.slice(0, index); // +1 pour inclure l'élément avec l'ID X

            // Mettre à jour le tableau avec le nouveau tableau résultant
            setParcoursEnCours(newData);
        }
    }, [current_page, parcoursEnCours, setParcoursEnCours]);
    return (
        <>
            <Header />
            {/* Affichage de confetti si la page actuelle est 13 */}
            {current_page === 13 &&
                <ReactConfetti
                    numberOfPieces={150}
                    width={window.innerWidth}
                    height={window.innerHeight}
                />}
            {/* carte Agro-Bachelor  */}
            <div id='fullHeightMap'>
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 1 }}>
                    <img src={carte_agrobachelor} className='entrance-image' alt="carte agrobachelor" />
                    <div style={{ height: '12vh', backgroundColor: '#183E32' }}></div>
                </div>
                {/* Titre cursus Agro-Bachelor  */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 3 }}>
                    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 750 1280" style={{ enableBackground: "new 0 0 750 1280" }} xmlSpace="preserve">
                        <g>
                            <g>
                                <path style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#7F5A41" }} d="M184.5,105.3h-7.3c-0.4,0-0.8-0.3-0.8-0.8V48.8c0-0.4,0.3-0.8,0.8-0.8h7.3c0.4,0,0.8,0.3,0.8,0.8v55.8C185.3,105,185,105.3,184.5,105.3z" />
                                <g>
                                    <rect x="127.8" y="20" style={{ fillRule: "evenodd", clipRule: "evenodd", fill: "#FFFFFF" }} width="106.2" height="29.7" />
                                    <path style={{ fill: "#F6C459" }} d="M234,50.2H127.8c-0.3,0-0.5-0.2-0.5-0.5V20c0-0.3,0.2-0.5,0.5-0.5H234c0.3,0,0.5,0.2,0.5,0.5v29.7C234.5,50,234.3,50.2,234,50.2z M128.3,49.2h105.2V20.5H128.3V49.2z" />
                                </g>
                                <text transform="matrix(1 0 0 1 134.4515 38.3206)" style={{ fill: "#213D33", fontFamily: "Poppins", fontWeight: 'bold', fontSize: "12.1393px" }}>{textTranslation[language]["map"].title_cursus.agro}</text>
                            </g>
                            <g>
                                <path style={{ fill: "#7FA195" }} d="M176.1,106.4c0-1.6-0.5-3.6-1.1-5.4c-0.7-1.8-1.5-3.6-2.6-5.4c-1.1-1.7-2.3-3.4-3.6-4.9c-1.3-1.6-2.8-3-4.4-4.4c1.8,1.1,3.5,2.3,5.1,3.6c1.6,1.3,3.1,2.8,4.6,4.4c1.4,1.6,2.7,3.4,3.7,5.3c1.1,2,2,3.8,2.5,6.4L176.1,106.4z" />
                                <path style={{ fill: "#7FA195" }} d="M177.7,105.4c0.3-1.5,0.3-3.7,0-5.5c-0.3-1.9-0.7-3.9-1.4-5.8c-0.7-1.9-1.5-3.8-2.5-5.5c-1-1.8-2.1-3.5-3.3-5.2c1.5,1.4,2.9,3,4.2,4.6c1.3,1.6,2.5,3.4,3.5,5.3c1,1.9,1.9,3.9,2.5,6c0.6,2.1,1.1,4.2,1.1,6.8L177.7,105.4z" />
                                <path style={{ fill: "#7FA195" }} d="M181.3,105.2c-0.3-2.3-0.3-4.4-0.2-6.5c0.1-2.1,0.4-4.3,0.9-6.3c0.4-2.1,1.1-4.1,2-6c0.8-1.9,2-3.7,3.1-5.4c-0.9,1.9-1.7,3.7-2.1,5.7c-0.5,2-0.8,3.9-0.9,5.9c-0.1,2,0,4,0.2,5.9c0.2,1.9,0.6,4,1.2,5.6L181.3,105.2z" />
                                <path style={{ fill: "#7FA195" }} d="M175.2,106.7c-0.3-2.3-0.3-4.4-0.2-6.5c0.1-2.1,0.4-4.3,0.9-6.3c0.4-2.1,1.1-4.1,2-6c0.8-1.9,2-3.7,3.1-5.4c-0.9,1.9-1.7,3.7-2.1,5.7c-0.5,2-0.8,3.9-0.9,5.9c-0.1,2,0,4,0.2,5.9c0.2,1.9,0.6,4,1.2,5.6L175.2,106.7z" />
                                <path style={{ fill: "#7FA195" }} d="M182.9,104c0.9-1.8,2-3,3.2-4.3c1.2-1.3,2.6-2.4,4-3.3c1.5-0.9,3-1.6,4.7-1.9c1.6-0.4,3.3-0.5,4.9-0.4c-1.6,0.3-3.1,0.7-4.5,1.4c-1.4,0.7-2.7,1.6-3.8,2.6c-1.1,1-2,2.2-2.8,3.5c-0.7,1.2-1.4,2.7-1.7,3.9L182.9,104z" />
                                <path style={{ fill: "#7FA195" }} d="M181,106.6c-1-1.7-1.6-3.3-2-5c-0.4-1.7-0.7-3.4-0.7-5.1c0-1.7,0.3-3.4,0.8-5c0.5-1.6,1.3-3,2.2-4.4c-0.6,1.5-1.1,3-1.2,4.6c-0.2,1.5-0.1,3.1,0.3,4.6c0.3,1.5,0.8,2.9,1.5,4.2c0.7,1.3,1.6,2.6,2.4,3.5L181,106.6z" />
                                <path style={{ fill: "#7FA195" }} d="M181,104.6c0.6-1.8,1.4-3.2,2.3-4.7c0.9-1.5,1.9-2.9,3-4.1c1.1-1.3,2.4-2.4,3.7-3.4c1.4-1,2.9-1.7,4.4-2.3c-1.4,0.9-2.6,1.9-3.7,3.1c-1,1.2-2,2.5-2.7,3.8c-0.7,1.4-1.4,2.8-1.8,4.3c-0.5,1.4-0.9,3-1.1,4.4L181,104.6z" />
                                <path style={{ fill: "#7FA195" }} d="M175,105.8c-0.5-1.3-1.2-2.7-2-4c-0.8-1.3-1.7-2.6-2.7-3.7c-1-1.2-2.2-2.2-3.5-3.1c-1.3-0.9-2.7-1.6-4.3-2.2c1.6,0.2,3.2,0.6,4.8,1.2c1.5,0.7,3,1.5,4.4,2.5c1.4,1,2.7,2.1,3.8,3.4c1.2,1.3,2.2,2.8,3,4.4L175,105.8z" />
                            </g>
                        </g>
                    </svg>
                </div>
                {/* Tous les panneaux  */}
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10 }}>
                    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 750 1785" style={{ enableBackground: 'new 0 0 750 1785' }} xmlSpace="preserve">


                        {/* Panneau première année  */}
                        <rect
                            className={student_journey.years.includes(1) ? 'parcour-1-panneau-année panneau-1' : ""} //Vérifie si le parcours de l'utilisateur contient la 1a
                            x="51.8" y="136.1" style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#213D33' }} width="155" height="40" />
                        <text
                            className={student_journey.years.includes(1) ? 'parcour-1-panneau-année panneau-1' : ""} //Vérifie si le parcours de l'utilisateur contient la 1a
                            transform="matrix(1 0 0 1 89.6761 161.521)" style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontSize: '18px' }}>{textTranslation[language]["map"].year.year1}</text>
                        {/* Panneau 2ème année  */}
                        <rect
                            // Vérifie si le parcours de l'utilisateur contient la 2a
                            className={
                                student_journey.years.includes(2)
                                    ? 'parcour-2-panneau-année panneau-3'
                                    : ""
                            }
                            x="550.9" y="290.7" style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#213D33' }} width="155" height="40" />
                        <g>
                            <text
                                // Vérifie si le parcours de l'utilisateur contient la 2a
                                className={
                                    student_journey.years.includes(2)
                                        ? 'parcour-2-panneau-année panneau-3'
                                        : ""
                                }
                                transform="matrix(1 0 0 1 582.7035 316.0479)" style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontSize: '18px' }}>{textTranslation[language]["map"].year.year2}</text>
                            {/* <text transform="matrix(0.583 0 0 0.583 593.0892 310.0537)" style={{ fill: '#FFFFFF', fontFamily: 'Poppins-Medium', fontSize: '18px' }}>e</text>
                            <text transform="matrix(1 0 0 1 599.5643 316.0479)" style={{ fill: '#FFFFFF', fontFamily: 'Poppins-Medium', fontSize: '18px' }}>année</text> */}
                        </g>
                        {/* Panneau 3ème année  */}
                        <rect x="330.5" y="684.4"
                            // Vérifie si le parcours de l'utilisateur contient la 3a
                            className={
                                student_journey.years.includes(3)
                                    ? 'parcour-3-panneau-année panneau-5'
                                    : ""
                            }
                            style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#213D33' }} width="155" height="40" />
                        <g>
                            <text
                                // Vérifie si le parcours de l'utilisateur contient la 3a
                                className={
                                    student_journey.years.includes(3)
                                        ? 'parcour-3-panneau-année panneau-5'
                                        : ""
                                }
                                transform="matrix(1 0 0 1 364.2186 709.7983)" style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontSize: '18px' }}>{textTranslation[language]["map"].year.year3}</text>
                            {/* <text transform="matrix(0.583 0 0 0.583 374.9105 703.8042)" style={{ fill: '#FFFFFF', fontFamily: 'Poppins-Medium', fontSize: '18px' }}>e</text>
                            <text transform="matrix(1 0 0 1 381.3851 709.7983)" style={{ fill: '#FFFFFF', fontFamily: 'Poppins-Medium', fontSize: '18px' }}>année</text> */}
                        </g>
                        {/* Panneau Agriculture et environnement   */}
                        <path
                            // Vérifie si le cursus de 3a est agriculture et environnment, si le parcours de l'utilisateur inclu l'année 3 et si nous sommes à l'étape 7
                            className={
                                cursus_third_year === 0 &&
                                    student_journey.years.includes(3) &&
                                    student_short_journey.includes(7)
                                    ? 'parcour-3-options-0 parcour-3 panneau-7'
                                    : ''
                            } style={{ fill: '#FBC13D' }} d="M489.7,1175.6v-62.7H318.4v62.7H400v17.3c-6.8,1.8-11.8,8-11.8,15.3c0,8.8,7.1,15.9,15.9,15.9 c8.8,0,15.9-7.1,15.9-15.9c0-7.4-5.1-13.6-11.9-15.3v-17.3H489.7z" />
                        <text transform="matrix(1 0 0 1 336.9364 1138.5908)" style={{ enableBackground: 'new' }} id="parcour-3-panneau">
                            <tspan
                                // Vérifie si le cursus de 3a est agriculture et environnment, si le parcours de l'utilisateur inclu l'année 3 et si nous sommes à l'étape 7
                                className={
                                    cursus_third_year === 0 &&
                                        student_journey.years.includes(3) &&
                                        student_short_journey.includes(7)
                                        ? 'parcour-3-options-0-panneau panneau-7' : "contenu"
                                }
                                id='parcour-3-panneau options-0' x="0" y="0">{textTranslation[language]["map"].cursusAgro4[0]}</tspan>
                            <tspan
                                // Vérifie si le cursus de 3a est agriculture et environnment, si le parcours de l'utilisateur inclu l'année 3 et si nous sommes à l'étape 7
                                className={
                                    cursus_third_year === 0 &&
                                        student_journey.years.includes(3) &&
                                        student_short_journey.includes(7)
                                        ? 'parcour-3-options-0-panneau panneau-7'
                                        : "contenu"
                                }
                                id='parcour-3-panneau options-0' x="-9.2" y="24">{language === "EN" ? <tspan> &amp;</tspan> : ""}  {textTranslation[language]["map"].cursusAgro4[1]}</tspan>
                        </text>
                        {/* Panneau stage anglophne   */}
                        <path
                            // Vérifie si nous sommes à l'étape 6
                            className={student_short_journey.includes(6) ? 'parcour-2  panneau-4' : "panneau-4"} id="parcour-2" style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FBC13D' }} d="M499.4,525.1v-62H335.9v62h12.8v20.5c-6.8,1.8-11.9,8-11.9,15.3 c0,8.8,7.1,15.9,15.9,15.9c8.8,0,15.9-7.1,15.9-15.9c0-7.4-5-13.5-11.9-15.3v-20.5H499.4z" />
                        <g>
                            <text
                                // Vérifie si nous sommes à l'étape 6
                                className={student_short_journey.includes(6) ? 'parcour-2-panneau panneau-4' : "panneau-4 contenu"} id="parcour-2-panneau" transform="matrix(1 0 0 1 347.9969 512.065)" style={{ fill: '#213D33', fontFamily: 'Poppins', fontSize: '20px' }}>{textTranslation[language]["map"].anglophone}</text>
                        </g>
                        <g>
                            <text
                                // Vérifie si nous sommes à l'étape 6
                                className={student_short_journey.includes(6) ? 'parcour-2-panneau panneau-4' : "panneau-4 titre"} id="parcour-2-panneau" transform="matrix(1 0 0 1 347.3783 488.643)" style={{ fill: '#213D33', fontFamily: 'Poppins', fontWeight: 'Bold', fontSize: '20px' }}>{textTranslation[language]["map"].internship.toUpperCase()}</text>
                        </g>
                        {/* Panneau food production  */}
                        <path
                            // Vérifie si le cursus de 3a est Food Production, si le parcours de l'utilisateur contient l'année 3 et s'il a atteint l'étape 7 
                            className={
                                cursus_third_year === 1 &&
                                    student_journey.years.includes(3) &&
                                    student_short_journey.includes(7)
                                    ? 'parcour-3-options-1 parcour-3 panneau-6'
                                    : ''} style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FBC13D' }} d="M188.8,1165.9v-21.7c6.9-1.7,12.1-7.9,12.1-15.4 c0-8.8-7.1-15.9-15.9-15.9s-15.9,7.1-15.9,15.9c0,7.3,4.9,13.4,11.6,15.3v21.9H85.9v66.9h198.3v-66.9H188.8z" />
                        <g>
                            <text transform="matrix(1 0 0 1 100.1244 1191.5654)" id="parcour-3-panneau">
                                <tspan
                                    // Vérifie si le cursus de 3a est Food Production, si le parcours de l'utilisateur contient l'année 3 et s'il a atteint l'étape 7 
                                    className={
                                        cursus_third_year === 1 &&
                                            student_journey.years.includes(3) &&
                                            student_short_journey.includes(7)
                                            ? 'parcour-3-options-1-panneau panneau-6 contenu-white'
                                            : "contenu"
                                    }
                                    id="parcour-3-panneau options-1" x="0" y="0">{textTranslation[language]["map"].cursusAgro2[0]} </tspan>
                                <tspan
                                    // Vérifie si le cursus de 3a est Food Production, si le parcours de l'utilisateur contient l'année 3 et s'il a atteint l'étape 7 
                                    className={
                                        cursus_third_year === 1 &&
                                            student_journey.years.includes(3) &&
                                            student_short_journey.includes(7)
                                            ? 'parcour-3-options-1-panneau panneau-6 contenu-white'
                                            : "contenu"
                                    } id="parcour-3-panneau options-1" x="8.6" y="24">&amp; {textTranslation[language]["map"].cursusAgro2[1]}</tspan>
                            </text>
                        </g>
                        {/* Panneau stage première année   */}
                        <path
                            // Vérifie le parcours de l'utilisateur contient l'étape 2
                            className={
                                student_short_journey.includes(2)
                                    ? 'parcour-1 panneau-2 '
                                    : "panneau-2"
                            }
                            id="parcour-1" style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FBC13D', fontFamily: 'Poppins' }} d="M583.1,202.5v-62H432v62h22.3v18.7c-6.5,2-11.3,8-11.3,15.2 c0,8.8,7.1,15.9,15.9,15.9c8.8,0,15.9-7.1,15.9-15.9c0-7.6-5.3-13.9-12.4-15.5v-18.4H583.1z" />
                        <g
                            // Vérifie le parcours de l'utilisateur contient l'étape 2
                            className={
                                student_short_journey.includes(2)
                                    ? 'parcour-1-panneau panneau-2 '
                                    : "panneau-2"
                            }
                            id="parcour-1-panneau">
                            <text
                                // Vérifie le parcours de l'utilisateur contient l'étape 2
                                className={
                                    student_short_journey.includes(2)
                                        ? 'parcour-1-panneau panneau-2'
                                        : "panneau-2 contenu"
                                }
                                id="parcour-1-panneau" transform="matrix(1 0 0 1 443.2411 190.9587)" style={{ fill: '#213D33', fontFamily: 'Poppins', fontSize: '20px' }}> {textTranslation[language]["map"].year.year1}</text>
                        </g>
                        <g
                            // Vérifie le parcours de l'utilisateur contient l'étape 2
                            className={
                                student_short_journey.includes(2)
                                    ? 'parcour-1-panneau panneau-2'
                                    : "panneau-2 titre"
                            }>
                            <text
                                // Vérifie le parcours de l'utilisateur contient l'étape 2
                                className={
                                    student_short_journey.includes(2)
                                        ? 'parcour-1-panneau panneau-2'
                                        : "panneau-2"
                                }
                                id="parcour-1-panneau" transform="matrix(1 0 0 1 442.6224 167.0323)" style={{ fill: '#213D33', fontFamily: 'Poppins', fontWeight: 'bold', fontSize: '20px' }}>{textTranslation[language]["map"].internship.toUpperCase()}</text>
                        </g>

                    </svg>
                </div>
                {/* Parcour 1 jusqu'au panneau 1ère année */}

                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 2 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <rect x="119.3" y="0.6" className='parcour-1' style={{ fill: '#EFD992' }} width="20" height="155.6" />
                    </svg>
                </div>
                {/* Parcour du panneau 1ère année au candidature stage */}

                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 2 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <path
                            // Vérifie si le parcours utilisateur contient l'étape 1
                            className={student_short_journey.includes(1) ? 'parcour-1' : ""}
                            style={{ fill: '#EFD992' }}
                            d="M293,246.3H159.3c-22.1,0-40-17.9-40-40v-50.2h20v50.2c0,11,9,20,20,20H293V246.3z"
                        />
                    </svg>
                </div>


                {/* Parcour 1  candidature stage */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 2 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <rect x="293" y="226.3"
                            // Vérifie si le parcours utilisateur contient l'étape 2
                            className={student_short_journey.includes(2) ? 'parcour-1' : ""} style={{ fill: '#EFD992' }} width="165.8" height="20" />
                    </svg>
                </div>
                {/* Parcour 1 fin  */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 2 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <path
                            // Vérifie si le parcours utilisateur contient l'étape 1 et s'il contient l'année 2
                            className={student_short_journey.includes(2) && student_journey.years.includes(2) ? 'parcour-1  parcour-1-fin' : ''}
                            style={{ fill: '#EFD992' }}
                            d="M632.6,312.4h-20v-46.2c0-11-9-20-20-20H458.9v-20h133.7c22.1,0,40,17.9,40,40V312.4z"
                        />
                    </svg>

                </div>

                {/* Panneau   Candidature stage première année  */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 3 }}>

                    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 750 1280" style={{ enableBackground: "new 0 0 750 1280" }}
                    >
                        <text transform="matrix(1 0 0 1 242.0633 279.3854)">
                            <tspan x="0" y="0" style={{ fill: "#FFFFFF", fontFamily: "Poppins", fontWeight: 'bold', fontSize: "15px" }}>
                                {textTranslation[language]["map"].application}
                            </tspan>
                            <tspan x={language === "EN" ? "5" : "28.4"} y="18" style={{ fill: "#FFFFFF", fontFamily: "Poppins", fontWeight: 'bold', fontSize: "15px" }}>
                                {textTranslation[language]["map"].internship}
                            </tspan>
                        </text>
                        <path style={{ fill: "#213D33" }} d="M302.4,236c0-5.2-4.2-9.3-9.3-9.3s-9.3,4.2-9.3,9.3c0,4.5,3.1,8.2,7.3,9.1v15.9h4v-15.9C299.2,244.2,302.4,240.5,302.4,236z" />
                    </svg>
                </div >


                {/*Rond parcour 1 */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 5 }}>

                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1280"
                        style={{ enableBackground: "new 0 0 750 1280" }}
                        xmlSpace="preserve"
                    >
                        <circle style={{ fill: "#FFFFFF" }} cx="458.9" cy="236" r="24.6" />
                    </svg>
                </div >

                {/* Panneau   Candidature contrat d'apprentissage  */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 3 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <text transform="matrix(1 0 0 1 143.5609 336.5341)">
                            <tspan x="0" y="0" style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontWeight: "Bold", fontSize: '15px' }}>
                                {textTranslation[language]["map"].application}
                            </tspan>
                            <tspan x={language === "EN" ? "8" : "19.3"} y="18" style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontWeight: "Bold", fontSize: '15px' }}>
                                {textTranslation[language]["map"].contract}
                            </tspan>
                            <tspan x={language === "EN" ? "-10" : "-2.2"} y="36" style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontWeight: "Bold", fontSize: '15px' }}>
                                {textTranslation[language]["map"].alternance}
                            </tspan>
                        </text>
                        <path style={{ fill: '#213D33' }} d="M196.4,396.6v-15.4h-4v15.4c-4.2,0.9-7.3,4.7-7.3,9.1c0,5.2,4.2,9.3,9.3,9.3s9.3-4.2,9.3-9.3 C203.8,401.3,200.6,397.5,196.4,396.6z" />
                    </svg>
                </div>
                {/* Panneau   Candidature spécialisation  */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 3 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <text transform="matrix(1 0 0 1 294.4144 336.5342)" x={language === "EN" ? "5" : "8"} style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontWeight: "Bold", fontSize: '15px' }}>
                            {textTranslation[language]["map"].application}
                        </text>
                        <text transform="matrix(1 0 0 1 290.3265 354.5342)" x={language === "EN" ? "0" : "5.2"} style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontWeight: "Bold", fontSize: '15px' }}>
                            {textTranslation[language]["map"].specialization}
                        </text>
                        <text transform="matrix(1 0 0 1 312.0228 372.5342)" x={language === "EN" ? "0" : "-5.2"} style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontWeight: "Bold", fontSize: '15px' }}>  {textTranslation[language]["map"].year.year3}</text>
                        <path style={{ fill: '#213D33' }} d="M347.3,396.6v-15.4h-4v15.4c-4.2,0.9-7.3,4.7-7.3,9.1c0,5.2,4.2,9.3,9.3,9.3s9.3-4.2,9.3-9.3 C354.6,401.3,351.5,397.5,347.3,396.6z" />
                    </svg>
                </div>
                {/* Panneau   Candidature stage 2ème année  */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 3 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <text transform="matrix(1 0 0 1 432.5555 355.228)" className='parcour-1'>
                            <tspan x="0" y="0" style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontWeight: "Bold", fontSize: '15px' }}>
                                {textTranslation[language]["map"].application}
                            </tspan>
                            <tspan x={language === "EN" ? "8" : "28.4"} y="18" style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontWeight: "Bold", fontSize: '15px' }}>
                                {textTranslation[language]["map"].internship}
                            </tspan>
                        </text>
                        <path style={{ fill: '#213D33' }} d="M486.4,396.6v-15.4h-4v15.4c-4.2,0.9-7.3,4.7-7.3,9.1c0,5.2,4.2,9.3,9.3,9.3s9.3-4.2,9.3-9.3 C493.7,401.3,490.6,397.5,486.4,396.6z" />
                    </svg>
                </div>

                {/* Rond voyage stage anglophone  */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: cursus_third_year ? 5 : 3 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <circle style={{ fill: '#FFFFFF' }} cx="194.4" cy="559.2" r="10" />
                    </svg>
                </div>
                {/* Rond point voyage stage anglophone  */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: cursus_third_year ? 5 : 3 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <circle style={{ fill: '#FFFFFF' }} cx="352.7" cy="560.9" r="24.6" />
                    </svg>
                </div>



                {/* Parcours 2 A  */}
                < div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 2 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <path
                            // Vérifie si le parcours utilisateur contient l'étape 3
                            className={student_short_journey.includes(3) ? 'parcour-2' : ""} style={{ fill: '#EFD992' }} d="M592.6,416.2H484.4v-20h108.2c11,0,20-9,20-20v-63.7h20v63.7C632.6,398.2,614.6,416.2,592.6,416.2z" />
                    </svg>
                </div>
                {/* Parcours 2 B  */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 2 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <rect x="345.3" y="396.2"
                            // Vérifie si le parcours utilisateur contient l'étape 4
                            className={student_short_journey.includes(4) ? 'parcour-2' : ""} style={{ fill: '#EFD992' }} width="139.2" height="20" />
                    </svg>
                </div>
                {/* Parcours 2 C */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 2 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <rect x="194.4" y="396.2"
                            // Vérifie si le parcours utilisateur contient l'étape 5
                            className={student_short_journey.includes(5) ? 'parcour-2' : ""} style={{ fill: '#EFD992' }} width="150.9" height="20" />
                    </svg>
                </div>
                {/* Parcours 2 D */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 2 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <path style={{ fill: '#EFD992' }}
                            // Vérifie si le parcours utilisateur contient l'étape 6
                            className={student_short_journey.includes(6) ? 'parcour-2' : ""} d="M194.4,569.2h-41.2c-22.1,0-40-17.9-40-40v-93c0-22.1,17.9-40,40-40h41.2v20h-41.2c-11,0-20,9-20,20
v93c0,11,9,20,20,20h41.2V569.2z" />
                    </svg>
                </div>
                {/* Parcours 2 E  dernier en stage anglophone*/}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 2 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <path
                            style={{ fill: '#EFD992' }}
                            // Vérifie si le parcours utilisateur contient l'étape 6 et s'il contient l'année 3
                            className={
                                student_short_journey.includes(6) &&
                                    student_journey.years.includes(3)
                                    ? 'parcour-2 parcour-2-fin'
                                    : ""}
                            d="M414.5,704.4h-20V589.2c0-11-9-20-20-20h-21.7v-20h21.7c22.1,0,40,17.9,40,40V704.4z" />
                    </svg>
                </div>

                {/*Panneau Alternant */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 2 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <g>
                            <g>
                                <path
                                    style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#7F5A41' }}
                                    d="M468.3,839.1h-6.8c-0.4,0-0.8-0.3-0.8-0.8v-52.4
c0-0.4,0.3-0.8,0.8-0.8h6.8c0.4,0,0.8,0.3,0.8,0.8v52.4C469.1,838.7,468.8,839.1,468.3,839.1z"
                                />

                                <polygon
                                    style={{
                                        fillRule: 'evenodd',
                                        clipRule: 'evenodd',
                                        fill: '#FFFFFF',
                                        stroke: '#F6C459',
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                    }}
                                    points="425.1,758.7 425.1,786.6 451.8,786.6 465.3,800.1 478.7,786.6 510.2,786.6 510.2,758.7"
                                />
                                <text
                                    transform="matrix(1 0 0 1 433.5775 775.9184)"
                                    style={{ fill: '#213D33', fontFamily: 'Poppins', fontWeight: "Bold", fontSize: '11.4348px' }}
                                >
                                    {textTranslation[language]["map"].alternance}
                                </text>
                            </g>
                            <g>
                                <path style={{ fill: '#7FA195' }} d="M460.8,840c0-1.5-0.5-3.4-1.1-5.1c-0.6-1.7-1.4-3.4-2.4-5.1c-1-1.6-2.1-3.2-3.4-4.6
c-1.3-1.5-2.7-2.8-4.1-4.1c1.6,1,3.3,2.1,4.8,3.4c1.5,1.3,3,2.6,4.3,4.2c1.3,1.5,2.5,3.2,3.5,5c1,1.9,1.8,3.6,2.3,6L460.8,840z" />
                                <path style={{ fill: '#7FA195' }} d="M462.4,839.1c0.3-1.4,0.2-3.4,0-5.2c-0.2-1.8-0.7-3.7-1.3-5.4c-0.6-1.8-1.4-3.5-2.3-5.2
c-0.9-1.7-2-3.3-3.2-4.9c1.4,1.4,2.8,2.8,4,4.3c1.2,1.5,2.3,3.2,3.3,5c1,1.8,1.8,3.6,2.4,5.6c0.6,2,1.1,3.9,1,6.4L462.4,839.1z" />
                                <path style={{ fill: '#7FA195' }} d="M465.8,838.9c-0.3-2.2-0.3-4.1-0.2-6.2c0.1-2,0.4-4,0.8-5.9c0.4-1.9,1.1-3.8,1.9-5.6
c0.8-1.8,1.8-3.5,2.9-5c-0.8,1.8-1.6,3.5-2,5.4c-0.5,1.8-0.8,3.7-0.8,5.6c-0.1,1.9,0,3.7,0.2,5.6c0.2,1.8,0.6,3.7,1.1,5.3
L465.8,838.9z" />
                                <path style={{ fill: '#7FA195' }} d="M460,840.3c-0.3-2.2-0.3-4.1-0.2-6.2c0.1-2,0.4-4,0.8-5.9c0.4-1.9,1.1-3.8,1.9-5.6
c0.8-1.8,1.8-3.5,2.9-5c-0.8,1.8-1.6,3.5-2,5.4c-0.5,1.8-0.8,3.7-0.8,5.6c-0.1,1.9,0,3.7,0.2,5.6c0.2,1.8,0.6,3.7,1.1,5.3
L460,840.3z" />
                                <path style={{ fill: '#7FA195' }} d="M467.3,837.8c0.9-1.7,1.8-2.9,3-4.1c1.1-1.2,2.4-2.2,3.8-3.1c1.4-0.9,2.9-1.5,4.4-1.8
c1.5-0.4,3.1-0.4,4.6-0.4c-1.5,0.3-3,0.6-4.3,1.3c-1.3,0.6-2.5,1.5-3.5,2.5c-1,1-1.9,2.1-2.6,3.3c-0.7,1.2-1.3,2.6-1.6,3.7
L467.3,837.8z" />
                                <path style={{ fill: '#7FA195' }} d="M465.5,840.3c-0.9-1.6-1.5-3.1-1.9-4.7c-0.4-1.6-0.6-3.2-0.6-4.8c0-1.6,0.3-3.2,0.8-4.7
c0.5-1.5,1.3-2.9,2.1-4.1c-0.5,1.4-1,2.8-1.1,4.3c-0.2,1.5-0.1,2.9,0.2,4.3c0.3,1.4,0.8,2.7,1.4,4c0.6,1.2,1.5,2.5,2.3,3.3
L465.5,840.3z" />
                                <path style={{ fill: '#7FA195' }} d="M465.5,838.3c0.6-1.7,1.3-3,2.2-4.5c0.8-1.4,1.8-2.7,2.8-3.9c1-1.2,2.2-2.3,3.5-3.2
c1.3-0.9,2.7-1.6,4.1-2.1c-1.3,0.8-2.5,1.8-3.5,2.9c-1,1.1-1.9,2.3-2.6,3.6c-0.7,1.3-1.3,2.6-1.7,4c-0.4,1.4-0.8,2.8-1,4.1
L465.5,838.3z" />
                                <path style={{ fill: '#7FA195' }} d="M459.9,839.5c-0.4-1.2-1.1-2.6-1.9-3.8c-0.8-1.2-1.6-2.4-2.6-3.5c-1-1.1-2.1-2.1-3.3-3
c-1.2-0.9-2.6-1.5-4-2c1.5,0.2,3.1,0.5,4.5,1.2c1.4,0.6,2.9,1.4,4.1,2.4c1.3,0.9,2.5,2,3.6,3.2c1.1,1.2,2.1,2.4,3.1,3.9
L459.9,839.5z" />
                                <path
                                    style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#7FA195' }}
                                    d="M458.6,836.8c0,0,0.8,3.9-1.6,3.9h15.8c0,0-1.7-0.8-0.9-3.9
L458.6,836.8z"
                                />
                            </g>
                        </g>
                    </svg>
                </div>
                {/*Panneau étudiant */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 2, opacity: 0 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <g>
                            <g>
                                <path
                                    style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#7F5A41' }}
                                    d="M343.9,839.1h-6.8c-0.4,0-0.8-0.3-0.8-0.8v-52.4
c0-0.4,0.3-0.8,0.8-0.8h6.8c0.4,0,0.8,0.3,0.8,0.8v52.4C344.6,838.7,344.3,839.1,343.9,839.1z"
                                />

                                <polygon
                                    style={{
                                        fillRule: 'evenodd',
                                        clipRule: 'evenodd',
                                        fill: '#FFFFFF',
                                        stroke: '#F6C459',
                                        strokeLinecap: 'round',
                                        strokeLinejoin: 'round',
                                        strokeMiterlimit: 10,
                                    }}
                                    points="309,758.7 383.1,758.7 383.1,786.6 309,786.6 290.5,772.6"
                                />
                                <text
                                    transform="matrix(1 0 0 1 316.9686 775.9184)"
                                    style={{ fill: '#213D33', fontFamily: 'Poppins', fontWeight: 'bold', fontSize: '11.4348px' }}
                                >
                                    {textTranslation[language]["map"].etudiant}
                                </text>
                            </g>
                            <g>
                                <path style={{ fill: '#7FA195' }} d="M336.4,840c0-1.5-0.5-3.4-1.1-5.1c-0.6-1.7-1.4-3.4-2.4-5.1c-1-1.6-2.1-3.2-3.4-4.6
c-1.3-1.5-2.7-2.8-4.1-4.1c1.6,1,3.3,2.1,4.8,3.4c1.5,1.3,3,2.6,4.3,4.2c1.3,1.5,2.5,3.2,3.5,5c1,1.9,1.8,3.6,2.3,6L336.4,840z"
                                />
                                <path style={{ fill: '#7FA195' }} d="M337.9,839.1c0.3-1.4,0.2-3.4,0-5.2c-0.2-1.8-0.7-3.7-1.3-5.4c-0.6-1.8-1.4-3.5-2.3-5.2
c-0.9-1.7-2-3.3-3.2-4.9c1.4,1.4,2.8,2.8,4,4.3c1.2,1.5,2.3,3.2,3.3,5c1,1.8,1.8,3.6,2.4,5.6c0.6,2,1.1,3.9,1,6.4L337.9,839.1z"
                                />
                                <path style={{ fill: '#7FA195' }} d="M341.3,838.9c-0.3-2.2-0.3-4.1-0.2-6.2c0.1-2,0.4-4,0.8-5.9c0.4-1.9,1.1-3.8,1.9-5.6
c0.8-1.8,1.8-3.5,2.9-5c-0.8,1.8-1.6,3.5-2,5.4c-0.5,1.8-0.8,3.7-0.8,5.6c-0.1,1.9,0,3.7,0.2,5.6c0.2,1.8,0.6,3.7,1.1,5.3
L341.3,838.9z"
                                />
                                <path style={{ fill: '#7FA195' }} d="M335.6,840.3c-0.3-2.2-0.3-4.1-0.2-6.2c0.1-2,0.4-4,0.8-5.9c0.4-1.9,1.1-3.8,1.9-5.6
c0.8-1.8,1.8-3.5,2.9-5c-0.8,1.8-1.6,3.5-2,5.4c-0.5,1.8-0.8,3.7-0.8,5.6c-0.1,1.9,0,3.7,0.2,5.6c0.2,1.8,0.6,3.7,1.1,5.3
L335.6,840.3z"
                                />
                                <path style={{ fill: '#7FA195' }} d="M342.8,837.8c0.9-1.7,1.8-2.9,3-4.1c1.1-1.2,2.4-2.2,3.8-3.1c1.4-0.9,2.9-1.5,4.4-1.8
c1.5-0.4,3.1-0.4,4.6-0.4c-1.5,0.3-3,0.6-4.3,1.3c-1.3,0.6-2.5,1.5-3.5,2.5c-1,1-1.9,2.1-2.6,3.3c-0.7,1.2-1.3,2.6-1.6,3.7
L342.8,837.8z"
                                />
                                <path style={{ fill: '#7FA195' }} d="M341,840.3c-0.9-1.6-1.5-3.1-1.9-4.7c-0.4-1.6-0.6-3.2-0.6-4.8c0-1.6,0.3-3.2,0.8-4.7
c0.5-1.5,1.3-2.9,2.1-4.1c-0.5,1.4-1,2.8-1.1,4.3c-0.2,1.5-0.1,2.9,0.2,4.3c0.3,1.4,0.8,2.7,1.4,4c0.6,1.2,1.5,2.5,2.3,3.3
L341,840.3z"
                                />
                                <path style={{ fill: '#7FA195' }} d="M341,838.3c0.6-1.7,1.3-3,2.2-4.5c0.8-1.4,1.8-2.7,2.8-3.9c1-1.2,2.2-2.3,3.5-3.2
c1.3-0.9,2.7-1.6,4.1-2.1c-1.3,0.8-2.5,1.8-3.5,2.9c-1,1.1-1.9,2.3-2.6,3.6c-0.7,1.3-1.3,2.6-1.7,4c-0.4,1.4-0.8,2.8-1,4.1
L341,838.3z"
                                />
                                <path style={{ fill: '#7FA195' }} d="M335.4,839.5c-0.4-1.2-1.1-2.6-1.9-3.8c-0.8-1.2-1.6-2.4-2.6-3.5c-1-1.1-2.1-2.1-3.3-3
c-1.2-0.9-2.6-1.5-4-2c1.5,0.2,3.1,0.5,4.5,1.2c1.4,0.6,2.9,1.4,4.1,2.4c1.3,0.9,2.5,2,3.6,3.2c1.1,1.2,2.1,2.4,3.1,3.9
L335.4,839.5z"
                                />
                                <path
                                    style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#7FA195' }}
                                    d="M334.2,836.8c0,0,0.8,3.9-1.6,3.9h15.8c0,0-1.7-0.8-0.9-3.9
L334.2,836.8z"
                                />
                            </g>
                        </g>
                    </svg>
                </div>


                {/*Parcours food sales flouté */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: cursus_third_year === 3 ? 5 : 3, opacity: 0 }} >
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <path
                            style={{ fill: '#8AC07A' }}
                            d="M639.5,1128.8h-20v-101.4c0-11-9-20-20-20H440.1v-20h159.4c22.1,0,40,17.9,40,40V1128.8z"
                        />
                        <path
                            style={{ fill: '#8AC07A' }}
                            d="M415.8,1630.1h-20v-131.7c0-22.1,18.5-40,41.2-40h162.5c11,0,20-9,20-20v-230.2h20v230.2c0,22.1-17.9,40-40,40H437.1c-11.7,0-21.2,9-21.2,20V1630.1z"
                        />
                        <g>
                            <path
                                style={{ fill: '#93D4B1' }}
                                d="M709.8,1161.1h-30.4c-0.3,0-0.6-0.3-0.6-0.6v-3.1c0-0.1,0-0.3,0.1-0.4l2.3-3.1c0.1-0.2,0.3-0.2,0.5-0.2l14.1-0.5c0.4,0,0.6,0.3,0.6,0.6c0,0.3-0.3,0.6-0.6,0.6l-13.8,0.4l-2,2.6v2.3h29.7c0.3,0,0.6,0.3,0.6,0.6S710.1,1161.1,709.8,1161.1z"
                            />
                            <path
                                style={{ fill: '#93D4B1' }}
                                d="M682.1,1154.2c-0.3,0-0.5-0.2-0.6-0.5l-5-18.4c-0.1-0.2,0-0.4,0.1-0.5c0.1-0.2,0.3-0.2,0.5-0.2l36.1,1.5c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.3,0.1,0.5l-1.6,15.2c0,0.3-0.3,0.5-0.6,0.5L682.1,1154.2C682.1,1154.2,682.1,1154.2,682.1,1154.2z M677.9,1135.9l4.6,17.1l28.5-1.6l1.5-14L677.9,1135.9z"
                            />
                            <path
                                style={{ fill: '#93D4B1' }}
                                d="M677.1,1135.8c-0.3,0-0.5-0.2-0.6-0.5l-1.2-4.5c-0.2-0.7-0.7-1.3-1.4-1.6l-4.7-2.1c-0.3-0.1-0.4-0.5-0.3-0.8c0.1-0.3,0.5-0.4,0.8-0.3l4.7,2.1c1,0.5,1.7,1.3,2,2.4l1.2,4.5c0.1,0.3-0.1,0.7-0.4,0.8C677.2,1135.8,677.1,1135.8,677.1,1135.8z"
                            />
                            <rect
                                x="684.8"
                                y="1135.2"
                                transform="matrix(0.9714 -0.2373 0.2373 0.9714 -252.0038 195.2826)"
                                style={{ fill: '#93D4B1' }}
                                width="0.7"
                                height="18.3"
                            />
                            <rect
                                x="689.5"
                                y="1135.5"
                                transform="matrix(0.982 -0.1889 0.1889 0.982 -203.7615 150.9211)"
                                style={{ fill: '#93D4B1' }}
                                width="0.7"
                                height="17.7"
                            />
                            <rect
                                x="694.2"
                                y="1135.8"
                                transform="matrix(0.9924 -0.1231 0.1231 0.9924 -135.6374 94.242)"
                                style={{ fill: '#93D4B1' }}
                                width="0.7"
                                height="17"
                            />
                            <rect
                                x="698.8"
                                y="1136.1"
                                transform="matrix(0.9969 -7.830968e-02 7.830968e-02 0.9969 -87.4666 58.2691)"
                                style={{ fill: '#93D4B1' }}
                                width="0.7"
                                height="16.5"
                            />
                            <rect
                                x="703.5"
                                y="1136.3"
                                transform="matrix(0.9999 -1.497572e-02 1.497572e-02 0.9999 -17.0584 10.6695)"
                                style={{ fill: '#93D4B1' }}
                                width="0.7"
                                height="16"
                            />
                            <rect
                                x="700.7"
                                y="1144"
                                transform="matrix(5.795851e-02 -0.9983 0.9983 5.795851e-02 -475.0181 1785.2812)"
                                style={{ fill: '#93D4B1' }}
                                width="15.6"
                                height="0.7"
                            />
                            <rect
                                x="695.2"
                                y="1124.2"
                                transform="matrix(3.241641e-02 -0.9995 0.9995 3.241641e-02 -467.5855 1799.4452)"
                                style={{ fill: '#93D4B1' }}
                                width="0.7"
                                height="34.1"
                            />
                            <rect
                                x="680.4"
                                y="1146.9"
                                transform="matrix(0.9998 -2.018475e-02 2.018475e-02 0.9998 -23.0157 14.2875)"
                                style={{ fill: '#93D4B1' }}
                                width="31.7"
                                height="0.7"
                            />
                            <circle style={{ fill: '#23724B' }} cx="678.3" cy="1164.2" r="2.7" />
                            <path
                                style={{ fill: '#93D4B1' }}
                                d="M681.2,1160.7v0.9h-1.6c0,0-0.5,2.2-0.7,2.8s-0.5,0.6-0.9,0.5s-0.3-0.3-0.4-0.8c-0.1-0.5,0-3.2,0-3.2L681.2,1160.7z"
                            />
                            <circle style={{ fill: '#23724B' }} cx="704.8" cy="1164.2" r="2.7" />
                            <path
                                style={{ fill: '#93D4B1' }}
                                d="M707.8,1160.7v0.9h-1.6c0,0-0.5,2.2-0.7,2.8s-0.5,0.6-0.9,0.5s-0.3-0.3-0.4-0.8c-0.1-0.5,0-3.2,0-3.2L707.8,1160.7z"
                            />
                            <rect
                                x="670.3"
                                y="1125.6"
                                transform="matrix(0.4143 -0.9101 0.9101 0.4143 -632.9897 1270.8577)"
                                style={{ fill: '#23724B' }}
                                width="1.2"
                                height="3.3"
                            />
                            <circle style={{ fill: '#23724B' }} cx="669.1" cy="1126.4" r="1" />
                        </g>
                        <g>
                            <circle style={{ fill: '#93D4B1' }} cx="629.5" cy="1128.8" r="24.6" />
                            <path
                                style={{ fill: '#91B44F' }}
                                d="M633.3,1165.8v-21.6c6.9-1.7,12.1-7.9,12.1-15.4c0-8.8-7.1-15.9-15.9-15.9s-15.9,7.1-15.9,15.9c0,7.3,4.9,13.4,11.6,15.3v21.7h-86.2v51.9h180.3v-51.9H633.3z"
                            />
                            <text
                                transform="matrix(1 0 0 1 576.412 1197.8708)"
                                style={{ fill: '#23724B', fontFamily: 'Poppins', fontSize: '20px' }}
                            >
                                Food Sales
                            </text>
                        </g>
                    </svg>
                </div>
                {/*option métier métiers du conseil en élevage à Bernussou */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: cursus_third_year === 3 ? 5 : 3 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <g>
                            <ellipse
                                transform="matrix(0.8355 -0.5495 0.5495 0.8355 -622.2778 476.1323)"
                                style={{ fill: '#FFFFFF' }}
                                cx="484.1"
                                cy="1277.4"
                                rx="10.3"
                                ry="10.3"
                            />
                            <path
                                style={{ fill: '#213D33' }}
                                d="M484.1,1265.2c0,0-0.1,0-0.1,0c-6.5,0-11.8,5.3-11.8,11.8c0,11.2,11.8,20,11.8,20s0,0,0,0c0.6-0.5,11.8-9.7,11.9-19.9C496,1270.5,490.7,1265.2,484.1,1265.2z M484.1,1284.3c-3.7,0-6.8-3-6.8-6.8s3-6.8,6.8-6.8c3.7,0,6.8,3,6.8,6.8S487.8,1284.3,484.1,1284.3z"
                            />
                        </g>
                        <g>
                            <text
                                transform="matrix(1 0 0 1 444.8114 1323.5078)"
                                style={{
                                    fill: '#FFFFFF',
                                    fontFamily: 'Poppins-Regular',
                                    fontSize: '20px',
                                }}
                            >
                                <tspan x="0" y="0" style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontSize: '20px' }}>
                                    {textTranslation[language]["map"].cursus4details.breeding[0]}
                                </tspan>
                                <tspan x={language === "EN" ? "-30" : "-16.6"} y="24" style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontSize: '20px' }}>
                                    {textTranslation[language]["map"].cursus4details.breeding[1]}
                                </tspan>
                                <tspan x="-15.7" y="48" style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontSize: '20px' }}>
                                    {textTranslation[language]["map"].cursus4details.breeding[2]}
                                </tspan>
                                <tspan x="-15.7" y="72" style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontSize: '20px' }}>
                                    {textTranslation[language]["map"].cursus4details.breeding[3]}
                                </tspan>
                            </text>
                        </g>
                    </svg>
                </div>
                {/*option métier métiers de transition écologique à Purpan */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: cursus_third_year === 3 ? 5 : 3 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <g>
                            <ellipse
                                transform="matrix(0.8355 -0.5495 0.5495 0.8355 -647.8816 390.6052)"
                                style={{ fill: '#FFFFFF' }}
                                cx="328.5"
                                cy="1277.4"
                                rx="10.3"
                                ry="10.3"
                            />
                            <path
                                style={{ fill: '#213D33' }}
                                d="M328.5,1265.2c0,0-0.1,0-0.1,0c-6.5,0-11.8,5.3-11.8,11.8c0,11.2,11.8,20,11.8,20s0,0,0,0c0.6-0.5,11.8-9.7,11.9-19.9C340.3,1270.5,335,1265.2,328.5,1265.2z M328.5,1284.3c-3.7,0-6.8-3-6.8-6.8s3-6.8,6.8-6.8c3.7,0,6.8,3,6.8,6.8S332.2,1284.3,328.5,1284.3z"
                            />
                        </g>
                        <g>
                            <text
                                transform="matrix(1 0 0 1 289.7308 1323.5078)"
                                style={{
                                    fill: '#FFFFFF',
                                    fontFamily: 'Poppins-Regular',
                                    fontSize: '20px',
                                }}
                            >
                                <tspan x={language === "EN" ? "-45" : "0"} y="0" style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontSize: '20px' }}>
                                    {textTranslation[language]["map"].cursus4details.ecological[0]}
                                </tspan>
                                <tspan x={language === "EN" ? "-10" : "-33"} y="24" style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontSize: '20px' }}>
                                    {textTranslation[language]["map"].cursus4details.ecological[1]}
                                </tspan>
                                <tspan x={language === "EN" ? "-20" : "-41"} y="48" style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontSize: '20px' }}>
                                    {textTranslation[language]["map"].cursus4details.ecological[2]}
                                </tspan>
                                <tspan x={language === "EN" ? "-20" : "-11"} y="72" style={{ fill: '#FFFFFF', fontFamily: 'Poppins', fontSize: '20px' }}>
                                    {textTranslation[language]["map"].cursus4details.ecological[3]}
                                </tspan>
                            </text>
                        </g>
                    </svg>
                </div>
                {/*Parcour recherche flouté */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 2, opacity: 0 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <path style={{ fill: '#8AC07A' }} d="M415.8,1630.1h-20v-131.8c0-11-9-20-20-20H67.2c-22,0-40-17.9-40-40l-0.5-523.8c0-22.1,17.9-40,40-40h90v20h-90c-11,0-20,9-20,20l0.5,523.8c0,11,9,20,20,20h308.6c22.1,0,40,17.9,40,40V1630.1z" />
                        <rect x="159.2" y="874.8" style={{ fill: '#8AC07A' }} width="217.6" height="20" />
                        <g>
                            <path style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FFFFFF' }} d="M271.5,853v-55H37.8v55h113.3v7.5c-11.7,1.9-20.6,12.1-20.6,24.3c0,13.6,11,24.6,24.6,24.6s24.6-11,24.6-24.6c0-12.2-8.9-22.4-20.6-24.3V853H271.5z" />
                            <g>
                                <circle style={{ fill: '#93D4B1' }} cx="155.2" cy="884.8" r="24.6" />
                                <path style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#91B44F' }} d="M271.5,853v-55H37.8v55h113.3v16.5c-6.8,1.8-11.9,8-11.9,15.3c0,8.8,7.1,15.9,15.9,15.9c8.8,0,15.9-7.1,15.9-15.9c0-7.4-5-13.5-11.9-15.3V853H271.5z" />
                                <text transform="matrix(1 0 0 1 55.0585 831.4146)" style={{ fill: '#23724B', fontFamily: 'Poppins', fontSize: '20px' }}>Parcours Recherche</text>
                            </g>
                        </g>
                        <g>
                            <path style={{ fill: '#C8E9D7' }} d="M76.2,794.3H60.5l-1.2-3.6c0.6,0.1,1.3,0.2,1.9,0.2c2.4,0,4.7-0.6,6.8-1.8l-1.5-2.8c0-0.1,0.1-0.3,0.1-0.4c0-0.8-0.6-1.4-1.4-1.4s-1.4,0.6-1.4,1.4c0,0.2,0,0.4,0.1,0.6c-0.8,0.2-1.7,0.4-2.6,0.4c-5.3,0-9.6-4.1-9.6-9.2c0-3.3,1.8-6.3,4.7-7.9l2.1,3.9l6-3.2l-7.2-13.3l-6,3.2l1.8,3.3c-0.8,0.3-1.4,1.1-1.4,2c0,0.8,0.4,1.4,1,1.8c-3.1,2.5-5,6.2-5,10.3c0,4.9,2.8,9.3,6.9,11.5l-4.2,5.2h-1.5c-1.1,0-2,0.9-2,2v1.7h31.3v-1.7C78.2,795.2,77.4,794.3,76.2,794.3z" />
                            <polygon style={{ fill: '#C8E9D7' }} points="65.2,773.8 64.1,771.7 59.8,774 60.9,776 62.6,776.3 62.6,776.3 64.4,779.7 66.3,778.7 64.4,775.3 64.5,775.3" />
                            <rect x="63.4" y="783" style={{ fill: '#C8E9D7' }} width="14.3" height="1.3" />
                            <rect x="49.8" y="753.5" transform="matrix(-0.4727 -0.8812 0.8812 -0.4727 -588.2682 1159.8929)" style={{ fill: '#588771' }} width="6.2" height="4.8" />
                            <rect x="61.2" y="769.5" transform="matrix(-0.4727 -0.8812 0.8812 -0.4727 -589.7708 1191.967)" style={{ fill: '#588771' }} width="1" height="5.9" />
                            <rect x="47.6" y="748" transform="matrix(-0.4727 -0.8812 0.8812 -0.4727 -587.8101 1150.1125)" style={{ fill: '#588771' }} width="5.2" height="5.9" />
                            <circle style={{ fill: '#588771' }} cx="53.8" cy="765.5" r="0.9" />
                            <rect x="64.4" y="784.3" transform="matrix(-1 -4.479052e-11 4.479052e-11 -1 141.1313 1569.7163)" style={{ fill: '#588771' }} width="12.3" height="1.1" />
                            <circle style={{ fill: '#588771' }} cx="65.2" cy="786" r="0.7" />
                        </g>
                    </svg>
                </div>
                {/*prémier rond point */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: cursus_third_year === 3 ? 5 : 3 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <path style={{ fill: '#EFD992' }} d="M404.1,926.1c-22.8,0-41.3-18.5-41.3-41.3s18.5-41.3,41.3-41.3c22.8,0,41.3,18.5,41.3,41.3 S426.9,926.1,404.1,926.1z M404.1,863.5c-11.7,0-21.3,9.6-21.3,21.3c0,11.7,9.6,21.3,21.3,21.3s21.3-9.6,21.3-21.3 C425.4,873.1,415.9,863.5,404.1,863.5z" />
                    </svg>
                </div>
                {/*deuxième  rond point */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 3 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <path style={{ fill: '#EFD992' }} d="M404.1,1038.6c-22.8,0-41.3-18.5-41.3-41.3s18.5-41.3,41.3-41.3c22.8,0,41.3,18.5,41.3,41.3 S426.9,1038.6,404.1,1038.6z M404.1,976c-11.7,0-21.3,9.6-21.3,21.3s9.6,21.3,21.3,21.3s21.3-9.6,21.3-21.3S415.9,976,404.1,976z" />
                    </svg>
                </div>
                {/*  rond  food supply chain */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: cursus_third_year === 1 ? 4 : 2 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <circle style={{ fill: '#FFFFFF' }} cx="185.1" cy="1128.8" r="24.6" />
                    </svg>
                </div>
                {/* rond agriculture et environnement*/}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 2 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <circle style={{ fill: '#FFFFFF' }} cx="404" cy="1208.2" r="24.6" />
                    </svg>
                </div>
                {/*Parcours 3ème année option 1 food and supply chain  */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: student_journey.years.includes(3) && cursus_third_year === 1 ? 3 : 1 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <path
                            // Vérifie si le parcours utilisateur contient l'année 3 et l'étape 7
                            className={
                                cursus_third_year === 1 &&
                                    student_journey.years.includes(3) &&
                                    student_short_journey.includes(7)
                                    ? 'parcour-3-options-1 parcour-3'
                                    : ''
                            }
                            style={{ fill: '#EFD992' }}
                            d="M195.1,1128.8h-20v-101.4c0-22.1,17.9-40,40-40H364c3.7-14.9,15.5-26.6,30.5-30.2V925
c-18.2-4.3-31.7-20.7-31.7-40.2s13.5-35.8,31.7-40.2V704.4h20v149.1c0,5.5-4.5,10-10,10h-0.4c-11.7,0-21.3,9.6-21.3,21.3
c0,11.7,9.6,21.3,21.3,21.3l0.3,0c2.7,0,5.2,1,7.1,2.9c1.9,1.9,3,4.4,3,7.1V966c0,5.5-4.5,10-10,10h-0.4c-11.7,0-21.3,9.6-21.3,21.3
c0,5.5-4.5,10-10,10H215.1c-11,0-20,9-20,20V1128.8z"
                        />
                    </svg>
                </div>
                {/*Parcours 3ème année agriculture et environnement  */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: cursus_third_year === 0 && student_journey.years.includes(3) ? 3 : 2 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <path
                            style={{ fill: '#EFD992' }}
                            // Vérifie si le parcours utilisateur contient l'année 3 et l'étape 7
                            className={
                                cursus_third_year === 0 &&
                                    student_journey.years.includes(3) &&
                                    student_short_journey.includes(7)
                                    ? 'parcour-3-options-0 parcour-3'
                                    : ''
                            }
                            d="M405.8,1122.9c-5.5,0-10-4.5-10-10v-84.3c0-2.5,0.9-4.8,2.5-6.7c1.8-2,4.3-3.2,7-3.4
c11.3-0.6,20.2-9.9,20.2-21.3c0-11.6-9.4-21.2-21-21.3c-5.5-0.1-9.9-4.5-9.9-10v-49.9c0-5.5,4.4-9.9,9.9-10c11.6-0.1,21-9.7,21-21.3
c0-11.6-9.4-21.2-21-21.3c-5.5-0.1-9.9-4.5-9.9-10V704.4c0-5.5,4.5-10,10-10s10,4.5,10,10v140.5c17.7,4.6,30.9,20.9,30.9,40
c0,19.1-13.2,35.3-30.9,40v32.5c17.7,4.6,30.9,20.9,30.9,40c0,18.7-12.4,34.6-29.6,39.6v76C415.8,1118.4,411.3,1122.9,405.8,1122.9z"
                        />
                    </svg>
                </div>
                {/*Parcours 3ème année food and supply chain  à la diplomation */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: cursus_third_year === 1 && (current_page === 13 || current_page === 14 || current_page === 15 || current_page === 16) ? 5 : 2 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <path
                            // Vérifie si le cursus de 3a est Food Production et si la page actuelle se trouve entre 13 et 16
                            style={{
                                fill: `${cursus_third_year === 1 &&
                                    (
                                        current_page === 13 ||
                                        current_page === 14 || current_page === 15 ||
                                        current_page === 16
                                    )
                                    ? '#821F1D'
                                    : '#EFD992'}`
                            }}
                            d="M415.8,1630.1h-20v-131.8c0-11-9-20-20-20H215.1c-22.1,0-40-17.9-40-40v-230h20v230c0,11,9,20,20,20
h160.7c22.1,0,40,17.9,40,40V1630.1z"
                        />
                    </svg>
                </div>
                {/*Parcours 3ème année de rond agriculture et environnement à l'option transition agroécologique  */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 1 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <path
                            className='parcour-3-options-0-fin'
                            // Vérifie si l'option de 3a choisie correspond à Métier de la transition, si le parcours utilisateur contient l'année 3, si la page actuelle vaut 92, 94, 13, 14, 15, 16 
                            style={{
                                fill: `${cursus_fourth_year === 0 &&
                                    student_journey.years.includes(3) &&
                                    (
                                        current_page === 92 ||
                                        current_page === 94 ||
                                        current_page === 13 ||
                                        current_page === 14 ||
                                        current_page === 15 ||
                                        current_page === 16
                                    )
                                    ? '#821F1D'
                                    : '#EFD992'}`
                            }}
                            d="M338.5,1277.4h-20v-35.2c0-22.1,17.9-40,40-40h31v20h-31c-11,0-20,9-20,20V1277.4z"
                        />
                    </svg>
                </div>
                {/*Parcours 3ème année de l'option transition agroécologique  à la diplomation */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 4 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <path
                            className='parcour-3-options-0-fin'
                            // Vérifie si l'option de 3a choisie correspond à Métier du conseil en Élevage, si la page actuelle vaut 13, 14, 15, 16 
                            style={{
                                fill: `${cursus_fourth_year === 0 &&
                                    (
                                        current_page === 13 ||
                                        current_page === 14 ||
                                        current_page === 15 ||
                                        current_page === 16
                                    )
                                    ? '#821F1D'
                                    : '#EFD992'}`
                            }}
                            d="M415.8,1630.1h-20v-131.8c0-11-9-20-20-20l-17.3,0c-22.1,0-40-17.9-40-40v-22.2h20v22.2
c0,11,9,20,20,20l17.3,0c22.1,0,40,17.9,40,40V1630.1z"
                        />
                    </svg>
                </div>
                {/*Parcours 3ème année de rond agriculture et environnement à l'option conseil en élevage  */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 1 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <path
                            className='parcour-3-options-0-fin'
                            style={{ fill: `${cursus_fourth_year === 1 && (current_page === 13 || current_page === 14 || current_page === 15 || current_page === 16 || current_page === 92 || current_page === 94) ? '#821F1D' : '#EFD992'}` }}
                            d="M494.1,1277.4h-20v-35.2c0-11-9-20-20-20h-31v-20h31c22.1,0,40,17.9,40,40V1277.4z"
                        />
                    </svg>
                </div>
                {/*Parcours 3ème année de l'option conseil en élevage  à la diplomation  */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: cursus_fourth_year === 1 ? 4 : 3 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <path
                            className='parcour-3-options-0-fin'
                            // Vérifie si l'option de 3a choisie correspond à Métier de la transition, si la page actuelle vaut 13, 14, 15, 16 
                            style={{
                                fill: `${cursus_fourth_year === 1 &&
                                    (
                                        current_page === 13 ||
                                        current_page === 14 ||
                                        current_page === 15 ||
                                        current_page === 16
                                    )
                                    ? '#821F1D'
                                    : '#EFD992'}`
                            }}
                            d="M415.8,1630.1h-20v-131.7c0-22.1,17.9-40,40-40h18.3c11,0,20-9,20-20v-22.2h20v22.2
c0,22.1-17.9,40-40,40h-18.3c-11,0-20,9-20,20V1630.1z"
                        />
                    </svg>
                </div>
                {/* Dernier parcours  */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 3 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <rect
                            x="395.8"
                            y="1669.3"
                            // Vérifie si la page actuelle vaut 14, 15, 16 
                            style={{
                                fill: `${current_page === 14 ||
                                        current_page === 15 ||
                                        current_page === 16
                                        ? '#821F1D'
                                        : '#EFD992'}`
                            }}
                            width="20"
                            height="115.4"
                        />
                    </svg>
                </div>
                {/* Dernier parcours  */}
                <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 2 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 1785"
                        style={{ enableBackground: 'new 0 0 750 1785' }}
                        xmlSpace="preserve"
                    >
                        <rect x="331.7" y="1629.3" style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#FFFFFF' }} width="146.5" height="40" />
                        <text transform="matrix(1 0 0 1 349.2303 1654.7087)" style={{ fill: '#213D33', fontFamily: 'Poppins', fontSize: '18px' }}>{textTranslation[language]["map"].graduation}</text>
                    </svg>

                </div>
            </div>
        </>

    )
}