import React from "react";
import { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import { AllLanguages, textTranslation } from "../config/Multilangues";
// import { languageSelected } from "../recoil/Atomes";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import { VscHome } from "react-icons/vsc";
import { MdOutlineExplore } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import { MdClose } from "react-icons/md";
import { FiList } from "react-icons/fi";
import { currentPage, cursusSelected, parcours, yearSelected } from "../recoil/Atomes";
import { useRecoilState, useRecoilValue } from "recoil";

export default function BottomBar() {
  // Barre en bas de l'écran
  const navigate = useNavigate();
  // const [, setLanguage] = useRecoilState(languageSelected);
  const [, setCurrentPage] = useRecoilState(currentPage);
  const selectedCursus = useRecoilValue(cursusSelected,)
  const [, setParcoursEnCours] = useRecoilState(parcours);
  const [, setYear] = useRecoilState(yearSelected);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const currentDisplay = useLocation();
  // console.log(currentDisplay.pathname);

  return (
    <>
      {/* Lors de la page de sélection du parcours */}
      {currentDisplay.pathname === "/" || currentDisplay.pathname === "/home" ? (
        <Navbar className="navbar justify-content-evenly d-flex flex-column pt-1 nav-shadow w-100">
          <p className="text-white fs-6 fw-bold">Mon parcours à Purpan</p>
          <div className="d-flex justify-content-evenly w-100">
            <Modal
              show={show === true}
              onHide={handleClose}
              animation={true}
              centered
              size='lg'
              dialogClassName='popin-bg'
              contentClassName='popin-content'
            >
              <Modal.Header className='border-0'>
                <Modal.Title
                  className='fw-bold'
                  as={"p"}
                >
                  Veuillez choisir un parcours
                </Modal.Title>
                <MdClose size={27} color='#FFC43E' onClick={handleClose} />
              </Modal.Header>
            </Modal>

            <VscHome // Retourne à la page d'accueil
              color="#FFC43E"
              className="cursor"
              size={32}
              onClick={() => {

                // Matomo <
                  
                window._paq.push(['trackEvent', 'Navigation', 'Home', null, null])
                
                // Matomo >

                // navigate("");
                setCurrentPage(0);
                setParcoursEnCours([]);
                setYear("");
              }}
            />
            <MdOutlineExplore // Permet d'aller sur la carte du parcours en cours
              color="#FFC43E"
              className="cursor"
              size={32}
              onClick={() => {

                // Matomo <
                  
                window._paq.push(['trackEvent', 'Navigation', 'Map', 'Open', null])
                
                // Matomo >

                if (selectedCursus === "") {
                  setShow(true)
                }
                else {
                  navigate("/maps");
                }
              }}
            />
            {/* <NavDropdown title={language} className="select-lang" drop="up">
              {AllLanguages.map((lng, key) => (
                <NavDropdown.Item key={key}>{lng}</NavDropdown.Item>
              ))}
            </NavDropdown> */}
          </div>
        </Navbar>
      ) : (
        <Navbar className="navbar justify-content-evenly d-flex flex-column pt-1 nav-shadow w-100">
          <p className="text-white fs-6 fw-bold">Mon parcours à Purpan</p>
          <div className="d-flex justify-content-evenly w-100">

            <FiList // Permet de revenir sur la page de sélection du parcours
              color="#FFC43E"
              className="cursor"
              size={32}
              style={{
                fontWeight: 'normal',
                fontStyle: 'normal',
              }}
              onClick={() => {

                // Matomo <
                  
                window._paq.push(['trackEvent', 'Navigation', 'Map', 'Close', null])
                
                // Matomo >

                navigate("/");
              }}
            />


            {/* <NavDropdown title={language} className="select-lang" drop="up">
              {AllLanguages.map((lng, key) => (
                <NavDropdown.Item key={key}>{lng}</NavDropdown.Item>
              ))}
            </NavDropdown> */}
          </div>
        </Navbar>
      )}
    </>
  );
}
