import { useState } from 'react';
import { useRecoilValue } from "recoil";
import {
  currentPage,
  languageSelected,
  cursusIdSelected,
} from "../recoil/Atomes";
import { textTranslation } from "../config/Multilangues";
import { Card } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { MdClose } from "react-icons/md";
import { switchDisplayLogo } from "./Pictures";
import { stylizeJsonText } from "../services/stylizeJsonText";
import { formatExposant } from '../services/stylizeJsonText';

// Affiche les pages les plus classiques sans aucun choix
export const CursusComponent = () => {
  const cursusId = useRecoilValue(cursusIdSelected);
  const current_page = useRecoilValue(currentPage);
  const language = useRecoilValue(languageSelected);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const valueTextCursus =
    cursusId && current_page > 3
      ? textTranslation[language][cursusId][current_page]
      : textTranslation[language]["general"][current_page];

  return (
    <div className="col-sm-12 px-4">
      {/* Vérifie si une card avec une image doit s'afficher */}
      {valueTextCursus && valueTextCursus.logo ? (
        <Card className="text-white fw-bold home-card px-3 py-2 mt-3 selected"
          style={{
            backgroundColor: `${valueTextCursus.background + '99'}`
          }}>
          <Card.Body className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div
                className="p-3 image-card-bg"
                style={{
                  backgroundColor: `${valueTextCursus.background}33`,
                }}>
                <img
                  src={switchDisplayLogo(valueTextCursus.logo)}
                  alt="..."
                  className=""
                />
              </div>
              <Card.Text className="m-0 p-2 text-start">
                {formatExposant(valueTextCursus.title2)}
              </Card.Text>
            </div>
            {/* Vérifie si la card a une tooltip */}
            {(valueTextCursus.tooltip || valueTextCursus.tooltipTitle) && (
              <div>
                <img
                  src={switchDisplayLogo("tooltip")}
                  alt="tooltip"
                  onClick={() => setShow(valueTextCursus.title)}
                  className='cursor'
                />
                <Modal
                  show={show === valueTextCursus.title}
                  onHide={handleClose}
                  animation={true}
                  centered
                  size='lg'
                  dialogClassName='popin-bg'
                  contentClassName='popin-content'
                >
                  <Modal.Header className='border-0'>
                    <Modal.Title
                      className='fw-bold'
                      as={"p"}
                      dangerouslySetInnerHTML={{ __html: stylizeJsonText(valueTextCursus.tooltipTitle) }}
                    />
                    <MdClose size={27} color='#FFC43E' className='cursor' onClick={() => setShow(false)} />
                  </Modal.Header>
                  <Modal.Body
                    className='pt-0'
                    dangerouslySetInnerHTML={{ __html: stylizeJsonText(valueTextCursus.tooltip) }}
                  />
                </Modal>
              </div>
            )}
          </Card.Body>
        </Card>
      ) : (
        ""
      )}
      {/* Vérifie si un paragraphe doit être affiché */}
      {valueTextCursus && valueTextCursus.paragraph ? (
        <Card className="home-card mt-3 p-3 text-white">
          <Card.Body className="text-start">
            <Card.Text dangerouslySetInnerHTML={{ __html: stylizeJsonText(valueTextCursus.paragraph) }} />
          </Card.Body>
        </Card>
      ) : (
        ""
      )}
    </div>
  );
};
