import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./features/Home";
import Maps from "./features/Maps";
import { ThemeProvider } from "react-bootstrap";
import React from 'react';
//import { _paq } from 'Matomo'; // Déclenchez un événement Matomo

function App() {

  // Matomo <

  React.useEffect(() => {
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://matomo.digeek.fr/js/container_eDec2eOp.js'; s.parentNode.insertBefore(g,s);
  }, [])

  // Matomo >

  // Permet de d'attribuer un préfixe sur certains éléments Bootstrap. Cela a pour but d'éviter les conflits avec les éléments Bootstrap du WordPress
  const prefixes = {
    btn: "my-btn",
    card: "my-card",
    nav: "my-nav",
    navbar: "my-navbar",
  };

  // Retourne les différentes routes du projet : 
  // - "/" et "/home" renvoient à la partie simulation d'un parcours
  // - "/maps" renvoie à la partie de visualisation de la map
  return (
    <div className="App">
      <ThemeProvider prefixes={prefixes}>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/maps" element={<Maps />}></Route>
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;