import { useRecoilValue, useSetRecoilState } from "recoil";
import { characterSelected } from "../recoil/Atomes";
import A from "../img/A_cut.png";
import B from "../img/B_cut.png";
import C from "../img/C_cut.png";
import D from "../img/D_cut.png";
import E from "../img/E_cut.png";
import F from "../img/F_cut.png";
import G from "../img/G_cut.png";

// Sélection d'un personnage
export const SelectCharacter = () => {
  const setCharacter = useSetRecoilState(characterSelected);
  const character = useRecoilValue(characterSelected);

  return (
    <div className="px-4">
      {/* Gérer l'alignement des images */}
      <div className={`col-sm-12 my-4 character-container even-character ${character.name === "A"
        ? "opacity-100"
        : "opacity-50"}`}
        onClick={() => {
          setCharacter({ name: "A", image: A });
        }}>
        <img
          src={A}
          alt="..."
          className="position-absolute img-fluid"
          // className="position-absolute"
          style={{ left: 0, bottom: 0 }}
        />
      </div>
      <div className={`col-sm-12 my-4 character-container odd-character ${character.name === "B"
        ? "opacity-100"
        : "opacity-50"}`}
        onClick={() => {
          setCharacter({ name: "B", image: B });
        }}>
        <img
          id="B"
          src={B}
          alt="..."
          className="position-absolute img-fluid"
          style={{ right: 10, bottom: 0 }}
        />
      </div>
      <div className={`col-sm-12 my-4 character-container even-character ${character.name === "C"
        ? "opacity-100"
        : "opacity-50"}`}
        onClick={() => {
          setCharacter({ name: "C", image: C });
        }}>
        <img
          src={C}
          alt="..."
          className="position-absolute img-fluid"
          style={{ left: -10, bottom: 0 }}

        />
      </div>
      <div className={`col-sm-12 my-4 character-container odd-character ${character.name === "D"
        ? "opacity-100"
        : "opacity-50"}`}
        onClick={() => {
          setCharacter({ name: "D", image: D });
        }}>
        <img
          src={D}
          alt="..."
          className="position-absolute img-fluid"

          style={{ right: 20, bottom: 0 }}
        />
      </div>
      <div className={`col-sm-12 my-4 character-container even-character ${character.name === "E"
        ? "opacity-100"
        : "opacity-50"}`}
        onClick={() => {
          setCharacter({ name: "E", image: E });
        }}>
        <img
          src={E}
          alt="..."
          className="position-absolute img-fluid"
          style={{ left: 10, bottom: 0 }}
        />
      </div>
      <div className={`col-sm-12 my-4 character-container odd-character ${character.name === "F"
        ? "opacity-100"
        : "opacity-50"}`}
        onClick={() => {
          setCharacter({ name: "F", image: F });
        }}>
        <img
          src={F}
          alt="..."
          className="position-absolute img-fluid"
          style={{ right: 20, bottom: 0 }}
        />
      </div>
      <div className={`col-sm-12 my-4 character-container even-character ${character.name === "G"
        ? "opacity-100"
        : "opacity-50"}`}
        onClick={() => {
          setCharacter({ name: "G", image: G });
        }}>
        <img
          src={G}
          alt="..."
          className="position-absolute img-fluid"
          style={{ left: -20, bottom: 0 }}
          onClick={() => {
            setCharacter({
              name: "G",
              image: G,
            });
          }}
        />
      </div>
    </div>
  );
};
