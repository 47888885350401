import React, { useEffect, useCallback } from "react";
import ReactConfetti from "react-confetti";
import carte_engineer from "../ingenieur2/carte-ingenieur.svg";
import Header from "../features/Header";
import { filterYears } from "../services/filterYears";
import { textTranslation } from "../config/Multilangues";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  currentPage,
  cursusThirdYear,
  cursusFourthYear,
  engineeringCourse,
  studentJourney,
  selectDiplome,
  cursusFifthYear,
  selectDoubleDiplome,
  studentShortJourney,
  parcours,
  isDoingTBS,
  yearSelected,
  selectDaFourthYear,
} from "../recoil/Atomes";

export default function EngineerMap({ languag }) {
  const [student_short_journey, setStudentShortJourney] =
    useRecoilState(studentShortJourney);
  const [student_journey, setStudentJourney] = useRecoilState(studentJourney);
  const selectedYear = useRecoilValue(yearSelected);
  const [current_page, setcurrent_page] = useRecoilState(currentPage);
  const [cursus_fourth_year, setCursusFourthYear] =
    useRecoilState(cursusFourthYear);
  const [cursus_third_year, setCursusThirdYear] =
    useRecoilState(cursusThirdYear);
  const [engineering_course, setEngineeringCourse] =
    useRecoilState(engineeringCourse);
  const [select_diplome, setSelectDiplome] = useRecoilState(selectDiplome);
  const [cursus_fifth_Year, setCursusFifthYear] =
    useRecoilState(cursusFifthYear);
  const [select_double_diplome, setSelectDoubleDiplome] =
    useRecoilState(selectDoubleDiplome);
  const [select_da, setDA] = useRecoilState(selectDaFourthYear);
  const [isTbs, setIsTbs] = useRecoilState(isDoingTBS);
  const [parcoursEnCours, setParcoursEnCours] = useRecoilState(parcours);
  const language = "FR";

  const setColorParcours = useCallback(
    (color) => {
      let cursusThirdYearAgro = document.querySelectorAll(
        `.parcour-3-options-${cursus_third_year}`
      );
      let cursusThirdYearAgroPanneau = document.querySelectorAll(
        `.parcour-3-options-${cursus_third_year}-panneau`
      );

      //Definir la couleur et la taille de text  dans le parcour et panneau
      student_journey.years.forEach((year) => {
        let parcour = document.querySelectorAll(`.parcour-${year}`);
        let PanneauStage = document.querySelectorAll(
          `.parcour-${year}-panneau`
        );
        let PanneauAnnée = document.querySelectorAll(
          `.parcour-${year}-panneau-année`
        );
        if (parcour) {
          // console.log(parcour)
          parcour.forEach((element) => {
            // console.log(parcour)
            // couleur rouge
            if (element) element.setAttribute("style", "fill:#821F1D");
          });
        }
        if (PanneauStage) {
          PanneauStage.forEach((element) => {
            if (element) {
              if (
                element.innerHTML.includes("STAGE") ||
                element.innerHTML.includes("INTERNSHIP")
              ) {
                element.setAttribute("class", "parcour-panneau-stage");
              } else {
                element.setAttribute("class", "parcour-panneau-stage-details");
              }
              // element.setAttribute('style', 'fill:white')
            }
          });
        }
        // Contient tous les panneaux des années se situant dans le parcours de l'utilisateur
        if (PanneauAnnée) {
          PanneauAnnée.forEach((element) => {
            element.addEventListener("click", () => {
              // Lors du clic sur le panneau de 1a
              if (
                element.className.baseVal.includes("panneau-1") &&
                element.className.baseVal.includes("parcour-1-panneau-année")
              ) {
                setStudentShortJourney([]);
                setCursusFourthYear("");
                setCursusThirdYear("");
                setCursusFifthYear("");
                setEngineeringCourse("");
                setStudentJourney({ years: [1] });
                setcurrent_page(5);
                setSelectDiplome("");
                setSelectDoubleDiplome("");

                //if (document.querySelector(`.parcour-1-fin`)) document.querySelector(`.parcour-1-fin`).setAttribute('style', 'fill:#EFD992')
                // if (document.querySelector(`.parcour-2-fin`)) document.querySelector(`.parcour-1-fin`).setAttribute('style', 'fill:#EFD992')
                if (document.querySelector(`.parcour-3-options-1-fin`))
                  document
                    .querySelector(`.parcour-3-options-1-fin`)
                    .setAttribute("style", "fill:#EFD992");
                if (document.querySelector(`.parcour-3-options-0-fin`))
                  document
                    .querySelector(`.parcour-3-options-0-fin`)
                    .setAttribute("style", "fill:#EFD992");
                if (document.querySelector(`.parcour-4-options`))
                  document
                    .querySelector(`.parcour-4-options`)
                    .setAttribute("style", "fill:#EFD992");
                if (document.querySelector(`.parcour-4-options-panneau`))
                  document
                    .querySelector(`.parcour-4-options`)
                    .setAttribute("style", "fill:#213D33");
                student_journey.years.forEach((year) => {
                  document
                    .querySelectorAll(`.parcour-${year}`)
                    .forEach((parcour) => {
                      // verifie si c'est un panneau ou parcours
                      if (parcour.className.baseVal.includes("panneau")) {
                        parcour.setAttribute("style", "fill:#F6C459");
                      } else {
                        parcour.setAttribute("style", "fill:#EFD992");
                      }
                    });
                  document
                    .querySelectorAll(`.parcour-3-options-${cursus_third_year}`)
                    .forEach((parcour) => {
                      // verifie si c'est un panneau ou parcours
                      if (parcour.className.baseVal.includes("panneau")) {
                        // couleur jaune foncé
                        parcour.setAttribute("style", "fill:#F6C459");
                      } else {
                        // couleur jaune parcours
                        parcour.setAttribute("style", "fill:#EFD992");
                      }
                    });

                  document
                    .querySelectorAll(`#parcour-${year}-panneau`)
                    .forEach((parcourpanneau) => {
                      //couleur noire
                      parcourpanneau.setAttribute(
                        "style",
                        "fill:#213D33;font-family:Poppins"
                      );
                    });

                  document
                    .querySelectorAll(`.parcour-5-options`)
                    .forEach((parcourpanneau) => {
                      // couleur jaune parcours
                      parcourpanneau.setAttribute(
                        "style",
                        "fill:#EFD992;font-family:Poppins"
                      );
                    });
                  document
                    .querySelectorAll(`.parcour-5-options-panneau`)
                    .forEach((parcourpanneau) => {
                      // couleur jaune foncé panneau
                      parcourpanneau.setAttribute(
                        "style",
                        "fill:#F6C459;font-family:Poppins"
                      );
                    });
                });
              }
              // Lors du clic sur le panneau de 2a
              else if (element.className.baseVal.includes("panneau-3")) {
                setCursusThirdYear("");
                setCursusFourthYear("");
                setCursusFifthYear("");
                setStudentJourney({ years: filterYears([1, 2], selectedYear) });
                setStudentShortJourney([1, 2]);
                setEngineeringCourse("");
                setcurrent_page(7);
                setSelectDiplome("");
                setSelectDoubleDiplome("");

                //document.querySelector(`.parcour-1-fin`).setAttribute('style', 'fill:#EFD992')
                if (document.querySelector(`.parcour-3-options-1-fin`))
                  document
                    .querySelector(`.parcour-3-options-1-fin`)
                    .setAttribute("style", "fill:#EFD992");
                if (document.querySelector(`.parcour-3-options-0-fin`))
                  document
                    .querySelector(`.parcour-3-options-0-fin`)
                    .setAttribute("style", "fill:#EFD992");
                if (document.querySelector(`.parcour-4-options`))
                  document
                    .querySelector(`.parcour-4-options`)
                    .setAttribute("style", "fill:#EFD992");
                if (document.querySelector(`.parcour-4-options-panneau`))
                  document
                    .querySelector(`.parcour-4-options`)
                    .setAttribute("style", "fill:#213D33");
                student_journey.years
                  .filter((year) => year > 1)
                  .forEach((year) => {
                    document
                      .querySelectorAll(`.parcour-${year}`)
                      .forEach((parcour) => {
                        // verifie si c'est un panneau ou parcours
                        if (parcour.className.baseVal.includes("panneau")) {
                          parcour.setAttribute("style", "fill:#F6C459");
                        } else {
                          parcour.setAttribute("style", "fill:#EFD992");
                        }
                      });
                    document
                      .querySelectorAll(
                        `.parcour-3-options-${cursus_third_year}`
                      )
                      .forEach((parcour) => {
                        // verifie si c'est un panneau ou parcours
                        if (parcour.className.baseVal.includes("panneau")) {
                          // couleur jaune foncé
                          parcour.setAttribute("style", "fill:#F6C459");
                        } else {
                          // couleur jaune parcours
                          parcour.setAttribute("style", "fill:#EFD992");
                        }
                      });

                    document
                      .querySelectorAll(`#parcour-${year}-panneau`)
                      .forEach((parcourpanneau) => {
                        //couleur noire
                        parcourpanneau.setAttribute(
                          "style",
                          "fill:#213D33;font-family:Poppins"
                        );
                      });

                    document
                      .querySelectorAll(`.parcour-5-options`)
                      .forEach((parcourpanneau) => {
                        // couleur jaune parcours
                        parcourpanneau.setAttribute(
                          "style",
                          "fill:#EFD992;font-family:Poppins"
                        );
                      });
                    document
                      .querySelectorAll(`.parcour-5-options-panneau`)
                      .forEach((parcourpanneau) => {
                        // couleur jaune foncé panneau
                        parcourpanneau.setAttribute(
                          "style",
                          "fill:#F6C459;font-family:Poppins"
                        );
                      });
                  });
              }
              // Lors du clic sur le panneau de 3a
              else if (element.className.baseVal.includes("panneau-5")) {
                setStudentShortJourney([1, 2, 3, 4]);
                setCursusFourthYear("");
                setCursusThirdYear("");
                setCursusFifthYear("");
                setStudentJourney({
                  years: filterYears([1, 2, 3], selectedYear),
                });
                setcurrent_page(9);
                setSelectDiplome("");
                setEngineeringCourse("");
                //document.querySelector(`.parcour-1-fin`).setAttribute('style', 'fill:#EFD992')
                student_journey.years
                  .filter((year) => year >= 3)
                  .forEach((year) => {
                    document
                      .querySelectorAll(`.parcour-${year}`)
                      .forEach((parcour) => {
                        // verifie si c'est un panneau ou parcours
                        if (parcour.className.baseVal.includes("panneau")) {
                          parcour.setAttribute("style", "fill:#F6C459");
                        } else {
                          parcour.setAttribute("style", "fill:#EFD992");
                        }
                      });
                    document
                      .querySelectorAll(
                        `.parcour-3-options-${cursus_third_year}`
                      )
                      .forEach((parcour) => {
                        // verifie si c'est un panneau ou parcours
                        if (parcour.className.baseVal.includes("panneau")) {
                          // couleur jaune foncé
                          parcour.setAttribute("style", "fill:#F6C459");
                        } else {
                          // couleur jaune parcours
                          parcour.setAttribute("style", "fill:#EFD992");
                        }
                      });
                    document
                      .querySelectorAll(`#parcour-${year}-panneau`)
                      .forEach((parcourpanneau) => {
                        //couleur noire
                        parcourpanneau.setAttribute(
                          "style",
                          "fill:#213D33;font-family:Poppins"
                        );
                      });
                    document
                      .querySelectorAll(`.parcour-5-options`)
                      .forEach((parcourpanneau) => {
                        // couleur jaune parcours
                        parcourpanneau.setAttribute(
                          "style",
                          "fill:#EFD992;font-family:Poppins"
                        );
                      });
                    document
                      .querySelectorAll(`.parcour-5-options-panneau`)
                      .forEach((parcourpanneau) => {
                        // couleur jaune foncé panneau
                        parcourpanneau.setAttribute(
                          "style",
                          "fill:#F6C459;font-family:Poppins"
                        );
                      });
                  });
              }
              // Lors du clic sur le panneau de 4a
              else if (element.className.baseVal.includes("panneau-7")) {
                setStudentShortJourney([1, 2, 3, 4, 5, 6, 7]);
                // setCursusFourthYear(0);
                // setCursusThirdYear(0);
                setCursusFifthYear("");
                setStudentJourney({
                  years: filterYears([1, 2, 3, 4], selectedYear),
                });
                setcurrent_page(170);
                setSelectDiplome("");
                setSelectDoubleDiplome("");
                setDA("");
                setIsTbs("");
                // setEngineeringCourse("")
                //document.querySelector(`.parcour-1-fin`).setAttribute('style', 'fill:#EFD992')
                student_journey.years
                  .filter((year) => year > 4)
                  .forEach((year) => {
                    document
                      .querySelectorAll(`.parcour-${year}`)
                      .forEach((parcour) => {
                        // verifie si c'est un panneau ou parcours
                        if (parcour.className.baseVal.includes("panneau")) {
                          parcour.setAttribute("style", "fill:#F6C459");
                        } else {
                          parcour.setAttribute("style", "fill:#EFD992");
                        }
                      });

                    document
                      .querySelectorAll(`#parcour-${year}-panneau`)
                      .forEach((parcourpanneau) => {
                        //couleur noire
                        parcourpanneau.setAttribute(
                          "style",
                          "fill:#213D33;font-family:Poppins"
                        );
                      });
                    document
                      .querySelectorAll(`.parcour-5-options`)
                      .forEach((parcourpanneau) => {
                        // couleur jaune parcours
                        parcourpanneau.setAttribute(
                          "style",
                          "fill:#EFD992;font-family:Poppins"
                        );
                      });
                    document
                      .querySelectorAll(`.parcour-5-options-panneau`)
                      .forEach((parcourpanneau) => {
                        // couleur jaune foncé panneau
                        parcourpanneau.setAttribute(
                          "style",
                          "fill:#F6C459;font-family:Poppins"
                        );
                      });
                  });

                // Panneau 5ème année
              } else if (element.className.baseVal.includes("panneau-12")) {
                setStudentShortJourney([1, 2, 3, 4, 5, 6, 7, 9]);
                setCursusFifthYear("");
                setStudentJourney({
                  years: filterYears([1, 2, 3, 4, 5], selectedYear),
                });
                setcurrent_page(18);

                student_journey.years
                  .filter((year) => year >= 5)
                  .forEach((year) => {
                    document
                      .querySelectorAll(`.parcour-${year}`)
                      .forEach((parcour) => {
                        // verifie si c'est un panneau ou parcours
                        if (parcour.className.baseVal.includes("panneau")) {
                          parcour.setAttribute("style", "fill:#F6C459");
                        } else {
                          parcour.setAttribute("style", "fill:#EFD992");
                        }
                      });

                    document
                      .querySelectorAll(`#parcour-${year}-panneau`)
                      .forEach((parcourpanneau) => {
                        //couleur noire
                        parcourpanneau.setAttribute(
                          "style",
                          "fill:#213D33;font-family:Poppins"
                        );
                      });
                    document
                      .querySelectorAll(`.parcour-5-options`)
                      .forEach((parcourpanneau) => {
                        // couleur jaune parcours
                        parcourpanneau.setAttribute(
                          "style",
                          "fill:#EFD992;font-family:Poppins"
                        );
                      });
                    document
                      .querySelectorAll(`.parcour-5-options-panneau`)
                      .forEach((parcourpanneau) => {
                        // couleur jaune foncé panneau
                        parcourpanneau.setAttribute(
                          "style",
                          "fill:#F6C459;font-family:Poppins"
                        );
                      });
                  });
              }
              // Lors du clic sur le panneau de 6a
              else if (element.className.baseVal.includes("panneau-22")) {
                setcurrent_page(185);
                setStudentShortJourney([1, 2, 3, 4, 5, 6, 7, 9, 10]);
                student_journey.years
                  .filter((year) => year >= 6)
                  .forEach((year) => {
                    document
                      .querySelectorAll(`.parcour-${year}`)
                      .forEach((parcour) => {
                        // verifie si c'est un panneau ou parcours
                        if (parcour.className.baseVal.includes("panneau")) {
                          parcour.setAttribute("style", "fill:#F6C459");
                        } else {
                          parcour.setAttribute("style", "fill:#EFD992");
                        }
                      });
                    document
                      .querySelectorAll(`#parcour-${year}-panneau`)
                      .forEach((parcourpanneau) => {
                        //couleur noire
                        parcourpanneau.setAttribute(
                          "style",
                          "fill:#213D33;font-family:Poppins"
                        );
                      });
                  });
                setStudentJourney({
                  years: filterYears([1, 2, 3, 4, 5, 6], selectedYear),
                });
              }
            });
          });
        }
      });

      //Definir la couleur et la taille de text  dans les options de 3ème année et leur  panneau
      cursusThirdYearAgro.forEach((element) => {
        if (element) element.setAttribute("style", "fill:#821F1D");
      });
      cursusThirdYearAgroPanneau.forEach((element) => {
        if (
          element.innerHTML.includes(
            textTranslation[language]["map"].alternance
          )
        ) {
          if (element)
            element.setAttribute("class", "parcour-3-panneau-alternance");
        } else {
          if (element)
            element.setAttribute("class", "parcour-3-panneau-details");
        }
        // element.setAttribute('style', 'fill:white')
      });
    },
    [
      cursus_third_year,
      selectedYear,
      setCursusFifthYear,
      setCursusFourthYear,
      setCursusThirdYear,
      setDA,
      setEngineeringCourse,
      setIsTbs,
      setSelectDiplome,
      setSelectDoubleDiplome,
      setStudentJourney,
      setStudentShortJourney,
      setcurrent_page,
      student_journey.years,
    ]
  );

  React.useEffect(() => {
    setColorParcours();
  }, [setColorParcours]);

  useEffect(() => {
    // Ceci permet de mettre à jour la var parcoursEnCours qui est envoyé au pdf lorsqu'on parcours les pages
    // Trouver l'index de l'élément avec l'ID X
    const index = parcoursEnCours.findIndex(
      (item) => item.page === current_page
    );

    if (index !== -1) {
      // Si l'ID X existe dans le tableau, supprimer les éléments jusqu'à cet index
      const newData = parcoursEnCours.slice(0, index); // +1 pour inclure l'élément avec l'ID X

      // Mettre à jour le tableau avec le nouveau tableau résultant
      setParcoursEnCours(newData);
    }
  }, [current_page, parcoursEnCours, setParcoursEnCours]);

  return (
    <>
      <Header />
      {/* Affiche les confetti à la page 13 */}
      {current_page === 13 && (
        <ReactConfetti
          numberOfPieces={150}
          width={window.innerWidth}
          height={window.innerHeight}
        />
      )}
      {/* carte ingenieur  */}
      <div id="fullHeightMap">
        {/* carte ingenieur  */}
        <div
          style={{
            position: "absolute",
            top: "0",
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1,
          }}
        >
          <img
            src={carte_engineer}
            // className="entrance-image"
            loading="lazy"
            alt="ingenieur"
          />
          <div style={{ height: "12vh", backgroundColor: "#183E32" }}></div>
        </div>
        {/* Titre cursus ingénieur  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 1870"
            style={{ enableBackground: "new 0 0 750 1870" }}
            xmlSpace="preserve"
          >
            <g>
              <path
                style={{
                  fillRule: "evenodd",
                  clipRule: "evenodd",
                  fill: "#7F5A41",
                }}
                d="M184.6,105.1h-7.3c-0.4,0-0.8-0.3-0.8-0.8V48.5c0-0.4,0.3-0.8,0.8-0.8h7.3c0.4,0,0.8,0.3,0.8,0.8v55.8C185.4,104.7,185,105.1,184.6,105.1z"
              />
              <rect
                x="130.2"
                y="19.7"
                style={{
                  fillRule: "evenodd",
                  clipRule: "evenodd",
                  fill: "#FFFFFF",
                  stroke: "#F6C459",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeMiterlimit: 10,
                }}
                width="101.6"
                height="29.7"
              />
            </g>
            <g>
              <path
                style={{ fill: "#7FA195" }}
                d="M176.1,106.1c0-1.6-0.5-3.6-1.1-5.4c-0.7-1.8-1.5-3.6-2.6-5.4c-1.1-1.7-2.3-3.4-3.6-4.9c-1.3-1.6-2.8-3-4.4-4.4c1.8,1.1,3.5,2.3,5.1,3.6c1.6,1.3,3.1,2.8,4.6,4.4c1.4,1.6,2.7,3.4,3.7,5.3c1.1,2,2,3.8,2.5,6.4L176.1,106.1z"
              />
              <path
                style={{ fill: "#7FA195" }}
                d="M177.7,105.1c0.3-1.5,0.3-3.7,0-5.5c-0.3-1.9-0.7-3.9-1.4-5.8c-0.7-1.9-1.5-3.8-2.5-5.5c-1-1.8-2.1-3.5-3.3-5.2c1.5,1.4,2.9,3,4.2,4.6c1.3,1.6,2.5,3.4,3.5,5.3c1,1.9,1.9,3.9,2.5,6c0.6,2.1,1.1,4.2,1.1,6.8L177.7,105.1z"
              />
              <path
                style={{ fill: "#7FA195" }}
                d="M181.3,104.9c-0.3-2.3-0.3-4.4-0.2-6.5c0.1-2.1,0.4-4.3,0.9-6.3c0.4-2.1,1.1-4.1,2-6c0.8-1.9,2-3.7,3.1-5.4c-0.9,1.9-1.7,3.7-2.1,5.7c-0.5,2-0.8,3.9-0.9,5.9c-0.1,2,0,4,0.2,5.9c0.2,1.9,0.6,4,1.2,5.6L181.3,104.9z"
              />
              <path
                style={{ fill: "#7FA195" }}
                d="M175.2,106.4c-0.3-2.3-0.3-4.4-0.2-6.5c0.1-2.1,0.4-4.3,0.9-6.3c0.4-2.1,1.1-4.1,2-6c0.8-1.9,2-3.7,3.1-5.4c-0.9,1.9-1.7,3.7-2.1,5.7c-0.5,2-0.8,3.9-0.9,5.9c-0.1,2,0,4,0.2,5.9c0.2,1.9,0.6,4,1.2,5.6L175.2,106.4z"
              />
              <path
                style={{ fill: "#7FA195" }}
                d="M182.9,103.7c0.9-1.8,2-3,3.2-4.3c1.2-1.3,2.6-2.4,4-3.3c1.5-0.9,3-1.6,4.7-1.9c1.6-0.4,3.3-0.5,4.9-0.4c-1.6,0.3-3.1,0.7-4.5,1.4c-1.4,0.7-2.7,1.6-3.8,2.6c-1.1,1-2,2.2-2.8,3.5c-0.7,1.2-1.4,2.7-1.7,3.9L182.9,103.7z"
              />
              <path
                style={{ fill: "#7FA195" }}
                d="M181,106.4c-1-1.7-1.6-3.3-2-5c-0.4-1.7-0.7-3.4-0.7-5.1c0-1.7,0.3-3.4,0.8-5c0.5-1.6,1.3-3,2.2-4.4c-0.6,1.5-1.1,3-1.2,4.6c-0.2,1.5-0.1,3.1,0.3,4.6c0.3,1.5,0.8,2.9,1.5,4.2c0.7,1.3,1.6,2.6,2.4,3.5L181,106.4z"
              />
              <path
                style={{ fill: "#7FA195" }}
                d="M181,104.3c0.6-1.8,1.4-3.2,2.3-4.7c0.9-1.5,1.9-2.9,3-4.1c1.1-1.3,2.4-2.4,3.7-3.4c1.4-1,2.9-1.7,4.4-2.3c-1.4,0.9-2.6,1.9-3.7,3.1c-1,1.2-2,2.5-2.7,3.8c-0.7,1.4-1.4,2.8-1.8,4.3c-0.5,1.4-0.9,3-1.1,4.4L181,104.3z"
              />
              <path
                style={{ fill: "#7FA195" }}
                d="M175,105.5c-0.5-1.3-1.2-2.7-2-4c-0.8-1.3-1.7-2.6-2.7-3.7c-1-1.2-2.2-2.2-3.5-3.1c-1.3-0.9-2.7-1.6-4.3-2.2c1.6,0.2,3.2,0.6,4.8,1.2c1.5,0.7,3,1.5,4.4,2.5c1.4,1,2.7,2.1,3.8,3.4c1.2,1.3,2.2,2.5,3.3,4.1L175,105.5z"
              />
              <path
                style={{
                  fillRule: "evenodd",
                  clipRule: "evenodd",
                  fill: "#7FA195",
                }}
                d="M173.7,102.7c0,0,0.8,4.2-1.7,4.2h16.7c0,0-1.8-0.8-1-4.2L173.7,102.7z"
              />
            </g>
            <text
              transform="matrix(1 0 0 1 150.9178 38.0366)"
              style={{
                fill: "#213D33",
                fontFamily: "Poppins",
                fontWeight: "bold",
                fontSize: 12.1393,
              }}
            >
              {textTranslation[language]["map"].title_cursus.engineer}
            </text>
          </svg>
        </div>
        {/* Tous les panneaux  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 11,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            {/* panneau stage de fin d'étude sur DD TBS en france  */}
            <path
              // Vérifie si le parcours contient l'étape 11
              className={
                student_short_journey.includes(11) ? "parcour-6 panneau-23" : ""
              }
              style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                fill: "#F6C459",
              }}
              d="M576.6,2787.5c-7.1,0-13.1,4.7-15.1,11.1h-24.3v-31.2H360.9v62
                h176.3v-22.7h23.9c1.5,7.2,7.9,12.6,15.5,12.6c8.8,0,15.9-7.1,15.9-15.9S585.4,2787.5,576.6,2787.5z"
            />
            {/* Clic désactivé pour faire fonctionner la couleur */}
            <text
              className={"panneau-23"}
              id=""
              transform="matrix(1 0 0 1 375.0997 2814.9963)"
              // Vérifie si le parcours contient l'étape 11 et l'année 6
              style={{
                fill: `${
                  student_short_journey.includes(11) &&
                  student_journey.years.includes(6)
                    ? "white"
                    : ""
                }`,
                fontFamily: "Poppins",
                fontSize: "20px",
              }}
            >
              de fin d’études
            </text>
            <text
              className="panneau-23 titre"
              // id='parcour-6-panneau'
              transform="matrix(1 0 0 1 374.4806 2792.9739)"
              // Vérifie si le parcours contient l'étape 11 et l'année 6
              style={{
                fill: `${
                  student_short_journey.includes(11) &&
                  student_journey.years.includes(6)
                    ? "white"
                    : ""
                }`,
                fontFamily: "Poppins",
                fontWeight: "Bold",
                fontSize: "20px",
              }}
            >
              STAGE
            </text>
            {/* panneau 4ème année */}
            <rect
              x="321.2"
              y="845.1"
              // Vérifie si le parcours contient l'année 4'
              className={`${
                student_journey.years.includes(4)
                  ? "panneau-7 parcour-4-panneau-année"
                  : ""
              }`}
              style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                fill: "#213D33",
              }}
              width="131.6"
              height="40"
            />
            <text
              // Vérifie si le parcours contient l'année 4
              className={`${
                student_journey.years.includes(4)
                  ? "panneau-7 parcour-4-panneau-année"
                  : ""
              }`}
              transform="matrix(1 0 0 1 346.7442 870.4993)"
              style={{
                fill: "#FFFFFF",
                fontFamily: "Poppins",
                fontSize: "18px",
              }}
            >
              {textTranslation[language]["map"].year.year4}
            </text>

            {/* panneau 5ème année */}
            <rect
              // Vérifie si le parcours contient l'année 5
              className={`${
                student_journey.years.includes(5)
                  ? "parcour-5-panneau-année panneau-12"
                  : ""
              }`}
              x="321.2"
              y="1350"
              style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                fill: "#213D33",
              }}
              width="131.6"
              height="40"
            />
            <text
              // Vérifie si le parcours contient l'année 5
              className={`${
                student_journey.years.includes(5)
                  ? "parcour-5-panneau-année panneau-12"
                  : ""
              }`}
              transform="matrix(1 0 0 1 346.816 1375.4143)"
              style={{
                fill: "#FFFFFF",
                fontFamily: "Poppins",
                fontSize: "18px",
              }}
            >
              {textTranslation[language]["map"].year.year5}
            </text>
            {/* panneau 6ème année */}
            <rect
              // Vérifie si le parcours contient l'année 6
              className={`${
                student_journey.years.includes(6)
                  ? "parcour-6-panneau-année panneau-22"
                  : ""
              }`}
              x="511.2"
              y="2638.9"
              style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                fill: "#213D33",
              }}
              width="131.6"
              height="40"
            />

            <text
              // Vérifie si le parcours contient l'année 6
              className={`${
                student_journey.years.includes(6)
                  ? "parcour-6-panneau-année panneau-22"
                  : ""
              }`}
              transform="matrix(1 0 0 1 536.7467 2664.2756)"
              style={{
                fill: "#FFFFFF",
                fontFamily: "Poppins",
                fontSize: "18px",
              }}
            >
              {textTranslation[language]["map"].year.year6}
            </text>
            {/* panneau DD TSM */}
            <path
              className="panneau-13 parcour-5-options-panneau parcour-5-options-double-diplome-0"
              // Vérifie si le cursus de 5a est classique, si le double diplôme est sélectionnée, si le double diplôme correspond à TSM
              style={{
                fill: `${
                  cursus_fifth_Year === 0 &&
                  select_diplome === 1 &&
                  select_double_diplome === 0
                    ? "#821F1D"
                    : "#F6C459"
                }`,
                fontSize: 20,
              }}
              d="M320.4,1817.9v-40.5h-110v40.5h51.8v25.4c-7.2,1.5-12.6,7.9-12.6,15.5c0,8.8,7.1,15.9,15.9,15.9
                s15.9-7.1,15.9-15.9c0-7.1-4.7-13.1-11.1-15.1v-25.8H320.4z"
            />
            <text
              className="panneau-13 parcour-5-options-double-diplome-0"
              transform="matrix(1 0 0 1 228.7003 1804.4299)"
              // Vérifie si le cursus de 5a est classique, si le double diplôme est sélectionnée, si le double diplôme correspond à TSM
              style={{
                fill: `${
                  cursus_fifth_Year === 0 &&
                  select_diplome === 1 &&
                  select_double_diplome === 0
                    ? "white"
                    : "#213D33"
                }`,
                fontFamily: "Poppins",
                fontSize: "20px",
              }}
            >
              DD TSM
            </text>

            {/* panneau DD à l'étranger */}
            <path
              className="panneau-17 parcour-5-options-double-diplome-etranger-2"
              // Vérifie si le cursus Université étrangère est choisie en 4a, si le double diplôme à l'étranger est sélectionné et si le cursus de 5a est classique
              style={{
                fill: `${
                  engineering_course === 1 &&
                  select_diplome === 2 &&
                  cursus_fifth_Year === 0
                    ? "#821F1D"
                    : "#F6C459"
                }`,
              }}
              d="M736,2200v-63.1H596.3v63.1h66v25.1c-6.8,1.8-11.8,8-11.8,15.3c0,8.8,7.1,15.9,15.9,15.9
                s15.9-7.1,15.9-15.9c0-7.4-5-13.6-11.9-15.3V2200H736z"
            />
            <text
              className="panneau-17 parcour-5-options-double-diplome-etranger-2"
              transform="matrix(1 0 0 1 652.7631 2163.2136)"
              // Vérifie si le cursus Université étrangère est choisie en 4a, si le double diplôme à l'étranger est sélectionné et si le cursus de 5a est classique
              style={{
                fill: `${
                  engineering_course === 1 &&
                  select_diplome === 2 &&
                  cursus_fifth_Year === 0
                    ? "white"
                    : "#213D33"
                }`,
                fontFamily: "Poppins",
                fontSize: "20px",
              }}
            >
              DD
            </text>
            <text
              className="panneau-17 parcour-5-options-double-diplome-etranger-2"
              transform="matrix(1 0 0 1 609.3631 2187.2136)"
              // Vérifie si le cursus Université étrangère est choisie en 4a, si le double diplôme à l'étranger est sélectionné et si le cursus de 5a est classique
              style={{
                fill: `${
                  engineering_course === 1 &&
                  select_diplome === 2 &&
                  cursus_fifth_Year === 0
                    ? "white"
                    : "#213D33"
                }`,
                fontFamily: "Poppins",
                fontSize: "20px",
              }}
            >
              à l’étranger
            </text>
            {/* panneau stage de fin d'étude sur DD au dessus de DA */}
            <path
              className="panneau-20 parcour-5-options-panneau parcour-5-options-panneau-fin "
              // (Vérifie si le cursus de 5a est classique, si le double diplôme est sélectionné, si le parcours contient l'étape 8, si le double diplome choisi est TSM, ENSFEA ou UT2JENSFEA)
              // OU
              // (Vérifie si le parcours contient l'étape 8 et si le parcours de 4a est en université étrangère, si le double diplome est choisi, si le cursus est classique)
              style={{
                fill: `${
                  (cursus_fifth_Year === 0 &&
                    select_diplome === 1 &&
                    student_short_journey.includes(8) &&
                    (select_double_diplome === 0 ||
                      select_double_diplome === 1 ||
                      select_double_diplome === 2)) ||
                  (student_short_journey.includes(8) &&
                    engineering_course === 1 &&
                    select_diplome === 2 &&
                    cursus_fifth_Year === 0)
                    ? "#821F1D"
                    : "#F6C459"
                }`,
              }}
              d="M387.7,2368.3c-7.1,0-13.1,4.7-15.1,11.1h-31.3v-31.2H165v62h176.3v-22.7h30.9
                c1.5,7.2,7.9,12.6,15.5,12.6c8.8,0,15.9-7.1,15.9-15.9S396.4,2368.3,387.7,2368.3z"
            />
            <text
              className="panneau-20"
              id="parcour-5-panneau parcour-5-panneau-fin"
              transform="matrix(1 0 0 1 179.1358 2395.7917)"
              // (Vérifie si le cursus de 5a est classique, si le double diplôme est sélectionné, si le parcours contient l'étape 8, si le double diplome choisi est TSM, ENSFEA ou UT2JENSFEA)
              // OU
              // (Vérifie si le parcours contient l'étape 8 et si le parcours de 4a est en université étrangère, si le double diplome est choisi, si le cursus est classique)
              style={{
                fill: `${
                  (cursus_fifth_Year === 0 &&
                    select_diplome === 1 &&
                    student_short_journey.includes(8) &&
                    (select_double_diplome === 0 ||
                      select_double_diplome === 1 ||
                      select_double_diplome === 2)) ||
                  (student_short_journey.includes(8) &&
                    engineering_course === 1 &&
                    select_diplome === 2 &&
                    cursus_fifth_Year === 0)
                    ? "white"
                    : "#213D33"
                }`,
                fontFamily: "Poppins",
                fontSize: "20px",
              }}
            >
              de fin d’études
            </text>
            <text
              className="panneau-20"
              id="parcour-5-panneau parcour-5-panneau-fin"
              transform="matrix(1 0 0 1 178.5167 2373.7693)"
              // (Vérifie si le cursus de 5a est classique, si le double diplôme est sélectionné, si le parcours contient l'étape 8, si le double diplome choisi est TSM, ENSFEA ou UT2JENSFEA)
              // OU
              // (Vérifie si le parcours contient l'étape 8 et si le parcours de 4a est en université étrangère, si le double diplome est choisi, si le cursus est classique)
              style={{
                fill: `${
                  (cursus_fifth_Year === 0 &&
                    select_diplome === 1 &&
                    student_short_journey.includes(8) &&
                    (select_double_diplome === 0 ||
                      select_double_diplome === 1 ||
                      select_double_diplome === 2)) ||
                  (student_short_journey.includes(8) &&
                    engineering_course === 1 &&
                    select_diplome === 2 &&
                    cursus_fifth_Year === 0)
                    ? "white"
                    : "#213D33"
                }`,
                fontFamily: "Poppins",
                fontWeight: "Bold",
                fontSize: "20px",
              }}
            >
              STAGE
            </text>
            {/* panneau MSI */}
            <path
              className="panneau-10  parcour-3-options-engineer-2"
              style={{
                // Vérifie si le parcours Université étrangère est choisi en 4a et si le parcours contient l'étape 9
                fill: `${
                  engineering_course === 2 &&
                  student_short_journey.includes(9) &&
                  ((select_da !== "" && isTbs === "") || // Vérifie si le DA est sélectionné et si TBS n'est pas sélectionné
                    (select_da === "" && isTbs === 1)) // Vérifie si le DA n'est pas sélectionné et si TBS est choisi
                    ? "#821F1D"
                    : "#F6C459"
                }`,
              }}
              d="M556,1132.1v-41.5h-83.5v41.5H492l-5.5,25c-8.4,0.4-15.1,7.3-15.1,15.8c0,8.8,7.1,15.9,15.9,15.9
                s15.9-7.1,15.9-15.9c0-6.2-3.6-11.6-8.8-14.2l5.8-26.7L556,1132.1L556,1132.1z"
            />
            <text
              className="panneau-10 parcour-3-options-engineer-2"
              transform="matrix(1 0 0 1 497.6246 1117.6372)"
              style={{
                // Vérifie si le parcours Université étrangère est choisi en 4a et si le parcours contient l'étape 9
                fill: `${
                  engineering_course === 2 &&
                  student_short_journey.includes(9) &&
                  ((select_da !== "" && isTbs === "") || // Vérifie si le DA est sélectionné et si TBS n'est pas sélectionné
                    (select_da === "" && isTbs === 1)) // Vérifie si le DA n'est pas sélectionné et si TBS est choisi
                    ? "white"
                    : "#213D33"
                }`,
                fontFamily: "Poppins",
                fontSize: "20px",
              }}
            >
              MSI
            </text>

            {/* panneau Global semester */}
            <rect
              style={{
                fill: `${
                  // Vérifie si l'élève est alternant et si le parcours contient l'étape 9
                  ((cursus_third_year === 1 || cursus_fourth_year === 1) &&
                    student_short_journey.includes(9)) ||
                  // Vérifie si le parcours ingénieur est global semester et si le DA est sélectionné et si TBS n'est pas sélectionné et si le parcours contient l'étape 9
                  (engineering_course === 3 &&
                    select_da !== "" &&
                    isTbs === "" &&
                    student_short_journey.includes(9)) ||
                  // Vérifie si le parcours ingénieur est global semester et si le DA est pas sélectionné et si TBS est sélectionné et si le parcours contient l'étape 9
                  (engineering_course === 3 &&
                    select_da === "" &&
                    isTbs === 1 &&
                    student_short_journey.includes(9))
                    ? "#821F1D"
                    : "#F6C459"
                }`,
              }}
              x="26.9"
              y="1069.3"
              transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 180.1111 2201.6311)"
              // className={
              //     (
              //         cursus_third_year === 1 ||
              //         cursus_fourth_year === 1
              //     ) ||
              //         (
              //             (select_da !== "" && isTbs === "") ||
              //             (select_da === "" && isTbs === 1)
              //         ) && student_short_journey.includes(9)
              //         ? 'panneau-8 parcour-4'
              //         : ''
              // }
              width="126.3"
              height="63.1"
              id="panneau-8"
            />
            <text transform="matrix(1 0 0 1 57.4415 1095.4387)">
              <tspan
                x="0"
                y="0"
                id="parcour-4-panneau panneau-8"
                style={{
                  fill: `${
                    // Vérifie si l'élève est alternant et si le parcours contient l'étape 9
                    ((cursus_third_year === 1 || cursus_fourth_year === 1) &&
                      student_short_journey.includes(9)) ||
                    // Vérifie si le parcours ingénieur est global semester et si le DA est sélectionné et si TBS n'est pas sélectionné et si le parcours contient l'étape 9
                    (engineering_course === 3 &&
                      select_da !== "" &&
                      isTbs === "" &&
                      student_short_journey.includes(9)) ||
                    // Vérifie si le parcours ingénieur est global semester et si le DA est pas sélectionné et si TBS est sélectionné et si le parcours contient l'étape 9
                    (engineering_course === 3 &&
                      select_da === "" &&
                      isTbs === 1 &&
                      student_short_journey.includes(9))
                      ? "white"
                      : "#213D33"
                  }`,
                  fontSize: 20,
                }}
              >
                Global
              </tspan>
              <tspan
                x="-14.7"
                y="24"
                id="parcour-4-panneau panneau-8"
                style={{
                  fill: `${
                    // Vérifie si l'élève est alternant et si le parcours contient l'étape 9
                    ((cursus_third_year === 1 || cursus_fourth_year === 1) &&
                      student_short_journey.includes(9)) ||
                    // Vérifie si le parcours ingénieur est global semester et si le DA est sélectionné et si TBS n'est pas sélectionné et si le parcours contient l'étape 9
                    (engineering_course === 3 &&
                      select_da !== "" &&
                      isTbs === "" &&
                      student_short_journey.includes(9)) ||
                    // Vérifie si le parcours ingénieur est global semester et si le DA est pas sélectionné et si TBS est sélectionné et si le parcours contient l'étape 9
                    (engineering_course === 3 &&
                      select_da === "" &&
                      isTbs === 1 &&
                      student_short_journey.includes(9))
                      ? "white"
                      : "#213D33"
                  }`,
                  fontSize: 20,
                }}
              >
                Semester
              </tspan>
            </text>

            {/* Panneau 4ème année à gauche */}
            <path
              style={{
                fill:
                  // Vérifie si l'élève est alternant et si le parcours contient l'étape 9
                  (cursus_third_year === 1 || cursus_fourth_year === 1) &&
                  student_short_journey.includes(9)
                    ? "#821F1D"
                    : "#F6C459",
              }}
              d="M63.8,1157.8V1131h-8v26.4c-7.2,1.5-12.6,7.9-12.6,15.5c0,8.8,7.1,15.9,15.9,15.9c8.8,0,15.9-7.1,15.9-15.9C75,1165.9,70.3,1159.8,63.8,1157.8z"
            />

            {/* Panneau 4ème année à droite */}
            <path
              style={{
                fill:
                  // Vérifie si l'élève n'est pas alternant
                  cursus_third_year === 0 &&
                  cursus_fourth_year === 0 &&
                  // Vérifie si le parcours ingénieur correspond à Global Semester et si le DA est sélectionné et si TBS n'est pas choisi
                  ((engineering_course === 3 &&
                    select_da !== "" &&
                    isTbs === "") ||
                    // Vérifie si le parcours ingénieur correspond à Global Semester et si le DA n'est pas sélectionné et si TBS est sélectionné
                    (engineering_course === 3 &&
                      select_da === "" &&
                      isTbs === 1)) &&
                  // Vérifie si le parcours contient l'étape 9
                  student_short_journey.includes(9)
                    ? "#821F1D"
                    : "#F6C459",
              }}
              d="M120.3,1157.8V1131h-8v26.4c-7.2,1.5-12.6,7.9-12.6,15.5c0,8.8,7.1,15.9,15.9,15.9c8.8,0,15.9-7.1,15.9-15.9C131.4,1165.9,126.8,1159.8,120.3,1157.8z"
            />

            {/* panneau Recherche */}
            <path
              className={"panneau-9 parcour-3-options-engineer-0"}
              style={{
                fill: `${
                  // Vérifie si le parcours ingé correspond à recherche et si l'élève suit un cursus classique et si le parcours contient l'étape 9
                  engineering_course === 0 &&
                  cursus_fourth_year === 0 &&
                  student_short_journey.includes(9) &&
                  ((select_da !== "" && isTbs === "") || // Vérifie si le DA est sélectionné et si TBS ne l'est pas
                    (select_da === "" && isTbs === 1)) // Vérifie si le DA n'est pas sélectionné et si TBS l'est
                    ? "#821F1D"
                    : "#F6C459"
                }`,
              }}
              d="M306.1,1132.3v-41.5H174.7v41.5h100.7l5.8,26.5c-5.2,2.6-8.8,8-8.8,14.2c0,8.8,7.1,15.9,15.9,15.9
                s15.9-7.1,15.9-15.9c0-8.5-6.7-15.4-15.1-15.8l-5.4-24.8L306.1,1132.3L306.1,1132.3z"
            />
            <text
              className={"panneau-9 parcour-3-options-engineer-0"}
              transform="matrix(1 0 0 1 187.6256 1117.8718)"
              style={{
                // Vérifie si le parcours ingé correspond à recherche et si l'élève suit un cursus classique et si le parcours contient l'étape 9
                fill: `${
                  engineering_course === 0 &&
                  cursus_fourth_year === 0 &&
                  student_short_journey.includes(9) &&
                  ((select_da !== "" && isTbs === "") || // Vérifie si le DA est sélectionné et si TBS ne l'est pas
                    (select_da === "" && isTbs === 1)) // Vérifie si le DA n'est pas sélectionné et si TBS l'est
                    ? "white"
                    : "#213D33"
                }`,
                fontFamily: "Poppins",
                fontSize: "20px",
              }}
            >
              Recherche
            </text>

            {/* panneau université étrangère */}
            <path
              className="panneau-11 parcour-3-options-engineer-1"
              style={{
                fill: `${
                  // Vérifie si le parcours ingé correspond à recherche et si l'élève suit un cursus classique et si le parcours contient l'étape 9
                  engineering_course === 1 &&
                  student_short_journey.includes(9) &&
                  ((select_da !== "" && isTbs === "") || // Vérifie si le DA est sélectionné et si TBS ne l'est pas
                    (select_da === "" && isTbs === 1)) // Vérifie si le DA n'est pas sélectionné et si TBS l'est
                    ? "#821F1D"
                    : "#F6C459"
                }`,
              }}
              d="M736,1132.3v-63H596.3v63h66.6v25.1c-7.2,1.5-12.6,7.9-12.6,15.5c0,8.8,7.1,15.9,15.9,15.9 s15.9-7.1,15.9-15.9c0-7.1-4.7-13.1-11.1-15.1v-25.5H736z"
            />
            <text
              className="panneau-11 parcour-3-options-engineer-1"
              transform="matrix(1 0 0 1 617.8768 1095.4385)"
              style={{
                fill: `${
                  // Vérifie si le parcours ingé correspond à recherche et si l'élève suit un cursus classique et si le parcours contient l'étape 9
                  engineering_course === 1 &&
                  student_short_journey.includes(9) &&
                  ((select_da !== "" && isTbs === "") || // Vérifie si le DA est sélectionné et si TBS ne l'est pas
                    (select_da === "" && isTbs === 1)) // Vérifie si le DA n'est pas sélectionné et si TBS l'est
                    ? "white"
                    : "#213D33"
                } `,
                fontFamily: "Poppins",
                fontSize: "20px",
              }}
            >
              Université
            </text>
            <text
              className="panneau-11 parcour-3-options-engineer-1"
              transform="matrix(1 0 0 1 616.8768 1119.4385)"
              style={{
                fill: `${
                  // Vérifie si le parcours ingé correspond à recherche et si l'élève suit un cursus classique et si le parcours contient l'étape 9
                  engineering_course === 1 &&
                  student_short_journey.includes(9) &&
                  ((select_da !== "" && isTbs === "") || // Vérifie si le DA est sélectionné et si TBS ne l'est pas
                    (select_da === "" && isTbs === 1)) // Vérifie si le DA n'est pas sélectionné et si TBS l'est
                    ? "white"
                    : "#213D33"
                }`,
                fontFamily: "Poppins",
                fontSize: "20px",
              }}
            >
              étrangère
            </text>

            {/* panneau DA à gauche alternance */}
            <rect
              x="29.9"
              y="1777.5"
              transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 186.1111 3595.2207)"
              width="126.3"
              height="40.2"
              style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                fill: `${
                  // Vérifie si l'étudiant suit un cursus classique en 5a et s'il fait aucun double diplôme
                  cursus_fifth_Year === 0 && select_diplome === 0
                    ? "#821F1D"
                    : "#F6C459"
                }`,
              }}
              className={
                // Vérifie si l'étudiant est en alternance
                (cursus_fourth_year === 1 ||
                  cursus_fifth_Year === 1 ||
                  cursus_third_year === 1) &&
                student_short_journey.includes(10) // Vérifie si l'étape 10 est présente dans le parcours de l'étudiant
                  ? "panneau-18 parcour-5"
                  : "panneau-18"
              }
            />
            <text
              // style={{ fill: '#213D33', fontFamily: 'Poppins', fontSize: '20px' }}
              className="panneau-18 parcour-5-panneau"
              id="parcour-5-panneau"
              style={{
                fill: `${
                  // Vérifie si un cursus est choisi pour la 5a et s'il ne fait aucun double diplome
                  (cursus_fifth_Year !== "" && select_diplome === 0) ||
                  // Vérifie si l'alternance a été choisie en 3a ou 4a
                  ((cursus_third_year === 1 || cursus_fourth_year === 1) &&
                    student_short_journey.includes(10)) // Vérifie si l'étape 10 se trouve dans le parcours
                    ? "white"
                    : "#213D33"
                }`,
                fontFamily: "Poppins",
                fontSize: "20px",
              }}
              transform="matrix(1 0 0 1 79.2516 1804.7288)"
            >
              {textTranslation[language]["map"].da}
            </text>
            {/*  pied Panneau DA à gauche en alternance */}
            <path
              className={
                // Vérifie si l'alternance est choisie en 4a, 5a et 3a
                (cursus_fourth_year === 1 ||
                  cursus_fifth_Year === 1 ||
                  cursus_third_year === 1) &&
                student_short_journey.includes(10) //Vérifie si l'étape 10 se trouve dans le parocurs
                  ? "parcour-5 panneau-18"
                  : ""
              }
              style={{ fill: "#F6C459" }}
              d="M63.8,1843.8v-26.8h-8v26.4c-7.2,1.5-12.6,7.9-12.6,15.5c0,8.8,7.1,15.9,15.9,15.9c8.8,0,15.9-7.1,15.9-15.9
        C75,1851.8,70.3,1845.8,63.8,1843.8z"
            />
            {/*  pied Panneau DA  à droite  diplôme classique*/}
            <path
              style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                fill: `${
                  cursus_fifth_Year === 0 && select_diplome === 0
                    ? "#821F1D"
                    : "#F6C459"
                }`,
              }}
              d="M120.3,1844.5v-27.6h-8v27.2c-7.2,1.5-12.6,7.9-12.6,15.5c0,8.8,7.1,15.9,15.9,15.9c8.8,0,15.9-7.1,15.9-15.9
        C131.4,1852.6,126.8,1846.6,120.3,1844.5z"
            />

            {/* panneau DD ENSFEA*/}
            <path
              className="panneau-14 parcour-5-options-double-diplome-1"
              style={{
                fill: `${
                  cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                  select_diplome === 1 && // Vérifie si l'étudiant fait un double diplôme
                  select_double_diplome === 1 // Vérifie si le double diplôme sélectionné est ENSFEA
                    ? "#821F1D"
                    : "#F6C459"
                }`,
              }}
              d="M331.4,1942.9v-40.5H200.9v40.5h61.2v24.7c-6.8,1.8-11.8,8-11.8,15.3c0,8.8,7.1,15.9,15.9,15.9
                s15.9-7.1,15.9-15.9c0-7.4-5-13.6-11.9-15.3v-24.7H331.4z"
            />
            <g>
              <text
                className="panneau-14 parcour-5-options-double-diplome-1"
                transform="matrix(1 0 0 1 214.3817 1928.8269)"
                style={{
                  fill: `${
                    cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                    select_diplome === 1 && // Vérifie si l'étudiant fait un double diplôme
                    select_double_diplome === 1 // Vérifie si le double diplôme sélectionné est ENSFEA
                      ? "white"
                      : "#213D33"
                  }`,
                  fontFamily: "Poppins",
                  fontSize: "20px",
                }}
              >
                DD ENSFEA
              </text>
            </g>
            {/* panneau DD UT2J ENSFEA*/}
            <path
              className="panneau-15 parcour-5-options-double-diplome-2"
              style={{
                fill: `${
                  cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                  select_diplome === 1 && // Vérifie si l'étudiant fait un double diplôme
                  select_double_diplome === 2 // Vérifie si le double diplôme sélectionné est UTJ2 ENSFEA
                    ? "#821F1D"
                    : "#F6C459"
                }`,
              }}
              d="M550.2,1942.9v-61.5H442.6v61.5H495v24.7c-6.8,1.8-11.8,8-11.8,15.3c0,8.8,7.1,15.9,15.9,15.9
                s15.9-7.1,15.9-15.9c0-7.4-5-13.6-11.9-15.3v-24.7H550.2z"
            />
            <g>
              <text
                className="panneau-15 parcour-5-options-double-diplome-2"
                transform="matrix(1 0 0 1 456.3814 1907.7556)"
                style={{
                  fill: `${
                    cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                    select_diplome === 1 && // Vérifie si l'étudiant fait un double diplôme
                    select_double_diplome === 2 // Vérifie si le double diplôme sélectionné est UTJ2 ENSFEA
                      ? "white"
                      : "#213D33"
                  }`,
                  fontFamily: "Poppins",
                  fontSize: "20px",
                }}
              >
                DD UT2J
              </text>
              <text
                className="panneau-15 parcour-5-options-double-diplome-2"
                transform="matrix(1 0 0 1 461.4814 1931.7556)"
                style={{
                  fill: `${
                    cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                    select_diplome === 1 && // Vérifie si l'étudiant fait un double diplôme
                    select_double_diplome === 2 // Vérifie si le double diplôme sélectionné est UTJ2 ENSFEA
                      ? "white"
                      : "#213D33"
                  }`,
                  fontFamily: "Poppins",
                  fontSize: "20px",
                }}
              >
                ENSFEA
              </text>
            </g>
            {/* panneau 1ère année*/}
            <rect
              x="63.5"
              y="135.2"
              className={
                student_journey.years.includes(1) // Vérifie si le parcours contient la 1a
                  ? "parcour-1-panneau-année panneau-1"
                  : ""
              }
              style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                fill: "#213D33",
              }}
              width="131.6"
              height="40"
            />
            <text
              className={
                student_journey.years.includes(1) // Vérifie si le parcours contient la 1a
                  ? "parcour-1-panneau-année panneau-1"
                  : ""
              }
              transform="matrix(1 0 0 1 89.6776 160.623)"
              style={{
                fill: "#FFFFFF",
                fontFamily: "Poppins",
                fontSize: "18px",
              }}
            >
              {textTranslation[language]["map"].year.year1}
            </text>

            {/* panneau 2ème année*/}
            <rect
              x="556.8"
              y="289.8"
              className={
                student_journey.years.includes(2) // Vérifie si le parcours contient la 2a
                  ? "parcour-2-panneau-année panneau-3"
                  : ""
              }
              style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                fill: "#213D33",
              }}
              width="131.6"
              height="40"
            />
            <text
              className={
                student_journey.years.includes(2) // Vérifie si le parcours contient la 2a
                  ? "parcour-2-panneau-année panneau-3"
                  : ""
              }
              transform="matrix(1 0 0 1 585.1195 315.1499)"
              style={{
                fill: "#FFFFFF",
                fontFamily: "Poppins",
                fontSize: "18px",
              }}
            >
              {textTranslation[language]["map"].year.year2}
            </text>
            {/* panneau 3ème année*/}
            <rect
              x="55.3"
              y="446.3"
              className={
                student_journey.years.includes(3) // Vérifie si le parcours contient la 3a
                  ? "panneau-5 parcour-3-panneau-année"
                  : ""
              }
              style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                fill: "#213D33",
              }}
              width="131.6"
              height="40"
            />
            <text
              className={
                student_journey.years.includes(3) // Vérifie si le parcours contient la 3a
                  ? "panneau-5 parcour-3-panneau-année"
                  : ""
              }
              transform="matrix(1 0 0 1 83.4081 471.6818)"
              style={{
                fill: "#FFFFFF",
                fontFamily: "Poppins",
                fontSize: "18px",
              }}
            >
              {textTranslation[language]["map"].year.year3}
            </text>

            {/* panneau stage de fin d'études sur diplôme classique*/}
            <path
              className="panneau-19"
              style={{
                fill: `${
                  cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                  select_diplome === 0 && // Vérifie s'il fait un diplôme classique
                  student_short_journey.includes(8) // Vérifie si le parcours contient l'étape 8
                    ? "#821F1D"
                    : "#F6C459"
                }`,
              }}
              d="M155.2,2157.5v26.5h-24c-1.5-7.2-7.9-12.6-15.5-12.6c-8.8,0-15.9,7.1-15.9,15.9 c0,8.8,7.1,15.9,15.9,15.9c7.1,0,13.1-4.7,15.1-11.1h24.4v27.5h176.3v-62L155.2,2157.5L155.2,2157.5z"
            />
            <text
              id="parcour-5-panneau-fin"
              transform="matrix(1 0 0 1 169.3473 2205.1487)"
              style={{
                fill: `${
                  student_short_journey.includes(8) && // Vérifie si le parcours contient l'étape 8
                  cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                  select_diplome === 0 // Vérifie s'il fait un diplôme classique
                    ? "white"
                    : "#213D33"
                }`,
                fontFamily: "Poppins",
                fontSize: "20px",
              }}
            >
              de fin d’études
            </text>

            <text
              transform="matrix(1 0 0 1 168.7281 2183.1262)"
              id="parcour-5-panneau-fin"
              style={{
                fill: `${
                  student_short_journey.includes(8) && // Vérifie si le parcours contient l'étape 8
                  cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                  select_diplome === 0 // Vérifie s'il fait un diplôme classique
                    ? "white"
                    : "#213D33"
                }`,
                fontFamily: "Poppins",
                fontWeight: "Bold",
                fontSize: "20px",
              }}
            >
              STAGE
            </text>
            {/* panneau stage anglophone */}
            <path
              className={
                student_short_journey.includes(4) // Vérifie si le parcours contient l'étape 4
                  ? "parcour-2 panneau-4"
                  : ""
              }
              style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                fill: "#F6C459",
              }}
              d="M258.5,424.3v-18.5c6.8-1.8,11.9-8,11.9-15.3
                c0-8.8-7.1-15.9-15.9-15.9c-8.8,0-15.9,7.1-15.9,15.9c0,7.4,5,13.5,11.9,15.3v18.5h-22.1v62h163.4v-62H258.5z"
            />
            <text
              id="parcour-2-panneau"
              className={
                student_short_journey.includes(4) // Vérifie si le parcours contient l'étape 4
                  ? "parcour-2-panneau panneau-4 contenu"
                  : "contenu"
              }
              transform="matrix(1 0 0 1 240.4 473.2826)"
            >
              {textTranslation[language]["map"].anglophone}
            </text>
            <text
              id="parcour-2-panneau"
              className={
                student_short_journey.includes(4) // Vérifie si le parcours contient l'étape 4
                  ? "parcour-2-panneau panneau-4 titre"
                  : "titre"
              }
              transform="matrix(1 0 0 1 239.7813 449.8607)"
            >
              {textTranslation[language]["map"].internship.toUpperCase()}
            </text>

            {/* panneau stage Exploitation agricole  */}
            <path
              className={
                student_short_journey.includes(2) // Vérifie si le parcours contient l'étape 2
                  ? "parcour-1 panneau-2"
                  : ""
              }
              style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                fill: "#F6C459",
              }}
              d="M655.5,201.6v-62H432v62h22.3v18.7c-6.5,2-11.3,8-11.3,15.2
                c0,8.8,7.1,15.9,15.9,15.9s15.9-7.1,15.9-15.9c0-7.6-5.3-13.9-12.4-15.5v-18.4H655.5z"
            />
            <text
              id="parcour-1-panneau"
              transform="matrix(1 0 0 1 443.2437 190.061)"
              className={
                student_short_journey.includes(2) // Vérifie si le parcours contient l'étape 2
                  ? "parcour-1-panneau panneau-2 contenu"
                  : "contenu"
              }
            >
              {textTranslation[language]["map"].internshipFarm}
            </text>
            <text
              id="parcour-1-panneau"
              transform="matrix(1 0 0 1 442.6251 166.1343)"
              className={
                student_short_journey.includes(2) // Vérifie si le parcours contient l'étape 2
                  ? "parcour-1-panneau panneau-2 titre"
                  : "titre"
              }
            >
              {textTranslation[language]["map"].internship.toUpperCase()}
            </text>

            {/* panneau stage entreprise 3ème année */}
            <path
              className={
                student_short_journey.includes(6) // Vérifie si le parcours contient l'étape 6
                  ? "parcour-3-options-0  panneau-6"
                  : ""
              }
              id="parcour-3-options-0"
              style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                fill: "#F6C459",
              }}
              d="M571.3,579.1v-62H434v62h22.1v14.7c-6.8,1.8-11.9,8-11.9,15.3
                c0,8.8,7.1,15.9,15.9,15.9s15.9-7.1,15.9-15.9c0-7.4-5-13.5-11.8-15.3v-14.7H571.3z"
            />
            <text
              className={
                student_short_journey.includes(6) // Vérifie si le parcours contient l'étape 6
                  ? "parcour-3-options-0-panneau panneau-6"
                  : "contenu"
              }
              transform="matrix(1 0 0 1 448.19 564.7029)"
              id="parcour-3-panneau parcour-3-options-0"
              style={{ fontFamily: "Poppins", fontSize: "20px" }}
            >
              {textTranslation[language]["map"].company}
            </text>
            <text
              id="parcour-3-panneau parcour-3-options-0"
              className={
                student_short_journey.includes(6) // Vérifie si le parcours contient l'étape 6
                  ? "parcour-3-options-0-panneau panneau-6"
                  : "titre"
              }
              transform="matrix(1 0 0 1 447.5709 542.6803)"
              style={{ fontWeight: "Bold", fontSize: "20px" }}
            >
              {textTranslation[language]["map"].internship.toUpperCase()}
            </text>

            {/* panneau DA sur DD en france */}
            <path
              className="panneau-21  parcour-5-options-panneau parcour-5-options-panneau-fin fin-parcour"
              style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                fill: `${
                  (cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                    select_diplome === 1 && // Vérifie si le double diplôme est sélectionné
                    student_short_journey.includes(10) && // Vérifie si le parcours contient l'étape 10
                    // Vérifie si le double diplome sélectionné est différent de TBS
                    (select_double_diplome === 0 ||
                      select_double_diplome === 1 ||
                      select_double_diplome === 2)) ||
                  (student_short_journey.includes(10) && // Vérifie si le parcours contient l'étape 10
                    engineering_course === 1 && // Vérifie si le cursus d'ingénieur correspond à Université étrangère
                    select_diplome === 2 && // Vérifie si le double diplôme à l'étranger est sélectionné
                    cursus_fifth_Year === 0) // Vérifie si le cursus de 5a n'est pas en alternance
                    ? "#821F1D"
                    : "#F6C459"
                }`,
              }}
              d="M387.7,2468.4c-7.1,0-13.1,4.7-15.1,11.1h-31.3v-16.2h-110v40.5 h110v-16.2h30.9c1.5,7.2,7.9,12.6,15.5,12.6c8.8,0,15.9-7.1,15.9-15.9S396.4,2468.4,387.7,2468.4z"
            />
            <text
              id="parcour-5-panneau parcour-5-panneau-fin"
              className="fin-parcour-panneau"
              transform="matrix(1 0 0 1 272.4564 2490.196)"
              style={{
                fill: `${
                  (cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                    select_diplome === 1 && // Vérifie si le double diplôme est sélectionné
                    student_short_journey.includes(10) && // Vérifie si le parcours contient l'étape 10
                    // Vérifie si le double diplôme sélectionné est différent de TBS
                    (select_double_diplome === 0 ||
                      select_double_diplome === 1 ||
                      select_double_diplome === 2)) ||
                  (student_short_journey.includes(10) && // Vérifie si le parcours contient l'étape 10
                    engineering_course === 1 && // Vérifie si le cursus ingénieur correspond à Université étrangère
                    select_diplome === 2 && // Vérifie si le double diplôme à l'étranger est sélectionné
                    cursus_fifth_Year === 0) // Vérifie si le cursus de 5a est classique
                    ? "white"
                    : "#213D33"
                }`,
                fontFamily: "Poppins",
                fontSize: "20px",
              }}
            >
              DA
            </text>
            {/* panneau DD TBS  */}
            <path
              className="panneau-16 parcour-5-options-double-diplome-3"
              style={{
                fillRule: "evenodd",
                clipRule: "evenodd",
                fill: `${
                  cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                  select_diplome === 1 && // Vérifie si le double diplôme est choisi
                  select_double_diplome === 3 // Vérifie si le double diplôme choisi est TBS
                    ? "#821F1D"
                    : "#F6C459"
                }`,
              }}
              d="M630.3,2068.3v-40.1H522.2v40.1h50.2v25.9 c-6.8,1.8-11.8,8-11.8,15.3c0,8.8,7.1,15.9,15.9,15.9s15.9-7.1,15.9-15.9c0-7.4-5-13.6-11.9-15.3v-25.9H630.3z"
            />
            <text
              className="panneau-16 parcour-5-options-double-diplome-3"
              transform="matrix(1 0 0 1 542.7719 2054.0725)"
              style={{
                fill: `${
                  cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                  select_diplome === 1 && // Vérifie si le double diplôme est choisi
                  select_double_diplome === 3 // Vérifie si le double diplôme choisi est TBS
                    ? "white"
                    : "#213D33"
                }`,
                fontFamily: "Poppins",
                fontSize: "20px",
              }}
            >
              DD TBS
            </text>
          </svg>
        </div>
        {/* Parcour 1 parcour j'au panneau 1ère année*/}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <rect
              x="119.3"
              y="-0.3"
              style={{ fill: "#EFD992" }}
              className="parcour-1"
              width="20"
              height="155.6"
            />
          </svg>
        </div>
        {/* Parcour du panneau 1ère année jusqu'au panneau candidature */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className={student_short_journey.includes(1) ? "parcour-1" : ""}
              style={{ fill: "#EFD992" }}
              d="M293,245.4H159.3c-22.1,0-40-17.9-40-40v-50.2h20v50.2c0,11,9,20,20,20H293V245.4z"
            />
          </svg>
        </div>
        {/* Parcour 1 B */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <rect
              x="293"
              y="225.4"
              className={
                student_short_journey.includes(2) // Vérifie si le parcours contient l'étape 2
                  ? "parcour-1"
                  : ""
              }
              style={{ fill: "#EFD992" }}
              width="165.8"
              height="20"
            />
          </svg>
        </div>
        {/* Parcour 1 C */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className={
                student_short_journey.includes(2) && // Vérifie si le parcours contient l'étape 2
                student_journey.years.includes(2) // Vérifie si le parcours contient l'année 2
                  ? "parcour-1  parcour-1-fin"
                  : ""
              }
              style={{ fill: "#EFD992" }}
              d="M632.6,310.7h-20v-45.4c0-11-9-20-20-20H458.9v-20h133.7c22.1,0,40,17.9,40,40V310.7z"
            />
          </svg>
        </div>

        {/* Panneau  Candidature stage première année  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <text transform="matrix(1 0 0 1 242.0655 278.7594)">
              <tspan
                x="0"
                y="0"
                style={{
                  fill: "#FFFFFF",
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {textTranslation[language]["map"].application}{" "}
              </tspan>
              <tspan
                x={language === "EN" ? "9" : "28.4"}
                y="18"
                style={{
                  fill: "#FFFFFF",
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {" "}
                {textTranslation[language]["map"].internship}
              </tspan>
            </text>
            <path
              style={{ fill: "#213D33" }}
              d="M302.4,235.4c0-5.2-4.2-9.3-9.3-9.3s-9.3,4.2-9.3,9.3c0,4.5,3.1,8.2,7.3,9.1v15.9h4v-15.9
        C299.2,243.6,302.4,239.9,302.4,235.4z"
            />
          </svg>
        </div>

        {/*Rond parcour 1   */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle
              style={{ fill: "#FFFFFF" }}
              cx="458.9"
              cy="235.4"
              r="24.6"
            />
          </svg>
        </div>

        {/* Parcour 2 A  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className={student_short_journey.includes(3) ? "parcour-2" : ""}
              style={{ fill: "#EFD992" }}
              d="M592.6,400.5h-84.1v-20h84.1c11,0,20-9,20-20v-49.9h20v49.9C632.6,382.6,614.6,400.5,592.6,400.5z"
            />
          </svg>
        </div>
        {/* Parcour 2 B  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <rect
              x="391.2"
              y="380.5"
              className={
                student_short_journey.includes(4) // Vérifie si le parcours contient l'étape 4
                  ? "parcour-2"
                  : ""
              }
              style={{ fill: "#EFD992" }}
              width="117.3"
              height="20"
            />
          </svg>
        </div>
        {/* Parcour 2 C fin parcours deuxième année */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className={
                student_journey.years.includes(3) // Vérifie si le parcours contient l'étape 3
                  ? "parcour-2  parcour-2-fin"
                  : ""
              }
              style={{ fill: "#EFD992" }}
              d="M131.1,446.3h-20v-25.8c0-22.1,18.1-40,40.2-40h102.5v20H151.4c-11.2,0-20.2,9-20.2,20V446.3z"
            />
          </svg>
        </div>

        {/* Rond point stage anglophone  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle
              style={{ fill: "#FFFFFF" }}
              cx="254.5"
              cy="390.5"
              r="24.6"
            />
          </svg>
        </div>
        {/* Panneau Candidature stage 2ème année   */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 1280"
            style={{ enableBackground: "new 0 0 750 1280" }}
            xmlSpace="preserve"
          >
            <text transform="matrix(1 0 0 1 457.6342 437.4902)">
              <tspan
                x="0"
                y="0"
                style={{
                  fill: "#FFFFFF",
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {textTranslation[language]["map"].application}
              </tspan>
              <tspan
                x={language === "EN" ? "5" : "28.4"}
                y="18"
                style={{
                  fill: "#FFFFFF",
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {textTranslation[language]["map"].internship}
              </tspan>
            </text>
            <path
              style={{ fill: "#213D33" }}
              d="M517.9,391.2c0-5.2-4.2-9.3-9.3-9.3s-9.3,4.2-9.3,9.3c0,4.5,3.1,8.2,7.3,9.1v16.4h4v-16.4C514.7,399.4,517.9,395.6,517.9,391.2z"
            />
          </svg>
        </div>
        {/* Rond voyage 2ème année   */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle style={{ fill: "#FFFFFF" }} cx="391.9" cy="390.5" r="9.3" />
          </svg>
        </div>

        {/* Rond Point alternance ou initial (étudiant) */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              style={{ fill: "#EFD992" }}
              d="M121.1,639.4c-17.5,0-31.7-14.2-31.7-31.7s14.2-31.7,31.7-31.7s31.7,14.2,31.7,31.7 S138.6,639.4,121.1,639.4z M121.1,596c-6.4,0-11.7,5.2-11.7,11.7s5.2,11.7,11.7,11.7s11.7-5.2,11.7-11.7 S127.5,596,121.1,596z"
            />
          </svg>
        </div>
        {/* parcours jusqu'au rond Point alternance ou initial (étudiant) */}

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 10,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <rect
              x="111.1"
              y="486.5"
              className={cursus_third_year !== "" ? "parcour-3" : ""}
              style={{ fill: "#EFD992" }}
              width="20"
              height="99.5"
            />
          </svg>
        </div>
        {/*  rond stage en entreprise*/}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle
              style={{ fill: "#FFFFFF" }}
              cx="460.1"
              cy="609.1"
              r="24.6"
            />
          </svg>
        </div>
        {/* Panneau stage  entreprise 3ème  */}
        {/* <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 3 }}>
                    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 750 1870" style={{ enableBackground: 'new 0 0 750 1870' }} xmlSpace="preserve">
                        <path style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: '#F6C459' }} className={student_short_journey.includes(6) ? 'parcour-3-options-0' : ''} d="M571.3,579.4v-62H434v62h22.1v14.7c-6.8,1.8-11.9,8-11.9,15.3
      c0,8.8,7.1,15.9,15.9,15.9c8.8,0,15.9-7.1,15.9-15.9c0-7.4-5-13.5-11.8-15.3v-14.7H571.3z"/>
                        <text className={student_short_journey.includes(6) ? 'parcour-3-options-0-panneau' : ''} transform="matrix(1 0 0 1 448.1903 565.0449)" style={{ fill: '#213D33', fontFamily: 'Poppins', fontSize: '20px' }}>{textTranslation[language]["map"].company}</text>
                        <text className={student_short_journey.includes(6) ? 'parcour-3-options-0-panneau' : ''} transform="matrix(1 0 0 1 447.5712 543.0223)" style={{ fill: '#213D33', fontFamily: 'Poppins', fontWeight: 'bold', fontSize: '20px' }}>{textTranslation[language]["map"].internship.toUpperCase()}</text>
                    </svg>
                </div> */}
        {/* Panneau candidature parcours 4 ème année  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 5,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <text
              transform="matrix(1 0 0 1 560.4034 690.2435)"
              style={{
                fill: "#FFFFFF",
                fontFamily: "Poppins",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              {textTranslation[language]["map"].application}
              {language === "EN" ? <tspan> for</tspan> : ""}{" "}
            </text>
            <text
              transform="matrix(1 0 0 1 574.5255 708.2435)"
              style={{
                fill: "#FFFFFF",
                fontFamily: "Poppins",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              {language === "EN"
                ? textTranslation[language]["map"].year.year4
                : textTranslation[language]["map"].course}{" "}
            </text>
            <text
              transform="matrix(1 0 0 1 577.4718 726.2435)"
              style={{
                fill: "#FFFFFF",
                fontFamily: "Poppins",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              {language === "EN"
                ? textTranslation[language]["map"].course
                : textTranslation[language]["map"].year.year4}
            </text>
            <path
              style={{ fill: "#213D33" }}
              d="M688.3,693.9c-4.5,0-8.2,3.1-9.1,7.3l-16.4,0v4l16.4,0c0.9,4.2,4.7,7.3,9.1,7.3 c5.2,0,9.3-4.2,9.3-9.3C697.7,698.1,693.5,693.9,688.3,693.9z"
            />
          </svg>
        </div>

        {/* Rond point alternance et Etudiant  près de 4ème année*/}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              style={{ fill: "#EFD992" }}
              d="M388.2,828.9c-17.5,0-31.7-14.2-31.7-31.7s14.2-31.7,31.7-31.7s31.7,14.2,31.7,31.7 S405.7,828.9,388.2,828.9z M388.2,785.5c-6.4,0-11.7,5.2-11.7,11.7s5.2,11.7,11.7,11.7s11.7-5.2,11.7-11.7 S394.7,785.5,388.2,785.5z"
            />
          </svg>
        </div>

        {/* Parcour 3ème année en alternance jusqu'en 4ème année premier trait */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: cursus_third_year === 1 ? 4 : 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <g>
              <rect
                x="49.1"
                y="779.1"
                className="parcour-3-options-1"
                style={{ fill: "#EFD992" }}
                width="20"
                height="33"
              />
              <rect
                x="49.1"
                y="726.1"
                className="parcour-3-options-1"
                style={{ fill: "#EFD992" }}
                width="20"
                height="33"
              />
              <rect
                x="49.1"
                y="832.1"
                className="parcour-3-options-1"
                style={{ fill: "#EFD992" }}
                width="20"
                height="33"
              />
              <path
                className="parcour-3-options-1"
                style={{ fill: "#EFD992" }}
                d="M63.3,638c-3.6,3.1-6.6,6.7-9,10.8l17.4,9.9c1.2-2.1,2.7-3.9,4.5-5.4l17.1-14.5l-12.9-15.3L63.3,638z"
              />
              <rect
                x="49.1"
                className="parcour-3-options-1"
                y="673.1"
                style={{ fill: "#EFD992" }}
                width="20"
                height="33"
              />
              <path
                className="parcour-3-options-1"
                style={{ fill: "#EFD992" }}
                d="M111.1,486.5v91.1c-12.6,4.2-21.7,16.1-21.7,30.1c0,10.6,5.2,20.4,14,26.3l11.2-16.6 c-3.2-2.2-5.2-5.8-5.2-9.7c0-6.4,5.2-11.7,11.7-11.7c5.5,0,10-4.5,10-10v-99.5H111.1z"
              />
            </g>
          </svg>
        </div>
        {/* panneau candidature DA en alternant */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
          dangerouslySetInnerHTML={{
            __html: `<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 750 3154" style="enable-background:new 0 0 750 3154;" xml:space="preserve">
               <text transform="matrix(1 0 0 1 93.2477 967.5977)"><tspan x="0" y="0" style="fill:#FFFFFF; font-family:'Poppins';font-weight:Bold;; font-size:15px;">${textTranslation[language]["map"].application}</tspan><tspan x="39.9" y="18" style="fill:#FFFFFF; font-family:'Poppins';font-weight:Bold; font-size:15px;">${textTranslation[language]["map"].da}</tspan></text>
               <circle style="fill:#213D33;" cx="59.2" cy="965.6" r="9.3"/>
               <rect x="59.1" y="963.6" style="fill:#213D33;" width="25.6" height="4"/>
               </svg>`,
          }} //className={cursus_third_year === 1 ? 'parcour-5' : ""}
        />
        {/* parcours du trait 4ème année en candidature DA en alternance*/}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              style={{
                fill:
                  // Vérifie si l'étudiant est alternant en 3a ou 4a
                  (cursus_third_year === 1 || cursus_fourth_year === 1) &&
                  select_da !== "" // Vérifie si un DA est sélectionné
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M69.1,965.6h-20v-29.5h20V965.6z M69.1,916.1h-20v-33h20V916.1z"
            />
          </svg>
        </div>
        {/* parcour du candidature DA en alternance jusqu'au global semester*/}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              style={{
                fill:
                  // Vérifie si l'étudiant est alternant en 3a ou 4a
                  (cursus_third_year === 1 || cursus_fourth_year === 1) &&
                  select_da !== "" // Vérifie si un DA est sélectionné
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M69.1,1066.9h-20v-33h20V1066.9z M69.1,1013.9h-20v-33h20V1013.9z"
            />
          </svg>
        </div>

        {/* Parcour 3 ème année  étudiant jusqu'en candidature stage 3ème année  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: cursus_third_year === 0 ? 4 : 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className={
                student_short_journey.includes(5) // Vérifie si le parcours contient l'étape 5
                  ? "parcour-3-options-0"
                  : ""
              }
              style={{ fill: "#EFD992" }}
              d="M142.7,619.6c-2.8,0-5.5-1.2-7.4-3.2s-2.8-4.8-2.6-7.6c0-0.3,0-0.7,0-1c0-6.4-5.2-11.7-11.7-11.7
                c-5.5,0-10-4.5-10-10v-99.5h20v91.1c10.1,3.4,17.9,11.6,20.6,21.9h142.5v20L142.7,619.6z"
            />
          </svg>
        </div>
        {/* panneau candidature stage en entreprise  3ème année */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 4,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <text transform="matrix(1 0 0 1 243.3917 654.6625)">
              <tspan
                x="0"
                y="0"
                style={{
                  fill: "#FFFFFF",
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {textTranslation[language]["map"].application}{" "}
              </tspan>
              <tspan
                x="28.4"
                y="18"
                style={{
                  fill: "#FFFFFF",
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                  fontSize: "15px",
                }}
              >
                {textTranslation[language]["map"].internship}
              </tspan>
            </text>
            <path
              style={{ fill: "#213D33" }}
              d="M303.6,609.6c0-5.2-4.2-9.3-9.3-9.3s-9.3,4.2-9.3,9.3c0,4.5,3.1,8.2,7.3,9.1v16.4h4v-16.4 C300.5,617.8,303.6,614.1,303.6,609.6z"
            />
          </svg>
        </div>
        {/* parcours candidature stage 3ème année  -> jusqu'en stage en entreprise 3ème année  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <rect
              className={
                student_short_journey.includes(6) // Vérifie si le parcours contient l'étape 6
                  ? "parcour-3-options-0"
                  : ""
              }
              x="294.3"
              y="599.6"
              style={{ fill: "#EFD992" }}
              width="165.8"
              height="20"
            />
          </svg>
        </div>
        {/* parcours stage en entreprise 3ème année jusqu'en candidature parcours 4ème année  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className={
                student_short_journey.includes(7) // Vérifie si le parcours contient l'étape 7
                  ? "parcour-3-options-0 parcour-3-options-0-fin"
                  : ""
              }
              style={{ fill: "#EFD992" }}
              d="M698.5,703.3h-20v-63.7c0-11-9-20-20-20H460.1v-20h198.4c22.1,0,40,17.9,40,40V703.3z"
            />
          </svg>
        </div>
        {/* panneau alternance 3ème année   */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `<svg svg version = "1.1" id = "Calque_1" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px"
    viewBox = "0 0 750 3154" style = "enable-background:new 0 0 750 3154;" xml: space = "preserve" >
        <g>
            <g>
                <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#7F5A41;" d="M60.9,582.8h-6.8c-0.4,0-0.8-0.3-0.8-0.8v-52.4
                           c0-0.4,0.3-0.8,0.8-0.8h6.8c0.4,0,0.8,0.3,0.8,0.8v52.4C61.7,582.5,61.4,582.8,60.9,582.8z"/>

                <polygon style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#F6C459;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="
                           26,502.4 100.2,502.4 100.2,530.4 26,530.4 7.6,516.4 		"/>
                <text transform="matrix(1 0 0 1 26.1823 519.7053)" style="fill:#213D33;  font-family:'Poppins'; font-weight: Bold; font-size:11.4348px;">${textTranslation[language]["map"].alternance}</text>
            </g>
            <g>
                <path style="fill:#7FA195;" d="M53.5,583.8c0-1.5-0.5-3.4-1.1-5.1c-0.6-1.7-1.4-3.4-2.4-5.1c-1-1.6-2.1-3.2-3.4-4.6
                           c-1.3-1.5-2.7-2.8-4.1-4.1c1.6,1,3.3,2.1,4.8,3.4c1.5,1.3,3,2.6,4.3,4.2c1.3,1.5,2.5,3.2,3.5,5c1,1.9,1.8,3.6,2.3,6L53.5,583.8z"
                />
                <path style="fill:#7FA195;" d="M55,582.9c0.3-1.4,0.2-3.4,0-5.2c-0.2-1.8-0.7-3.7-1.3-5.4c-0.6-1.8-1.4-3.5-2.3-5.2
                           c-0.9-1.7-2-3.3-3.2-4.9c1.4,1.4,2.8,2.8,4,4.3c1.2,1.5,2.3,3.2,3.3,5c1,1.8,1.8,3.6,2.4,5.6c0.6,2,1.1,3.9,1,6.4L55,582.9z"/>
                <path style="fill:#7FA195;" d="M58.4,582.7c-0.3-2.2-0.3-4.1-0.2-6.2c0.1-2,0.4-4,0.8-5.9c0.4-1.9,1.1-3.8,1.9-5.6
                           c0.8-1.8,1.8-3.5,2.9-5c-0.8,1.8-1.6,3.5-2,5.4c-0.5,1.8-0.8,3.7-0.8,5.6c-0.1,1.9,0,3.7,0.2,5.6c0.2,1.8,0.6,3.7,1.1,5.3
                           L58.4,582.7z"/>
                <path style="fill:#7FA195;" d="M52.6,584.1c-0.3-2.2-0.3-4.1-0.2-6.2c0.1-2,0.4-4,0.8-5.9c0.4-1.9,1.1-3.8,1.9-5.6
                           c0.8-1.8,1.8-3.5,2.9-5c-0.8,1.8-1.6,3.5-2,5.4c-0.5,1.8-0.8,3.7-0.8,5.6c-0.1,1.9,0,3.7,0.2,5.6c0.2,1.8,0.6,3.7,1.1,5.3
                           L52.6,584.1z"/>
                <path style="fill:#7FA195;" d="M59.9,581.6c0.9-1.7,1.8-2.9,3-4.1c1.1-1.2,2.4-2.2,3.8-3.1c1.4-0.9,2.9-1.5,4.4-1.8
                           c1.5-0.4,3.1-0.4,4.6-0.4c-1.5,0.3-3,0.6-4.3,1.3c-1.3,0.6-2.5,1.5-3.5,2.5c-1,1-1.9,2.1-2.6,3.3c-0.7,1.2-1.3,2.6-1.6,3.7
                           L59.9,581.6z"/>
                <path style="fill:#7FA195;" d="M58.1,584.1c-0.9-1.6-1.5-3.1-1.9-4.7c-0.4-1.6-0.6-3.2-0.6-4.8c0-1.6,0.3-3.2,0.8-4.7
                           c0.5-1.5,1.3-2.9,2.1-4.1c-0.5,1.4-1,2.8-1.1,4.3c-0.2,1.5-0.1,2.9,0.2,4.3c0.3,1.4,0.8,2.7,1.4,4c0.6,1.2,1.5,2.5,2.3,3.3
                           L58.1,584.1z"/>
                <path style="fill:#7FA195;" d="M58.1,582.1c0.6-1.7,1.3-3,2.2-4.5c0.8-1.4,1.8-2.7,2.8-3.9c1-1.2,2.2-2.3,3.5-3.2
                           c1.3-0.9,2.7-1.6,4.1-2.1c-1.3,0.8-2.5,1.8-3.5,2.9c-1,1.1-1.9,2.3-2.6,3.6c-0.7,1.3-1.3,2.6-1.7,4c-0.4,1.4-0.8,2.8-1,4.1
                           L58.1,582.1z"/>
                <path style="fill:#7FA195;" d="M52.5,583.3c-0.4-1.2-1.1-2.6-1.9-3.8c-0.8-1.2-1.6-2.4-2.6-3.5c-1-1.1-2.1-2.1-3.3-3
                           c-1.2-0.9-2.6-1.5-4-2c1.5,0.2,3.1,0.5,4.5,1.2c1.4,0.6,2.9,1.4,4.1,2.4c1.3,0.9,2.5,2,3.6,3.2c1.1,1.2,2.1,2.4,3.1,3.9
                           L52.5,583.3z"/>
                <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#7FA195;" d="M51.2,580.6c0,0,0.8,3.9-1.6,3.9h15.8c0,0-1.7-0.8-0.9-3.9
                           L51.2,580.6z"/>
            </g>
        </g>
               </svg >
        `,
          }}
        />
        {/* panneau étudiant 3ème année */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: ` <svg svg version = "1.1" id = "Calque_1" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px"
    viewBox = "0 0 750 3154" style = "enable-background:new 0 0 750 3154;" xml: space = "preserve" >
        <g>
            <g>
                <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#7F5A41;" d="M185.4,582.8h-6.8c-0.4,0-0.8-0.3-0.8-0.8v-52.4
                           c0-0.4,0.3-0.8,0.8-0.8h6.8c0.4,0,0.8,0.3,0.8,0.8v52.4C186.2,582.5,185.8,582.8,185.4,582.8z"/>

                <polygon style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#F6C459;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="
                           216.3,530.4 142.2,530.4 142.2,502.4 216.3,502.4 234.8,516.4 		"/>
                <text transform="matrix(1 0 0 1 158.5255 519.7053)" style="fill:#213D33;  font-family:'Poppins'; font-weight: Bold; font-size:11.4348px;">${textTranslation[language]["map"].etudiant}</text>
            </g>
            <g>
                <path style="fill:#7FA195;" d="M177.9,583.8c0-1.5-0.5-3.4-1.1-5.1c-0.6-1.7-1.4-3.4-2.4-5.1c-1-1.6-2.1-3.2-3.4-4.6
                           c-1.3-1.5-2.7-2.8-4.1-4.1c1.6,1,3.3,2.1,4.8,3.4c1.5,1.3,3,2.6,4.3,4.2c1.3,1.5,2.5,3.2,3.5,5c1,1.9,1.8,3.6,2.3,6L177.9,583.8z"
                />
                <path style="fill:#7FA195;" d="M179.4,582.9c0.3-1.4,0.2-3.4,0-5.2c-0.2-1.8-0.7-3.7-1.3-5.4c-0.6-1.8-1.4-3.5-2.3-5.2
                           c-0.9-1.7-2-3.3-3.2-4.9c1.4,1.4,2.8,2.8,4,4.3c1.2,1.5,2.3,3.2,3.3,5c1,1.8,1.8,3.6,2.4,5.6c0.6,2,1.1,3.9,1,6.4L179.4,582.9z"/>
                <path style="fill:#7FA195;" d="M182.9,582.7c-0.3-2.2-0.3-4.1-0.2-6.2c0.1-2,0.4-4,0.8-5.9c0.4-1.9,1.1-3.8,1.9-5.6
                           c0.8-1.8,1.8-3.5,2.9-5c-0.8,1.8-1.6,3.5-2,5.4c-0.5,1.8-0.8,3.7-0.8,5.6c-0.1,1.9,0,3.7,0.2,5.6c0.2,1.8,0.6,3.7,1.1,5.3
                           L182.9,582.7z"/>
                <path style="fill:#7FA195;" d="M177.1,584.1c-0.3-2.2-0.3-4.1-0.2-6.2c0.1-2,0.4-4,0.8-5.9c0.4-1.9,1.1-3.8,1.9-5.6
                           c0.8-1.8,1.8-3.5,2.9-5c-0.8,1.8-1.6,3.5-2,5.4c-0.5,1.8-0.8,3.7-0.8,5.6c-0.1,1.9,0,3.7,0.2,5.6c0.2,1.8,0.6,3.7,1.1,5.3
                           L177.1,584.1z"/>
                <path style="fill:#7FA195;" d="M184.4,581.6c0.9-1.7,1.8-2.9,3-4.1c1.1-1.2,2.4-2.2,3.8-3.1c1.4-0.9,2.9-1.5,4.4-1.8
                           c1.5-0.4,3.1-0.4,4.6-0.4c-1.5,0.3-3,0.6-4.3,1.3c-1.3,0.6-2.5,1.5-3.5,2.5c-1,1-1.9,2.1-2.6,3.3c-0.7,1.2-1.3,2.6-1.6,3.7
                           L184.4,581.6z"/>
                <path style="fill:#7FA195;" d="M182.5,584.1c-0.9-1.6-1.5-3.1-1.9-4.7c-0.4-1.6-0.6-3.2-0.6-4.8c0-1.6,0.3-3.2,0.8-4.7
                           c0.5-1.5,1.3-2.9,2.1-4.1c-0.5,1.4-1,2.8-1.1,4.3c-0.2,1.5-0.1,2.9,0.2,4.3c0.3,1.4,0.8,2.7,1.4,4c0.6,1.2,1.5,2.5,2.3,3.3
                           L182.5,584.1z"/>
                <path style="fill:#7FA195;" d="M182.6,582.1c0.6-1.7,1.3-3,2.2-4.5c0.8-1.4,1.8-2.7,2.8-3.9c1-1.2,2.2-2.3,3.5-3.2
                           c1.3-0.9,2.7-1.6,4.1-2.1c-1.3,0.8-2.5,1.8-3.5,2.9c-1,1.1-1.9,2.3-2.6,3.6c-0.7,1.3-1.3,2.6-1.7,4c-0.4,1.4-0.8,2.8-1,4.1
                           L182.6,582.1z"/>
                <path style="fill:#7FA195;" d="M176.9,583.3c-0.4-1.2-1.1-2.6-1.9-3.8c-0.8-1.2-1.6-2.4-2.6-3.5c-1-1.1-2.1-2.1-3.3-3
                           c-1.2-0.9-2.6-1.5-4-2c1.5,0.2,3.1,0.5,4.5,1.2c1.4,0.6,2.9,1.4,4.1,2.4c1.3,0.9,2.5,2,3.6,3.2c1.1,1.2,2.1,2.4,3.1,3.9
                           L176.9,583.3z"/>
                <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#7FA195;" d="M175.7,580.6c0,0,0.8,3.9-1.6,3.9h15.8c0,0-1.7-0.8-0.9-3.9
                           L175.7,580.6z"/>
            </g>
        </g>
               </svg > `,
          }}
        />
        {/* panneau alternance près de candidature stage en 3ème année    */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `
        <svg svg version = "1.1" id = "Calque_1" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px"
    viewBox = "0 0 750 3154" style = "enable-background:new 0 0 750 3154;" xml: space = "preserve" >
        <g>
            <g>
                <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#7F5A41;" d="M334.5,774.3h-6.8c-0.4,0-0.8-0.3-0.8-0.8v-52.4
                            c0-0.4,0.3-0.8,0.8-0.8h6.8c0.4,0,0.8,0.3,0.8,0.8v52.4C335.2,774,334.9,774.3,334.5,774.3z"/>

                <polygon style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#F6C459;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="
                            299.6,693.9 373.7,693.9 373.7,721.9 299.6,721.9 281.1,707.9 		"/>
                <text transform="matrix(1 0 0 1 299.705 711.2053)" style="fill:#213D33;  font-family:'Poppins'; font-weight: Bold; font-weight: Bold;font-size:11.4348px;">${textTranslation[language]["map"].alternance}</text >
            </g >
            <g>
                <path style="fill:#7FA195;" d="M327,775.3c0-1.5-0.5-3.4-1.1-5.1c-0.6-1.7-1.4-3.4-2.4-5.1c-1-1.6-2.1-3.2-3.4-4.6
                            c-1.3-1.5-2.7-2.8-4.1-4.1c1.6,1,3.3,2.1,4.8,3.4c1.5,1.3,3,2.6,4.3,4.2c1.3,1.5,2.5,3.2,3.5,5c1,1.9,1.8,3.6,2.3,6L327,775.3z"/>
                <path style="fill:#7FA195;" d="M328.5,774.4c0.3-1.4,0.2-3.4,0-5.2c-0.2-1.8-0.7-3.7-1.3-5.4c-0.6-1.8-1.4-3.5-2.3-5.2
                            c-0.9-1.7-2-3.3-3.2-4.9c1.4,1.4,2.8,2.8,4,4.3c1.2,1.5,2.3,3.2,3.3,5c1,1.8,1.8,3.6,2.4,5.6c0.6,2,1.1,3.9,1,6.4L328.5,774.4z"/>
                <path style="fill:#7FA195;" d="M331.9,774.2c-0.3-2.2-0.3-4.1-0.2-6.2c0.1-2,0.4-4,0.8-5.9c0.4-1.9,1.1-3.8,1.9-5.6
                            c0.8-1.8,1.8-3.5,2.9-5c-0.8,1.8-1.6,3.5-2,5.4c-0.5,1.8-0.8,3.7-0.8,5.6c-0.1,1.9,0,3.7,0.2,5.6c0.2,1.8,0.6,3.7,1.1,5.3
                            L331.9,774.2z"/>
                <path style="fill:#7FA195;" d="M326.2,775.6c-0.3-2.2-0.3-4.1-0.2-6.2c0.1-2,0.4-4,0.8-5.9c0.4-1.9,1.1-3.8,1.9-5.6
                            c0.8-1.8,1.8-3.5,2.9-5c-0.8,1.8-1.6,3.5-2,5.4c-0.5,1.8-0.8,3.7-0.8,5.6c-0.1,1.9,0,3.7,0.2,5.6c0.2,1.8,0.6,3.7,1.1,5.3
                            L326.2,775.6z"/>
                <path style="fill:#7FA195;" d="M333.4,773.1c0.9-1.7,1.8-2.9,3-4.1c1.1-1.2,2.4-2.2,3.8-3.1c1.4-0.9,2.9-1.5,4.4-1.8
                            c1.5-0.4,3.1-0.4,4.6-0.4c-1.5,0.3-3,0.6-4.3,1.3c-1.3,0.6-2.5,1.5-3.5,2.5c-1,1-1.9,2.1-2.6,3.3c-0.7,1.2-1.3,2.6-1.6,3.7
                            L333.4,773.1z"/>
                <path style="fill:#7FA195;" d="M331.6,775.6c-0.9-1.6-1.5-3.1-1.9-4.7c-0.4-1.6-0.6-3.2-0.6-4.8c0-1.6,0.3-3.2,0.8-4.7
                            c0.5-1.5,1.3-2.9,2.1-4.1c-0.5,1.4-1,2.8-1.1,4.3c-0.2,1.5-0.1,2.9,0.2,4.3c0.3,1.4,0.8,2.7,1.4,4c0.6,1.2,1.5,2.5,2.3,3.3
                            L331.6,775.6z"/>
                <path style="fill:#7FA195;" d="M331.7,773.6c0.6-1.7,1.3-3,2.2-4.5c0.8-1.4,1.8-2.7,2.8-3.9c1-1.2,2.2-2.3,3.5-3.2
                            c1.3-0.9,2.7-1.6,4.1-2.1c-1.3,0.8-2.5,1.8-3.5,2.9c-1,1.1-1.9,2.3-2.6,3.6c-0.7,1.3-1.3,2.6-1.7,4c-0.4,1.4-0.8,2.8-1,4.1
                            L331.7,773.6z"/>
                <path style="fill:#7FA195;" d="M326,774.8c-0.4-1.2-1.1-2.6-1.9-3.8c-0.8-1.2-1.6-2.4-2.6-3.5c-1-1.1-2.1-2.1-3.3-3
                            c-1.2-0.9-2.6-1.5-4-2c1.5,0.2,3.1,0.5,4.5,1.2c1.4,0.6,2.9,1.4,4.1,2.4c1.3,0.9,2.5,2,3.6,3.2c1.1,1.2,2.1,2.4,3.1,3.9L326,774.8
                            z"/>
                <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#7FA195;" d="M324.8,772.1c0,0,0.8,3.9-1.6,3.9h15.8c0,0-1.7-0.8-0.9-3.9
                            L324.8,772.1z"/>
            </g>
        </g >
                </svg >
        `,
          }}
        />
        {/* panneau étudiant près de candidature stage en 3ème année    */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `<svg svg svg version = "1.1" id = "Calque_1" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px"
    viewBox = "0 0 750 3154" style = "enable-background:new 0 0 750 3154;" xml: space = "preserve" >
        <g>
            <g>
                <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#7F5A41;" d="M443.9,774.3h-6.8c-0.4,0-0.8-0.3-0.8-0.8v-52.4
                           c0-0.4,0.3-0.8,0.8-0.8h6.8c0.4,0,0.8,0.3,0.8,0.8v52.4C444.7,774,444.3,774.3,443.9,774.3z"/>

                <polygon style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#F6C459;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="
                           400.7,693.9 400.7,721.9 427.4,721.9 440.8,735.4 454.3,721.9 485.8,721.9 485.8,693.9 		"/>
                <text transform="matrix(1 0 0 1 417.0143 711.2053)" style="fill:#213D33;  font-family:'Poppins'; font-weight: Bold; font-size:11.4348px;">${textTranslation[language]["map"].etudiant}</text>
            </g>
            <g>
                <path style="fill:#7FA195;" d="M436.4,775.3c0-1.5-0.5-3.4-1.1-5.1c-0.6-1.7-1.4-3.4-2.4-5.1c-1-1.6-2.1-3.2-3.4-4.6
                           c-1.3-1.5-2.7-2.8-4.1-4.1c1.6,1,3.3,2.1,4.8,3.4c1.5,1.3,3,2.6,4.3,4.2c1.3,1.5,2.5,3.2,3.5,5c1,1.9,1.8,3.6,2.3,6L436.4,775.3z"
                />
                <path style="fill:#7FA195;" d="M437.9,774.4c0.3-1.4,0.2-3.4,0-5.2c-0.2-1.8-0.7-3.7-1.3-5.4c-0.6-1.8-1.4-3.5-2.3-5.2
                           c-0.9-1.7-2-3.3-3.2-4.9c1.4,1.4,2.8,2.8,4,4.3c1.2,1.5,2.3,3.2,3.3,5c1,1.8,1.8,3.6,2.4,5.6c0.6,2,1.1,3.9,1,6.4L437.9,774.4z"/>
                <path style="fill:#7FA195;" d="M441.3,774.2c-0.3-2.2-0.3-4.1-0.2-6.2c0.1-2,0.4-4,0.8-5.9c0.4-1.9,1.1-3.8,1.9-5.6
                           c0.8-1.8,1.8-3.5,2.9-5c-0.8,1.8-1.6,3.5-2,5.4c-0.5,1.8-0.8,3.7-0.8,5.6c-0.1,1.9,0,3.7,0.2,5.6c0.2,1.8,0.6,3.7,1.1,5.3
                           L441.3,774.2z"/>
                <path style="fill:#7FA195;" d="M435.6,775.6c-0.3-2.2-0.3-4.1-0.2-6.2c0.1-2,0.4-4,0.8-5.9c0.4-1.9,1.1-3.8,1.9-5.6
                           c0.8-1.8,1.8-3.5,2.9-5c-0.8,1.8-1.6,3.5-2,5.4c-0.5,1.8-0.8,3.7-0.8,5.6c-0.1,1.9,0,3.7,0.2,5.6c0.2,1.8,0.6,3.7,1.1,5.3
                           L435.6,775.6z"/>
                <path style="fill:#7FA195;" d="M442.8,773.1c0.9-1.7,1.8-2.9,3-4.1c1.1-1.2,2.4-2.2,3.8-3.1c1.4-0.9,2.9-1.5,4.4-1.8
                           c1.5-0.4,3.1-0.4,4.6-0.4c-1.5,0.3-3,0.6-4.3,1.3c-1.3,0.6-2.5,1.5-3.5,2.5c-1,1-1.9,2.1-2.6,3.3c-0.7,1.2-1.3,2.6-1.6,3.7
                           L442.8,773.1z"/>
                <path style="fill:#7FA195;" d="M441,775.6c-0.9-1.6-1.5-3.1-1.9-4.7c-0.4-1.6-0.6-3.2-0.6-4.8c0-1.6,0.3-3.2,0.8-4.7
                           c0.5-1.5,1.3-2.9,2.1-4.1c-0.5,1.4-1,2.8-1.1,4.3c-0.2,1.5-0.1,2.9,0.2,4.3c0.3,1.4,0.8,2.7,1.4,4c0.6,1.2,1.5,2.5,2.3,3.3
                           L441,775.6z"/>
                <path style="fill:#7FA195;" d="M441.1,773.6c0.6-1.7,1.3-3,2.2-4.5c0.8-1.4,1.8-2.7,2.8-3.9c1-1.2,2.2-2.3,3.5-3.2
                           c1.3-0.9,2.7-1.6,4.1-2.1c-1.3,0.8-2.5,1.8-3.5,2.9c-1,1.1-1.9,2.3-2.6,3.6c-0.7,1.3-1.3,2.6-1.7,4c-0.4,1.4-0.8,2.8-1,4.1
                           L441.1,773.6z"/>
                <path style="fill:#7FA195;" d="M435.4,774.8c-0.4-1.2-1.1-2.6-1.9-3.8c-0.8-1.2-1.6-2.4-2.6-3.5c-1-1.1-2.1-2.1-3.3-3
                           c-1.2-0.9-2.6-1.5-4-2c1.5,0.2,3.1,0.5,4.5,1.2c1.4,0.6,2.9,1.4,4.1,2.4c1.3,0.9,2.5,2,3.6,3.2c1.1,1.2,2.1,2.4,3.1,3.9
                           L435.4,774.8z"/>
                <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#7FA195;" d="M434.2,772.1c0,0,0.8,3.9-1.6,3.9h15.8c0,0-1.7-0.8-0.9-3.9
                           L434.2,772.1z"/>
            </g>
        </g>
               </svg > `,
          }}
        />
        {/* panneau alternance 5ème année  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `
        <svg svg svg version = "1.1" id = "Calque_1" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px"
    viewBox = "0 0 750 3154" style = "enable-background:new 0 0 750 3154;" xml: space = "preserve" >
        <g>
            <g>
                <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#7F5A41;" d="M318.8,1481.7H312c-0.4,0-0.8-0.3-0.8-0.8v-52.4
                           c0-0.4,0.3-0.8,0.8-0.8h6.8c0.4,0,0.8,0.3,0.8,0.8v52.4C319.5,1481.4,319.2,1481.7,318.8,1481.7z"/>

                <polygon style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#F6C459;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="
                           283.9,1401.3 358,1401.3 358,1429.3 283.9,1429.3 265.4,1415.3 		"/>
                <text transform="matrix(1 0 0 1 283.9984 1418.593)" style="fill:#213D33;  font-family:'Poppins'; font-weight: Bold; font-size:11.4348px;">${textTranslation[language]["map"].alternance}</text>
            </g>
            <g>
                <path style="fill:#7FA195;" d="M311.3,1482.7c0-1.5-0.5-3.4-1.1-5.1c-0.6-1.7-1.4-3.4-2.4-5.1c-1-1.6-2.1-3.2-3.4-4.6
                           c-1.3-1.5-2.7-2.8-4.1-4.1c1.6,1,3.3,2.1,4.8,3.4c1.5,1.3,3,2.6,4.3,4.2c1.3,1.5,2.5,3.2,3.5,5c1,1.9,1.8,3.6,2.3,6L311.3,1482.7z
                           "/>
                <path style="fill:#7FA195;" d="M312.8,1481.8c0.3-1.4,0.2-3.4,0-5.2c-0.2-1.8-0.7-3.7-1.3-5.4c-0.6-1.8-1.4-3.5-2.3-5.2
                           c-0.9-1.7-2-3.3-3.2-4.9c1.4,1.4,2.8,2.8,4,4.3c1.2,1.5,2.3,3.2,3.3,5c1,1.8,1.8,3.6,2.4,5.6c0.6,2,1.1,3.9,1,6.4L312.8,1481.8z"
                />
                <path style="fill:#7FA195;" d="M316.2,1481.6c-0.3-2.2-0.3-4.1-0.2-6.2c0.1-2,0.4-4,0.8-5.9c0.4-1.9,1.1-3.8,1.9-5.6
                           c0.8-1.8,1.8-3.5,2.9-5c-0.8,1.8-1.6,3.5-2,5.4c-0.5,1.8-0.8,3.7-0.8,5.6c-0.1,1.9,0,3.7,0.2,5.6c0.2,1.8,0.6,3.7,1.1,5.3
                           L316.2,1481.6z"/>
                <path style="fill:#7FA195;" d="M310.5,1483c-0.3-2.2-0.3-4.1-0.2-6.2c0.1-2,0.4-4,0.8-5.9c0.4-1.9,1.1-3.8,1.9-5.6
                           c0.8-1.8,1.8-3.5,2.9-5c-0.8,1.8-1.6,3.5-2,5.4c-0.5,1.8-0.8,3.7-0.8,5.6c-0.1,1.9,0,3.7,0.2,5.6c0.2,1.8,0.6,3.7,1.1,5.3
                           L310.5,1483z"/>
                <path style="fill:#7FA195;" d="M317.7,1480.4c0.9-1.7,1.8-2.9,3-4.1c1.1-1.2,2.4-2.2,3.8-3.1c1.4-0.9,2.9-1.5,4.4-1.8
                           c1.5-0.4,3.1-0.4,4.6-0.4c-1.5,0.3-3,0.6-4.3,1.3c-1.3,0.6-2.5,1.5-3.5,2.5c-1,1-1.9,2.1-2.6,3.3c-0.7,1.2-1.3,2.6-1.6,3.7
                           L317.7,1480.4z"/>
                <path style="fill:#7FA195;" d="M315.9,1482.9c-0.9-1.6-1.5-3.1-1.9-4.7c-0.4-1.6-0.6-3.2-0.6-4.8c0-1.6,0.3-3.2,0.8-4.7
                           c0.5-1.5,1.3-2.9,2.1-4.1c-0.5,1.4-1,2.8-1.1,4.3c-0.2,1.5-0.1,2.9,0.2,4.3c0.3,1.4,0.8,2.7,1.4,4c0.6,1.2,1.5,2.5,2.3,3.3
                           L315.9,1482.9z"/>
                <path style="fill:#7FA195;" d="M315.9,1481c0.6-1.7,1.3-3,2.2-4.5c0.8-1.4,1.8-2.7,2.8-3.9c1-1.2,2.2-2.3,3.5-3.2
                           c1.3-0.9,2.7-1.6,4.1-2.1c-1.3,0.8-2.5,1.8-3.5,2.9c-1,1.1-1.9,2.3-2.6,3.6c-0.7,1.3-1.3,2.6-1.7,4c-0.4,1.4-0.8,2.8-1,4.1
                           L315.9,1481z"/>
                <path style="fill:#7FA195;" d="M310.3,1482.1c-0.4-1.2-1.1-2.6-1.9-3.8c-0.8-1.2-1.6-2.4-2.6-3.5c-1-1.1-2.1-2.1-3.3-3
                           c-1.2-0.9-2.6-1.5-4-2c1.5,0.2,3.1,0.5,4.5,1.2c1.4,0.6,2.9,1.4,4.1,2.4c1.3,0.9,2.5,2,3.6,3.2c1.1,1.2,2.1,2.4,3.1,3.9
                           L310.3,1482.1z"/>
                <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#7FA195;" d="M309.1,1479.5c0,0,0.8,3.9-1.6,3.9h15.8c0,0-1.7-0.8-0.9-3.9
                           L309.1,1479.5z"/>
            </g>
        </g>
               </svg >
        `,
          }}
        />
        {/* panneau étudiant 5ème année  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `<svg svg svg version = "1.1" id = "Calque_1" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px"
    viewBox = "0 0 750 3154" style = "enable-background:new 0 0 750 3154;" xml: space = "preserve" >
        <g>
            <g>
                <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#7F5A41;" d="M461.9,1481.7h-6.8c-0.4,0-0.8-0.3-0.8-0.8v-52.4
                           c0-0.4,0.3-0.8,0.8-0.8h6.8c0.4,0,0.8,0.3,0.8,0.8v52.4C462.7,1481.4,462.3,1481.7,461.9,1481.7z"/>

                <polygon style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#F6C459;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="
                           418.7,1401.3 418.7,1429.3 445.4,1429.3 458.8,1442.8 472.3,1429.3 503.8,1429.3 503.8,1401.3 		"/>
                <text transform="matrix(1 0 0 1 435.0114 1418.593)" style="fill:#213D33;  font-family:'Poppins'; font-weight: Bold;font-size:11.4348px;">${textTranslation[language]["map"].etudiant}</text>
            </g>
            <g>
                <path style="fill:#7FA195;" d="M454.4,1482.7c0-1.5-0.5-3.4-1.1-5.1c-0.6-1.7-1.4-3.4-2.4-5.1c-1-1.6-2.1-3.2-3.4-4.6
                           c-1.3-1.5-2.7-2.8-4.1-4.1c1.6,1,3.3,2.1,4.8,3.4c1.5,1.3,3,2.6,4.3,4.2c1.3,1.5,2.5,3.2,3.5,5c1,1.9,1.8,3.6,2.3,6L454.4,1482.7z
                           "/>
                <path style="fill:#7FA195;" d="M455.9,1481.8c0.3-1.4,0.2-3.4,0-5.2c-0.2-1.8-0.7-3.7-1.3-5.4c-0.6-1.8-1.4-3.5-2.3-5.2
                           c-0.9-1.7-2-3.3-3.2-4.9c1.4,1.4,2.8,2.8,4,4.3c1.2,1.5,2.3,3.2,3.3,5c1,1.8,1.8,3.6,2.4,5.6c0.6,2,1.1,3.9,1,6.4L455.9,1481.8z"
                />
                <path style="fill:#7FA195;" d="M459.3,1481.6c-0.3-2.2-0.3-4.1-0.2-6.2c0.1-2,0.4-4,0.8-5.9c0.4-1.9,1.1-3.8,1.9-5.6
                           c0.8-1.8,1.8-3.5,2.9-5c-0.8,1.8-1.6,3.5-2,5.4c-0.5,1.8-0.8,3.7-0.8,5.6c-0.1,1.9,0,3.7,0.2,5.6c0.2,1.8,0.6,3.7,1.1,5.3
                           L459.3,1481.6z"/>
                <path style="fill:#7FA195;" d="M453.6,1483c-0.3-2.2-0.3-4.1-0.2-6.2c0.1-2,0.4-4,0.8-5.9c0.4-1.9,1.1-3.8,1.9-5.6
                           c0.8-1.8,1.8-3.5,2.9-5c-0.8,1.8-1.6,3.5-2,5.4c-0.5,1.8-0.8,3.7-0.8,5.6c-0.1,1.9,0,3.7,0.2,5.6c0.2,1.8,0.6,3.7,1.1,5.3
                           L453.6,1483z"/>
                <path style="fill:#7FA195;" d="M460.8,1480.4c0.9-1.7,1.8-2.9,3-4.1c1.1-1.2,2.4-2.2,3.8-3.1c1.4-0.9,2.9-1.5,4.4-1.8
                           c1.5-0.4,3.1-0.4,4.6-0.4c-1.5,0.3-3,0.6-4.3,1.3c-1.3,0.6-2.5,1.5-3.5,2.5c-1,1-1.9,2.1-2.6,3.3c-0.7,1.2-1.3,2.6-1.6,3.7
                           L460.8,1480.4z"/>
                <path style="fill:#7FA195;" d="M459,1482.9c-0.9-1.6-1.5-3.1-1.9-4.7c-0.4-1.6-0.6-3.2-0.6-4.8c0-1.6,0.3-3.2,0.8-4.7
                           c0.5-1.5,1.3-2.9,2.1-4.1c-0.5,1.4-1,2.8-1.1,4.3c-0.2,1.5-0.1,2.9,0.2,4.3c0.3,1.4,0.8,2.7,1.4,4c0.6,1.2,1.5,2.5,2.3,3.3
                           L459,1482.9z"/>
                <path style="fill:#7FA195;" d="M459.1,1481c0.6-1.7,1.3-3,2.2-4.5c0.8-1.4,1.8-2.7,2.8-3.9c1-1.2,2.2-2.3,3.5-3.2
                           c1.3-0.9,2.7-1.6,4.1-2.1c-1.3,0.8-2.5,1.8-3.5,2.9c-1,1.1-1.9,2.3-2.6,3.6c-0.7,1.3-1.3,2.6-1.7,4c-0.4,1.4-0.8,2.8-1,4.1
                           L459.1,1481z"/>
                <path style="fill:#7FA195;" d="M453.4,1482.1c-0.4-1.2-1.1-2.6-1.9-3.8c-0.8-1.2-1.6-2.4-2.6-3.5c-1-1.1-2.1-2.1-3.3-3
                           c-1.2-0.9-2.6-1.5-4-2c1.5,0.2,3.1,0.5,4.5,1.2c1.4,0.6,2.9,1.4,4.1,2.4c1.3,0.9,2.5,2,3.6,3.2c1.1,1.2,2.1,2.4,3.1,3.9
                           L453.4,1482.1z"/>
                <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#7FA195;" d="M452.2,1479.5c0,0,0.8,3.9-1.6,3.9h15.8c0,0-1.7-0.8-0.9-3.9
                           L452.2,1479.5z"/>
            </g>
        </g>
               </svg > `,
          }}
        />

        {/* panneau Double diplôme à l'étranger */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `<svg svg svg version = "1.1" id = "Calque_1" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px"
                viewBox = "0 0 750 3154" style = "enable-background:new 0 0 750 3154;" xml: space = "preserve" >
                    <g>
                        <g>
                            <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#7F5A41;" d="M598.1,1360.1h6.8c0.4,0,0.8-0.3,0.8-0.8v-52.4
                                    c0-0.4-0.3-0.8-0.8-0.8h-6.8c-0.4,0-0.8,0.3-0.8,0.8v52.4C597.3,1359.7,597.6,1360.1,598.1,1360.1z"/>

                            <polygon style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#F6C459;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="
                                    644.9,1265.7 558,1265.7 558,1313.9 587.5,1313.9 587.5,1313.9 600.9,1327.4 614.4,1313.9 614.3,1313.9 644.9,1313.9 		"/>
                            <text transform="matrix(1 0 0 1 579.3112 1279.6667)"><tspan x="0" y="0" style="fill:#213D33;font-family:'Poppins'; font-weight: Bold;font-size:11.4348px;">Double</tspan><tspan x="-3.6" y="13.7" style="fill:#213D33; font-family:'Poppins'; font-weight: Bold; font-size:11.4348px;">diplôme</tspan><tspan x="-13.7" y="27.4" style="fill:#213D33;font-family:'Poppins'; font-weight: Bold;font-size:11.4348px;">à l’étranger</tspan></text>
                        </g>
                        <g>
                            <path style="fill:#7FA195;" d="M605.5,1361.1c0-1.5,0.5-3.4,1.1-5.1c0.6-1.7,1.4-3.4,2.4-5.1c1-1.6,2.1-3.2,3.4-4.6
                                    c1.3-1.5,2.7-2.8,4.1-4.1c-1.6,1-3.3,2.1-4.8,3.4c-1.5,1.3-3,2.6-4.3,4.2c-1.3,1.5-2.5,3.2-3.5,5c-1,1.9-1.8,3.6-2.3,6
                                    L605.5,1361.1z"/>
                            <path style="fill:#7FA195;" d="M604,1360.1c-0.3-1.4-0.2-3.4,0-5.2c0.2-1.8,0.7-3.7,1.3-5.4c0.6-1.8,1.4-3.5,2.3-5.2
                                    c0.9-1.7,2-3.3,3.2-4.9c-1.4,1.4-2.8,2.8-4,4.3c-1.2,1.5-2.3,3.2-3.3,5c-1,1.8-1.8,3.6-2.4,5.6c-0.6,2-1.1,3.9-1,6.4L604,1360.1z"
                            />
                            <path style="fill:#7FA195;" d="M600.6,1360c0.3-2.2,0.3-4.1,0.2-6.2c-0.1-2-0.4-4-0.8-5.9c-0.4-1.9-1.1-3.8-1.9-5.6
                                    c-0.8-1.8-1.8-3.5-2.9-5c0.8,1.8,1.6,3.5,2,5.4c0.5,1.8,0.8,3.7,0.8,5.6c0.1,1.9,0,3.7-0.2,5.6c-0.2,1.8-0.6,3.7-1.1,5.3
                                    L600.6,1360z"/>
                            <path style="fill:#7FA195;" d="M606.4,1361.4c0.3-2.2,0.3-4.1,0.2-6.2c-0.1-2-0.4-4-0.8-5.9c-0.4-1.9-1.1-3.8-1.9-5.6
                                    c-0.8-1.8-1.8-3.5-2.9-5c0.8,1.8,1.6,3.5,2,5.4c0.5,1.8,0.8,3.7,0.8,5.6c0.1,1.9,0,3.7-0.2,5.6c-0.2,1.8-0.6,3.7-1.1,5.3
                                    L606.4,1361.4z"/>
                            <path style="fill:#7FA195;" d="M599.1,1358.8c-0.9-1.7-1.8-2.9-3-4.1c-1.1-1.2-2.4-2.2-3.8-3.1c-1.4-0.9-2.9-1.5-4.4-1.8
                                    c-1.5-0.4-3.1-0.4-4.6-0.4c1.5,0.3,3,0.6,4.3,1.3c1.3,0.6,2.5,1.5,3.5,2.5c1,1,1.9,2.1,2.6,3.3c0.7,1.2,1.3,2.6,1.6,3.7
                                    L599.1,1358.8z"/>
                            <path style="fill:#7FA195;" d="M600.9,1361.3c0.9-1.6,1.5-3.1,1.9-4.7c0.4-1.6,0.6-3.2,0.6-4.8c0-1.6-0.3-3.2-0.8-4.7
                                    c-0.5-1.5-1.3-2.9-2.1-4.1c0.5,1.4,1,2.8,1.1,4.3c0.2,1.5,0.1,2.9-0.2,4.3c-0.3,1.4-0.8,2.7-1.4,4c-0.6,1.2-1.5,2.5-2.3,3.3
                                    L600.9,1361.3z"/>
                            <path style="fill:#7FA195;" d="M600.9,1359.4c-0.6-1.7-1.3-3-2.2-4.5c-0.8-1.4-1.8-2.7-2.8-3.9c-1-1.2-2.2-2.3-3.5-3.2
                                    c-1.3-0.9-2.7-1.6-4.1-2.1c1.3,0.8,2.5,1.8,3.5,2.9c1,1.1,1.9,2.3,2.6,3.6c0.7,1.3,1.3,2.6,1.7,4c0.4,1.4,0.8,2.8,1,4.1
                                    L600.9,1359.4z"/>
                            <path style="fill:#7FA195;" d="M606.5,1360.5c0.4-1.2,1.1-2.6,1.9-3.8c0.8-1.2,1.6-2.4,2.6-3.5c1-1.1,2.1-2.1,3.3-3
                                    c1.2-0.9,2.6-1.5,4-2c-1.5,0.2-3.1,0.5-4.5,1.2c-1.4,0.6-2.9,1.4-4.1,2.4c-1.3,0.9-2.5,2-3.6,3.2c-1.1,1.2-2.1,2.4-3.1,3.9
                                    L606.5,1360.5z"/>
                            <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#7FA195;" d="M607.7,1357.8c0,0-0.8,3.9,1.6,3.9h-15.8c0,0,1.7-0.8,0.9-3.9
                                    L607.7,1357.8z"/>
                        </g>
                    </g>
               </svg > `,
          }}
        />
        {/* panneau Double diplôme en france */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `<svg svg svg version = "1.1" id = "Calque_1" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px"
    viewBox = "0 0 750 3154" style = "enable-background:new 0 0 750 3154;" xml: space = "preserve" >
        <g>
            <g>
                <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#7F5A41;" d="M458.7,1642.5h6.8c0.4,0,0.8-0.3,0.8-0.8v-52.4
                           c0-0.4-0.3-0.8-0.8-0.8h-6.8c-0.4,0-0.8,0.3-0.8,0.8v52.4C458,1642.2,458.3,1642.5,458.7,1642.5z"/>

                <polygon style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#F6C459;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="
                           495.6,1548.1 418.7,1548.1 418.7,1596.3 448.2,1596.3 448.2,1596.4 461.6,1609.8 475.1,1596.4 475,1596.3 495.6,1596.3 
                           515.6,1573.1 		"/>
                <text transform="matrix(1 0 0 1 440.0077 1562.0955)"><tspan x="0" y="0" style="fill:#213D33; font-family:'Poppins'; font-weight: Bold; font-size:11.4348px;">Double</tspan><tspan x="-3.6" y="13.7" style="fill:#213D33; font-family:'Poppins'; font-weight: Bold; font-size:11.4348px;">diplôme</tspan><tspan x="-8.1" y="27.4" style="fill:#213D33;font-family:'Poppins'; font-weight: Bold; font-size:11.4348px;">en France</tspan></text>
            </g>
            <g>
                <path style="fill:#7FA195;" d="M466.2,1643.5c0-1.5,0.5-3.4,1.1-5.1c0.6-1.7,1.4-3.4,2.4-5.1c1-1.6,2.1-3.2,3.4-4.6
                           c1.3-1.5,2.7-2.8,4.1-4.1c-1.6,1-3.3,2.1-4.8,3.4c-1.5,1.3-3,2.6-4.3,4.2c-1.3,1.5-2.5,3.2-3.5,5c-1,1.9-1.8,3.6-2.3,6
                           L466.2,1643.5z"/>
                <path style="fill:#7FA195;" d="M464.7,1642.6c-0.3-1.4-0.2-3.4,0-5.2c0.2-1.8,0.7-3.7,1.3-5.4c0.6-1.8,1.4-3.5,2.3-5.2
                           c0.9-1.7,2-3.3,3.2-4.9c-1.4,1.4-2.8,2.8-4,4.3c-1.2,1.5-2.3,3.2-3.3,5c-1,1.8-1.8,3.6-2.4,5.6c-0.6,2-1.1,3.9-1,6.4L464.7,1642.6
                           z"/>
                <path style="fill:#7FA195;" d="M461.3,1642.4c0.3-2.2,0.3-4.1,0.2-6.2c-0.1-2-0.4-4-0.8-5.9c-0.4-1.9-1.1-3.8-1.9-5.6
                           c-0.8-1.8-1.8-3.5-2.9-5c0.8,1.8,1.6,3.5,2,5.4c0.5,1.8,0.8,3.7,0.8,5.6c0.1,1.9,0,3.7-0.2,5.6c-0.2,1.8-0.6,3.7-1.1,5.3
                           L461.3,1642.4z"/>
                <path style="fill:#7FA195;" d="M467,1643.8c0.3-2.2,0.3-4.1,0.2-6.2c-0.1-2-0.4-4-0.8-5.9c-0.4-1.9-1.1-3.8-1.9-5.6
                           c-0.8-1.8-1.8-3.5-2.9-5c0.8,1.8,1.6,3.5,2,5.4c0.5,1.8,0.8,3.7,0.8,5.6c0.1,1.9,0,3.7-0.2,5.6c-0.2,1.8-0.6,3.7-1.1,5.3
                           L467,1643.8z"/>
                <path style="fill:#7FA195;" d="M459.8,1641.2c-0.9-1.7-1.8-2.9-3-4.1c-1.1-1.2-2.4-2.2-3.8-3.1c-1.4-0.9-2.9-1.5-4.4-1.8
                           c-1.5-0.4-3.1-0.4-4.6-0.4c1.5,0.3,3,0.6,4.3,1.3c1.3,0.6,2.5,1.5,3.5,2.5c1,1,1.9,2.1,2.6,3.3c0.7,1.2,1.3,2.6,1.6,3.7
                           L459.8,1641.2z"/>
                <path style="fill:#7FA195;" d="M461.6,1643.7c0.9-1.6,1.5-3.1,1.9-4.7c0.4-1.6,0.6-3.2,0.6-4.8c0-1.6-0.3-3.2-0.8-4.7
                           c-0.5-1.5-1.3-2.9-2.1-4.1c0.5,1.4,1,2.8,1.1,4.3c0.2,1.5,0.1,2.9-0.2,4.3c-0.3,1.4-0.8,2.7-1.4,4c-0.6,1.2-1.5,2.5-2.3,3.3
                           L461.6,1643.7z"/>
                <path style="fill:#7FA195;" d="M461.6,1641.8c-0.6-1.7-1.3-3-2.2-4.5c-0.8-1.4-1.8-2.7-2.8-3.9c-1-1.2-2.2-2.3-3.5-3.2
                           c-1.3-0.9-2.7-1.6-4.1-2.1c1.3,0.8,2.5,1.8,3.5,2.9c1,1.1,1.9,2.3,2.6,3.6c0.7,1.3,1.3,2.6,1.7,4c0.4,1.4,0.8,2.8,1,4.1
                           L461.6,1641.8z"/>
                <path style="fill:#7FA195;" d="M467.2,1642.9c0.4-1.2,1.1-2.6,1.9-3.8c0.8-1.2,1.6-2.4,2.6-3.5c1-1.1,2.1-2.1,3.3-3
                           c1.2-0.9,2.6-1.5,4-2c-1.5,0.2-3.1,0.5-4.5,1.2c-1.4,0.6-2.9,1.4-4.1,2.4c-1.3,0.9-2.5,2-3.6,3.2c-1.1,1.2-2.1,2.4-3.1,3.9
                           L467.2,1642.9z"/>
                <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#7FA195;" d="M468.4,1640.3c0,0-0.8,3.9,1.6,3.9h-15.8c0,0,1.7-0.8,0.9-3.9
                           L468.4,1640.3z"/>
            </g>
        </g>
               </svg > `,
          }}
        />
        {/* panneau Diplôme classique */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `<svg svg svg version = "1.1" id = "Calque_1" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px"
    viewBox = "0 0 750 3154" style = "enable-background:new 0 0 750 3154;" xml: space = "preserve" >
        <g>
            <g>
                <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#7F5A41;" d="M318.1,1642.5h-6.8c-0.4,0-0.8-0.3-0.8-0.8v-52.4
                           c0-0.4,0.3-0.8,0.8-0.8h6.8c0.4,0,0.8,0.3,0.8,0.8v52.4C318.9,1642.2,318.6,1642.5,318.1,1642.5z"/>

                <polygon style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;stroke:#F6C459;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;" points="
                           278.7,1556 358,1556 358,1590.1 278.7,1590.1 256.3,1573.1 		"/>
                <text transform="matrix(1 0 0 1 291.4989 1570.0276)"><tspan x="0" y="0" style="fill:#213D33; font-family:'Poppins'; font-weight: Bold; font-size:11.4348px;">Diplôme</tspan><tspan x="-3.7" y="13.7" style="fill:#213D33; font-family:'Poppins'; font-weight: Bold; font-size:11.4348px;">classique</tspan></text>
            </g>
            <g>
                <path style="fill:#7FA195;" d="M310.6,1643.5c0-1.5-0.5-3.4-1.1-5.1c-0.6-1.7-1.4-3.4-2.4-5.1c-1-1.6-2.1-3.2-3.4-4.6
                           c-1.3-1.5-2.7-2.8-4.1-4.1c1.6,1,3.3,2.1,4.8,3.4c1.5,1.3,3,2.6,4.3,4.2c1.3,1.5,2.5,3.2,3.5,5c1,1.9,1.8,3.6,2.3,6L310.6,1643.5z
                           "/>
                <path style="fill:#7FA195;" d="M312.2,1642.6c0.3-1.4,0.2-3.4,0-5.2c-0.2-1.8-0.7-3.7-1.3-5.4c-0.6-1.8-1.4-3.5-2.3-5.2
                           c-0.9-1.7-2-3.3-3.2-4.9c1.4,1.4,2.8,2.8,4,4.3c1.2,1.5,2.3,3.2,3.3,5c1,1.8,1.8,3.6,2.4,5.6c0.6,2,1.1,3.9,1,6.4L312.2,1642.6z"
                />
                <path style="fill:#7FA195;" d="M315.6,1642.4c-0.3-2.2-0.3-4.1-0.2-6.2c0.1-2,0.4-4,0.8-5.9c0.4-1.9,1.1-3.8,1.9-5.6
                           c0.8-1.8,1.8-3.5,2.9-5c-0.8,1.8-1.6,3.5-2,5.4c-0.5,1.8-0.8,3.7-0.8,5.6c-0.1,1.9,0,3.7,0.2,5.6c0.2,1.8,0.6,3.7,1.1,5.3
                           L315.6,1642.4z"/>
                <path style="fill:#7FA195;" d="M309.8,1643.8c-0.3-2.2-0.3-4.1-0.2-6.2c0.1-2,0.4-4,0.8-5.9c0.4-1.9,1.1-3.8,1.9-5.6
                           c0.8-1.8,1.8-3.5,2.9-5c-0.8,1.8-1.6,3.5-2,5.4c-0.5,1.8-0.8,3.7-0.8,5.6c-0.1,1.9,0,3.7,0.2,5.6c0.2,1.8,0.6,3.7,1.1,5.3
                           L309.8,1643.8z"/>
                <path style="fill:#7FA195;" d="M317.1,1641.2c0.9-1.7,1.8-2.9,3-4.1c1.1-1.2,2.4-2.2,3.8-3.1c1.4-0.9,2.9-1.5,4.4-1.8
                           c1.5-0.4,3.1-0.4,4.6-0.4c-1.5,0.3-3,0.6-4.3,1.3c-1.3,0.6-2.5,1.5-3.5,2.5c-1,1-1.9,2.1-2.6,3.3c-0.7,1.2-1.3,2.6-1.6,3.7
                           L317.1,1641.2z"/>
                <path style="fill:#7FA195;" d="M315.3,1643.7c-0.9-1.6-1.5-3.1-1.9-4.7c-0.4-1.6-0.6-3.2-0.6-4.8c0-1.6,0.3-3.2,0.8-4.7
                           c0.5-1.5,1.3-2.9,2.1-4.1c-0.5,1.4-1,2.8-1.1,4.3c-0.2,1.5-0.1,2.9,0.2,4.3c0.3,1.4,0.8,2.7,1.4,4c0.6,1.2,1.5,2.5,2.3,3.3
                           L315.3,1643.7z"/>
                <path style="fill:#7FA195;" d="M315.3,1641.8c0.6-1.7,1.3-3,2.2-4.5c0.8-1.4,1.8-2.7,2.8-3.9c1-1.2,2.2-2.3,3.5-3.2
                           c1.3-0.9,2.7-1.6,4.1-2.1c-1.3,0.8-2.5,1.8-3.5,2.9c-1,1.1-1.9,2.3-2.6,3.6c-0.7,1.3-1.3,2.6-1.7,4c-0.4,1.4-0.8,2.8-1,4.1
                           L315.3,1641.8z"/>
                <path style="fill:#7FA195;" d="M309.6,1642.9c-0.4-1.2-1.1-2.6-1.9-3.8c-0.8-1.2-1.6-2.4-2.6-3.5c-1-1.1-2.1-2.1-3.3-3
                           c-1.2-0.9-2.6-1.5-4-2c1.5,0.2,3.1,0.5,4.5,1.2c1.4,0.6,2.9,1.4,4.1,2.4c1.3,0.9,2.5,2,3.6,3.2c1.1,1.2,2.1,2.4,3.1,3.9
                           L309.6,1642.9z"/>
                <path style="fill-rule:evenodd;clip-rule:evenodd;fill:#7FA195;" d="M308.4,1640.3c0,0,0.8,3.9-1.6,3.9h15.8c0,0-1.7-0.8-0.9-3.9
                           L308.4,1640.3z"/>
            </g>
        </g>
               </svg > `,
          }}
        />

        {/* parcours candidature parcours 4ème année jusqu'en direction alternance  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: cursus_fourth_year === 1 ? 4 : 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              style={{
                fill:
                  cursus_fourth_year === 1 // Vérifie si l'étudiant est alternant en 4a
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M69.1,865.1h-20v-11.4h20V865.1z M69.1,833.7h-20v-6.5c0-12,5.3-23.3,14.6-30.9l12.7,15.4
        c-4.6,3.8-7.3,9.5-7.3,15.5V833.7z M88.7,807.2l-0.4-20c0.3,0,0.6,0,0.9,0h32.4v20L88.7,807.2z M333.5,807.2h-33v-20h33V807.2z
         M280.5,807.2h-33v-20h33V807.2z M227.5,807.2h-33v-20h33V807.2z M174.5,807.2h-33v-20h33V807.2z"
            />
            <path
              style={{
                fill:
                  cursus_fourth_year === 1 // Vérifie si l'étudiant est alternant en 4a
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M366.5,807.2h-17v-20h8.6c4.2-12.6,16.1-21.7,30.1-21.7c13.9,0,25.7,9,30,21.5h240.2c11,0,20-9,20-20
        v-63.7h20V767c0,22.1-17.9,40-40,40H409.9c-5.5,0-10-4.4-10-9.9c-0.1-6.4-5.3-11.6-11.7-11.6c-6.4,0-11.7,5.2-11.7,11.7
        C376.5,802.7,372.1,807.2,366.5,807.2z"
            />
          </svg>
        </div>
        {/* parcours candidature parcours 4ème année jusqu'en 4ème année  student */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: cursus_fourth_year === 0 ? 4 : 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              style={{
                fill:
                  cursus_fourth_year === 0 && // Vérifie si l'étudiant est alternant en 4a
                  student_journey.years.includes(4) // Vérifie si le parcous contient l'année 4
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M398.2,865.1h-20v-46.2c0-5.5,4.5-10,10-10c6.4,0,11.7-5.2,11.7-11.7c0-2.7,1-5.3,2.9-7.2
        c1.9-1.9,4.4-3,7.1-3h248.5c11,0,20-9,20-20v-63.7h20V767c0,22.1-17.9,40-40,40H418.4c-3.1,9.5-10.6,17.1-20.1,20.3V865.1z"
            />
          </svg>
        </div>
        {/* Parcours du panneau 4ème  année au candidature DD */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <rect
              className={"efz"}
              x="378.2"
              y="865.1"
              style={{
                fill:
                  cursus_fourth_year === 0 && // Vérifie si l'étudiant suit un cursus classique en 4a
                  select_diplome !== "" && // Vérifie si un diplôme est sélectionné
                  student_journey.years.includes(4) // Vérifie si le parcours contient l'année 4
                    ? "#821F1D"
                    : "#EFD992",
              }}
              width="20"
              height="60.4"
            />
          </svg>
        </div>
        {/* Parcours candidature DD au candidature DA */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <rect
              className={""}
              x="378.2"
              y="925.5"
              style={{
                fill:
                  cursus_fourth_year === 0 && // Vérifie si le cursus de 4a est classique
                  ((select_da !== "" && // Vérifie si un DA est sélectionné
                    isTbs === "") || // Vérifie si TBS n'est pas sélectionné
                    (select_da === "" && // Vérifie si aucun DA est sélectionné
                      isTbs === 1)) // Vérifie si TBS est sélectionné
                    ? "#821F1D"
                    : "#EFD992",
              }}
              width="20"
              height="40.1"
            />
          </svg>
        </div>

        {/* Options candidature DA */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 4,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <text
              transform="matrix(1 0 0 1 253.6417 967.5977)"
              style={{
                fill: "#FFFFFF",
                fontFamily: "Poppins",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              <tspan x="0" y="0">
                Candidature{" "}
              </tspan>
              <tspan x="39.9" y="18">
                DA
              </tspan>
            </text>
            <path
              style={{ fill: "#213D33" }}
              d="M388.3,956.2c-4.5,0-8.2,3.1-9.1,7.3h-16.4v4h16.4c0.9,4.2,4.7,7.3,9.1,7.3c5.2,0,9.3-4.2,9.3-9.3S393.4,956.2,388.3,956.2z"
            />
          </svg>
        </div>
        {/* Options candidature DD */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <text
              transform="matrix(1 0 0 1 421.1541 927.5745)"
              style={{
                fill: "#FFFFFF",
                fontFamily: "Poppins",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              <tspan x="0" y="0">
                Candidature{" "}
              </tspan>
              <tspan x="40" y="18">
                DD
              </tspan>
            </text>
            <path
              style={{ fill: "#213D33" }}
              d="M413.8,923.6h-16.4c-0.9-4.2-4.7-7.3-9.1-7.3c-5.2,0-9.3,4.2-9.3,9.3s4.2,9.3,9.3,9.3c4.5,0,8.2-3.1,9.1-7.3h16.4V923.6z"
            />
          </svg>
        </div>

        {/* Parcours candidature DA au panneau université étrangère */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: engineering_course === 1 ? 3 : 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className="parcour-4-options"
              style={{
                fill:
                  engineering_course === 1 && // Vérifie si le cursus ingénieur se fait en université étrangère
                  student_short_journey.includes(9) && // Vérifie si le parcours contient l'étape 9
                  ((select_da !== "" && // Vérifie si un DA est sélectionné
                    isTbs === "") || // Vérifie si TBS n'est pas sélectionné
                    (select_da === "" && // Vérifie si aucun DA est sélectionné
                      isTbs === 1)) // Vérifie si TBS est sélectionné
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M676.2,1100.8h-20v-31.9c0-11-9-20-20-20H409.9c-5.5,0-10-4.5-10-10c0-6.4-5.2-11.7-11.7-11.7
        c-5.5,0-10-4.5-10-10v-51.6h20v43.2c9.4,3.1,16.9,10.6,20.1,20.1h217.9c22.1,0,40,17.9,40,40V1100.8z"
            />
          </svg>
        </div>
        {/* Parcours candidature DA  au MSI */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: engineering_course === 2 ? 3 : 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className="parcour-4-options"
              style={{
                fill:
                  engineering_course === 2 && // Vérifie si le parcours Mission de solidarité internationale est sélectionné en 4a
                  student_short_journey.includes(9) && // Vérifie si le parcours contient l'étape 9
                  ((select_da !== "" && // Vérifie si un DA est sélectionné
                    isTbs === "") || // Vérifie si TBS n'est pas sélectionné
                    (select_da === "" && // Vérifie si aucun DA est sélectionné
                      isTbs === 1)) // Vérifie si TBS est sélectionné
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M479.3,1179.1c0,0-85.5-109.8-89.2-114.5c-0.6-0.7-1.1-1.5-1.5-2.5c-2-4.9,0.1-10.5,4.8-12.8
        c4-2,6.5-6,6.5-10.5c0-6.4-5.2-11.7-11.7-11.7c-5.5,0-10-4.5-10-10v-51.6h20v43.2c12.6,4.2,21.7,16.1,21.7,30.1c0,8-2.9,15.4-8,21.1
        l83.2,106.8L479.3,1179.1z"
            />
          </svg>
        </div>
        {/* Parcours candidature DA  au panneau recherche */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: engineering_course === 0 && cursus_third_year !== 1 ? 3 : 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className="parcour-4-options"
              style={{
                fill:
                  engineering_course === 0 && // Vérifie si le parcours ingénieur de 4a est en recherche
                  cursus_fourth_year === 0 && // Vérifie si l'étudiant suit un diplôme classique en 4a
                  student_short_journey.includes(9) && // Vérifie si le parcours contient l'étape 9
                  ((select_da !== "" && // Vérifie si un DA est sélectionné
                    isTbs === "") || // Vérifie si TBS n'est pas sélectionné
                    (select_da === "" && // Vérifie si aucun DA est sélectionné
                      isTbs === 1)) // Vérifie si TBS est sélectionné
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M296.3,1179.1l-15.8-12.3l83.6-107.3c-4.8-5.6-7.6-12.9-7.6-20.6c0-14,9.1-25.9,21.7-30.1v-43.2h20
        v51.6c0,5.5-4.5,10-10,10c-6.4,0-11.7,5.2-11.7,11.7c0,4.3,2.4,8.3,6.2,10.3c4.6,2.5,6.5,8,4.4,12.8c-0.4,0.8-0.8,1.6-1.4,2.3
        C382.2,1068.9,296.3,1179.1,296.3,1179.1z"
            />
          </svg>
        </div>

        {/* Parcours candidature DA  au panneau semester global */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className="parcour-4-options"
              style={{
                fill:
                  engineering_course === 3 && // Vérifie le parcours ingénieur de 4a est en Global Semester
                  student_short_journey.includes(9) && // Vérifie si le parcours contient l'étape 9
                  ((select_da !== "" && // Vérifie si un DA est sélectionné
                    isTbs === "") || // Vérifie si TBS n'est pas sélectionné
                    (select_da === "" && // Vérifie si aucun DA est sélectionné
                      isTbs === 1)) // Vérifie si TBS est sélectionné
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M126.6,1100.8h-20v-31.9c0-22.1,17.9-40,40-40h211.6c3.1-9.4,10.6-16.9,20.1-20.1v-43.2h20v51.6 c0,5.5-4.5,10-10,10c-6.4,0-11.7,5.2-11.7,11.7c0,5.5-4.5,10-10,10h-220c-11,0-20,9-20,20V1100.8z"
            />
          </svg>
        </div>

        {/* Rond point  panneau  semester A */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle style={{ fill: "#FFFFFF" }} cx="59.1" cy="1173" r="24.6" />
          </svg>
        </div>

        {/* rond  global semester B */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 4,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle style={{ fill: "#FFFFFF" }} cx="115.6" cy="1173" r="24.6" />
          </svg>
        </div>

        {/* parcour  pointillé panneau  semester  global semester jusqu'au trait  de 5ème année */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              style={{
                fill:
                  // Vérifie si l'étudiant est alternant en 3a ou 4a
                  (cursus_third_year === 1 || cursus_fourth_year === 1) &&
                  select_da !== "" && // Vérifie si un DA est sélectionné
                  student_journey.years.includes(5) // Vérifie si le parcours contient la 5a
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M69.1,1365h-20v-33h20V1365z M69.1,1312h-20v-33h20V1312z M69.1,1259h-20v-33h20V1259z M69.1,1206
        h-20v-33h20V1206z"
            />
          </svg>
        </div>
        {/* parcour  pointillé du trait  de 5ème année jusqu'au panneau DA   */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: cursus_third_year === 1 || cursus_fourth_year === 1 ? 3 : 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className={
                // Vérifie si l'étudiant est alternant en 3a ou 4a
                (cursus_third_year === 1 || cursus_fourth_year === 1) &&
                student_short_journey.includes(10) // Vérifie si le parcours contient l'étape 10
                  ? "parcour-5"
                  : ""
              }
              style={{ fill: "#EFD992" }}
              d="M69.1,1797.6h-20v-3.6h20V1797.6z M69.1,1774h-20v-33h20V1774z M69.1,1721h-20v-33h20V1721z
         M69.1,1668h-20v-33h20V1668z M69.1,1615h-20v-33h20V1615z M69.1,1562h-20v-33h20V1562z M69.1,1509h-20v-33h20V1509z M69.1,1456h-20
        v-33h20V1456z M69.1,1403h-20v-33h20V1403z"
            />
          </svg>
        </div>

        {/*  panneau  DA A*/}
        {/* <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: cursus_fourth_year || cursus_third_year === 1 || cursus_fifth_Year === 1 ? 4 : 3 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 3154"
                        style={{ enableBackground: 'new 0 0 750 3154' }}
                        xmlSpace="preserve"
                    >
                        <path
                            className={cursus_fourth_year === 1 || cursus_fifth_Year === 1 || cursus_third_year === 1 ? 'parcour-5' : ''}
                            style={{ fill: '#F6C459' }}
                            d="M156.2,1817.7v-40.2H29.9v40.2h25.9v25.7c-7.2,1.5-12.6,7.9-12.6,15.5c0,8.8,7.1,15.9,15.9,15.9 c8.8,0,15.9-7.1,15.9-15.9c0-7.1-4.7-13.1-11.1-15.1v-26H156.2z"
                        />
                        <text
                            className='parcour-5-panneau'
                            transform="matrix(1 0 0 1 79.2516 1804.7288)"
                            style={{ fill: '#213D33', fontFamily: 'Poppins', fontSize: '20px' }}
                        >
                            DA
                        </text>
                    </svg>
                </div> */}
        {/* parcours du panneau DA  jusqu'au Diplômation */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex:
              (cursus_fourth_year === 1 ||
                cursus_third_year === 1 ||
                cursus_fifth_Year === 1) &&
              (current_page === 13 ||
                current_page === 14 ||
                current_page === 15 ||
                current_page === 16)
                ? 3
                : 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className="parcour-5-options parcour-5-options"
              style={{
                fill:
                  // Vérifie si l'étudiant est alternant en 3a, 4a, 5a
                  (cursus_fourth_year === 1 ||
                    cursus_third_year === 1 ||
                    cursus_fifth_Year === 1) &&
                  // Vérifie les valeurs de la page
                  (current_page === 13 ||
                    current_page === 14 ||
                    current_page === 15 ||
                    current_page === 16)
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M238,2638.6h-20v-20.8h20V2638.6z M238,2597.8h-20v-2.4c0-7-3.6-13.4-9.5-17l10.5-17
                c11.9,7.3,19,20.1,19,34.1V2597.8z M194.6,2575.4h-33v-20h33V2575.4z M141.6,2575.4h-33v-20h33V2575.4z M88.4,2575.4
                c-15.1-0.3-28.6-8.9-35.3-22.4l18-8.8c3.3,6.8,10.1,11.1,17.6,11.2L88.4,2575.4z M69.1,2529h-20v-33h20V2529z M69.1,2476h-20v-33h20
                V2476z M69.1,2423h-20v-33h20V2423z M69.1,2370h-20v-33h20V2370z M69.1,2317h-20v-33h20V2317z M69.1,2264h-20v-33h20V2264z
                M69.1,2211h-20v-33h20V2211z M69.1,2158h-20v-33h20V2158z M69.1,2105h-20v-33h20V2105z M69.1,2052h-20v-33h20V2052z M69.1,1999h-20
                v-33h20V1999z M69.1,1946h-20v-33h20V1946z M69.1,1893h-20v-33h20V1893z"
            />
          </svg>
        </div>
        {/* rond panneau  DA A*/}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex:
              cursus_fourth_year === 1 ||
              cursus_third_year === 1 ||
              cursus_fifth_Year === 1
                ? 3
                : 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle
              style={{ fill: "#FFFFFF" }}
              cx="59.1"
              cy="1858.9"
              r="24.6"
            />
          </svg>
        </div>

        {/* rond panneau DA B*/}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle
              style={{ fill: "#FFFFFF" }}
              cx="115.6"
              cy="1859.7"
              r="24.6"
            />
          </svg>
        </div>
        {/*  panneau  DA  B*/}
        {/*  <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 3 }}>
                    <svg
                        version="1.1"
                        id="Calque_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 750 3154"
                        style={{ enableBackground: 'new 0 0 750 3154' }}
                        xmlSpace="preserve"
                    >
                        <path
                            style={{ fillRule: 'evenodd', clipRule: 'evenodd', fill: `${cursus_fifth_Year === 0 && select_diplome === 0 ? '#821F1D' : '#F6C459'} ` }}
                            d="M156.2,1817.7v-40.2H29.9v40.2h82.4v26.4 c-7.2,1.5-12.6,7.9-12.6,15.5c0,8.8,7.1,15.9,15.9,15.9 c8.8,0,15.9-7.1,15.9-15.9c0-7.1-4.7-13.1-11.1-15.1v-26.8H156.2z"
                        />
                        <text
                            transform="matrix(1 0 0 1 79.2516 1804.7288)"
                            style={{ fill: `${cursus_fifth_Year === 0 && select_diplome === 0 ? 'white' : '#213D33'} `, fontFamily: 'Poppins', fontSize: '20px' }}
                        >
                            DA
                        </text>
                    </svg>
                </div>
 */}

        {/* parcours  pointillé du panneau 5ème année jusqu'au panneau DA   */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: cursus_fifth_Year === 1 ? 4 : 1,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              style={{
                fill:
                  cursus_fifth_Year === 1 // Vérifie si l'alternance est sélectionné en 5a
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M69.1,1797.6h-20v-3.3h20V1797.6z M69.1,1774.3h-20v-33h20V1774.3z M69.1,1721.3h-20v-33h20V1721.3z
                    M69.1,1668.3h-20v-33h20V1668.3z M69.1,1615.3h-20v-33h20V1615.3z M69.1,1562.3h-20V1538h20V1562.3z"
            />
            <path
              style={{
                fill:
                  cursus_fifth_Year === 1 // Vérifie si l'alternance est sélectionné en 5a
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M69.1,1543.7h-20v-9c0-10.9,4.3-21.1,12.1-28.7l13.9,14.3c-3.9,3.8-6.1,8.9-6.1,14.3V1543.7z
                    M87,1514.8l-2.1-19.9c1.4-0.1,2.8-0.2,4.2-0.2h29.8v20H89.1C88.4,1514.7,87.7,1514.8,87,1514.8z
                    M358.3,1514.7h-7.4v-20h7.4c5.8,0,11.3-2.5,15.1-6.9l15.1,13.2C380.9,1509.7,369.9,1514.7,358.3,1514.7z
                    M330.9,1514.7h-33v-20h33V1514.7z
                    M277.9,1514.7h-33v-20h33V1514.7z
                    M224.9,1514.7h-33v-20h33V1514.7z
                    M171.9,1514.7h-33v-20h33V1514.7z
                    M398.3,1476.8l-20-1c0-0.3,0-0.7,0-1v-31.5h20
                    v31.5C398.3,1475.4,398.3,1476.1,398.3,1476.8z
                    M398.3,1423.3h-20v-33h20V1423.3z"
            />
          </svg>
        </div>
        {/* parcours du panneau 5ème année jusqu'à candidature de fin d'études au dessus de DD TSM   */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className="parcour-5-options"
              style={{
                fill:
                  cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                  select_diplome === 0 // Vérifie si aucun double diplôme est sélectionné
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M366.5,1681.7H250.5v-20h107.7c3.2-9.5,10.7-17,20.2-20.1v-251.4h20v259.8c0,5.5-4.5,10-10,10h-0.1
          c-6.4,0-11.7,5.2-11.7,11.7C376.5,1677.3,372.1,1681.7,366.5,1681.7z"
            />
          </svg>
        </div>
        {/* parcours du panneau 5ème année jusqu'à candidature de fin d'études  au dessus de DD UT2J ENSFEA */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: cursus_fifth_Year === 0 && select_diplome === 1 ? 3 : 1,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className="parcour-5-options"
              style={{
                fill:
                  cursus_fifth_Year === 0 && // Vérifie si le cursus est classique en 5a
                  select_diplome === 1 && // Vérifie si le double diplome est sélectionné
                  select_double_diplome !== 3 // Vérifie si le double diplôme choisi est différent de TBS
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M398.3,1740.8h-20v-39c-12.6-4.2-21.8-16.1-21.8-30.1s9.2-25.9,21.8-30.1v-251.4h20v259.8
          c0,5.5-4.5,10-10,10h-0.1c-6.4,0-11.7,5.2-11.7,11.7s5.2,11.7,11.7,11.7h0.1c5.5,0,10,4.5,10,10V1740.8z"
            />
          </svg>
        </div>
        {/* parcours du rond point A 5ème année jusqu'au panneau  DD TBS */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex:
              cursus_fifth_Year === 0 &&
              select_diplome === 1 &&
              select_double_diplome === 3
                ? 3
                : 1,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className="parcour-5-options"
              style={{
                fill:
                  cursus_fifth_Year === 0 && // Vérifie l'année 5 se fait en cursus classique
                  select_diplome === 1 && // Vérifie si le double diplôme est sélectionné
                  select_double_diplome === 3 // Vérifie si le double diplôme choisi est TBS
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M587,2031.9h-20v-330.1c0-11-9-20-20-20H409.9c-5.5,0-10-4.5-10-10c0-6.4-5.2-11.7-11.6-11.7
        c-5.5,0-9.9-4.5-9.9-10v-259.8h20v251.5c9.4,3.2,16.8,10.6,20,20H547c22.1,0,40,17.9,40,40V2031.9z"
            />
          </svg>
        </div>
        {/*  rond  DD TBS */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle
              style={{ fill: "#FFFFFF" }}
              cx="576.4"
              cy="2109.5"
              r="24.6"
            />
          </svg>
        </div>

        {/* parcours du DD TBS  jusqu'au prémier pont  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `<svg svg svg version = "1.1" id = "Calque_1" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px"
    viewBox = "0 0 750 3154" style = "enable-background:new 0 0 750 3154;" xml: space = "preserve" >
        <rect x="566.4" y="2109.5" class="parcour-5-options-tbs-fin" style="fill:${
          cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
          select_diplome === 1 && // Vérifie si le double diplôme est sélectionné
          select_double_diplome === 3 && // Vérifie si le double diplôme choisi est TBS
          student_short_journey.includes(10) // Vérifie si le parcours contient l'étape 10
            ? "#821F1D"
            : "#EFD992"
        };" width="20" height="196" />
               </svg > `,
          }}
        />
        {/* parcours du DD TBS  du  prémier pont  au deuxième pont */}
        {/*  <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 2 }}
                    dangerouslySetInnerHTML={{
                        __html: `
        <svg svg svg version = "1.1" id = "Calque_1" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px"
    viewBox = "0 0 750 3154" style = "enable-background:new 0 0 750 3154;" xml: space = "preserve" >
        <rect x="566.4" y="2334.4" class="parcour-5-options-tbs-fin" style="fill:${cursus_fifth_Year === 0 && select_diplome === 1 && select_double_diplome === 3 ? '#821F1D' : '#EFD992'};" width="20" height="304.1" />
</svg > `
                    }}
                /> */}
        {/* parcours du DD TBS  du   candidature stage au deuxième pont */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `
                        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 750 3154" style="enable-background:new 0 0 750 3154;" xml:space="preserve">
                   <rect x="566.4" class=" parcour-5-options-tbs-fin"  y="2334.4" style="fill:${
                     cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                     select_diplome === 1 && // Vérifie si le double diplôme est sélectionné
                     select_double_diplome === 3 && // Vérifie si le double diplôme choisi est TBS
                     student_short_journey.includes(10) // Vérifie si le parcours contient l'étape 10
                       ? "#821F1D"
                       : "#EFD992"
                   };" width="20" height="174.9"/>
                   </svg> `,
          }}
        />
        {/* parcours du candidature stage au deuxième pont  jusqu'au panneau 6ème année */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `
                        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 750 3154" style="enable-background:new 0 0 750 3154;" xml:space="preserve">
                   <rect x="566.4" class="parcour-5-options-tbs-fin"  y="2509.4" style="fill:${
                     cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                     select_diplome === 1 && // Vérifie si le double diplôme est sélectionné
                     select_double_diplome === 3 && // Vérifie si le double diplôme choisi est TBS
                     student_journey.years.includes(6) // Vérifie si le parcours contient l'année 6
                       ? "#821F1D"
                       : "#EFD992"
                   };" width="20" height="129.5"/>
                   </svg>`,
          }}
        />

        {/* parcours  panneau  semester  global semester jusqu'au panneau 5ème année */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex:
              cursus_fourth_year === 0 && engineering_course === 3 ? 3 : 1,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className="parcour-4-options-engineer-fin"
              style={{
                fill:
                  cursus_fourth_year === 0 && // Vérifie si le cursus de 4a est classique
                  engineering_course === 3 && // Vérifie si le parcours de 4a est en Global Semester
                  student_journey.years.includes(5) && // Vérifie le parcours contient l'année 5
                  ((select_da !== "" && // Vérifie si un DA est sélectionné
                    isTbs === "") || // Vérifie si TBS n'est pas sélectionné
                    (select_da === "" && // Vérifie si aucun DA n'est sélectionné
                      isTbs === 1)) // Vérifie si TBS est sélectionné
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M398.3,1349.8h-20v-75.9c0-11-9-20-20-20H146.4c-22.1,0-40-17.9-40-40V1173h20v40.9c0,11,9,20,20,20
        h211.9c22.1,0,40,17.9,40,40V1349.8z"
            />
          </svg>
        </div>
        {/* parcours  panneau  MSI jusqu'au panneau 5ème année */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex:
              cursus_fourth_year === 0 && engineering_course === 2 ? 3 : 1,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className="parcour-4-options-engineer-fin"
              style={{
                fill:
                  cursus_fourth_year === 0 && // Vérifie si le cursus de 4a est classique
                  engineering_course === 2 && // Vérifie si le parcours de 4a est Mission de Solidarité internationale
                  student_journey.years.includes(5) && // Vérifie le parcours contient l'année 5
                  ((select_da !== "" && // Vérifie si un DA est sélectionné
                    isTbs === "") || // Vérifie si TBS n'est pas sélectionné
                    (select_da === "" && // Vérifie si aucun DA n'est sélectionné
                      isTbs === 1)) // Vérifie si TBS est sélectionné
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M398.3,1350.2h-20v-76.3c0-22.1,17.9-40,40-40h37.8c11,0,20-9,20-20V1173h20v40.9
        c0,22.1-17.9,40-40,40h-37.8c-11,0-20,9-20,20V1350.2z"
            />
          </svg>
        </div>
        {/*  parcours panneau recherche jusqu'au panneau 5ème année */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 750 3154" style="enable-background:new 0 0 750 3154;" xml:space="preserve">
               <path  class='parcour-4-options-engineer-fin' style=fill:${
                 cursus_fourth_year === 0 && // Vérifie si le cursus de 4a est classique
                 engineering_course === 0 && // Vérifie si le parcours ingénieur de 4a est en recherche
                 student_journey.years.includes(5) && // Vérifie si le parcours contient l'année 5
                 ((select_da !== "" && // Vérifie un DA est sélectionné
                   isTbs === "") || // Vérifie si TBS n'est pas sélectionné
                   (select_da === "" && // Vérifie si auncun DA n'est sélectionné
                     isTbs === 1)) // Vérifie si TBS est sélectionné
                   ? "#821F1D"
                   : "#EFD992"
               }; d="M398.3,1350.2h-20v-76.3c0-11-9-20-20-20h-40.1c-22.1,0-40-17.9-40-40V1173h20v40.9c0,11,9,20,20,20
                   h40.1c22.1,0,40,17.9,40,40V1350.2z"/>
               </svg>`,
          }}
        />

        {/*  parcours panneau  université étrangère jusqu'au panneau 5ème année */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex:
              cursus_fourth_year === 0 &&
              engineering_course === 1 &&
              select_diplome !== "" &&
              select_diplome !== 2
                ? 3
                : 1,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className="parcour-4-options-engineer-fin"
              style={{
                fill:
                  cursus_fourth_year === 0 && // Vérifie si le cursus de 4a est classique
                  engineering_course === 1 && // Vérifie si le parcours ingénieur de 4a est en Université étrangère
                  student_journey.years.includes(5) && // Vérifie si le parcours contient l'année 5
                  select_diplome !== 2 && // Vérifie si le diplôme choisi est différent du double diplôme à l'étranger
                  ((select_da !== "" && // Vérifie si un DA est sélectionné
                    isTbs === "") || // Vérifie si TBS n'est pas sélectionné
                    (select_da === "" && // Vérifie si aucun DA n'est sélectionné
                      isTbs === 1)) // Vérifie si TBS est sélectionné
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M398.3,1350.2h-20v-76.3c0-22.1,17.9-40,40-40h217.8c11,0,20-9,20-20V1173h20v40.9
        c0,22.1-17.9,40-40,40H418.3c-11,0-20,9-20,20V1350.2z"
            />
          </svg>
        </div>
        {/* parcours panneau  université étrangère jusqu'au panneau double diplôme à l'étranger  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <rect
              x="656.2"
              y="1173"
              style={{
                fill:
                  engineering_course === 1 && // Vérifie si le parcours ingénieur de 4a correspond à Université étrangère
                  select_diplome === 2 && // Vérifie si le diplôme choisi est double diplôme à l'étranger
                  student_journey.years.includes(5) // Vérifie si le parcours contient la 5a
                    ? "#821F1D"
                    : "#EFD992",
              }}
              width="20"
              height="197.1"
            />
          </svg>
        </div>

        {/* trait parcours 5ème année direction université étrangère au candidature stage de fin d'études  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <rect
              x="656.2"
              y="1370"
              style={{
                fill:
                  engineering_course === 1 && // Vérifie si le parcours ingénieur de 4a correspond à Université étrangère
                  select_diplome === 2 && // Vérifie si le diplôme choisi est double diplôme à l'étranger
                  cursus_fifth_Year === 0 // Vérifie si le cursus de 5a est classique
                    ? "#821F1D"
                    : "#EFD992",
              }}
              width="20"
              height="124.7"
            />
          </svg>
        </div>
        {/* parcours du candidature stage de fin d'études jusqu'au panneau DD à l'étranger */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <rect
              x="656.2"
              y="1494.7"
              style={{
                fill:
                  engineering_course === 1 && // Vérifie si le parcours ingénieur de 4a correspond à Université étrangère
                  select_diplome === 2 && // Vérifie si le diplôme choisi est double diplôme à l'étranger
                  cursus_fifth_Year === 0 // Vérifie si le cursus de 5a est classique
                    ? "#821F1D"
                    : "#EFD992",
              }}
              width="20"
              height="680.4"
            />
          </svg>
        </div>
        {/*  rond  DD  à l’étranger */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 4,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle
              style={{ fill: "#FFFFFF" }}
              cx="666.4"
              cy="2240.4"
              r="24.6"
            />
          </svg>
        </div>

        {/* parcours du DD à l'étranger  jusqu'au stage de fin d'études avant DA  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
          dangerouslySetInnerHTML={{
            __html: `
                    <svg svg svg version = "1.1" id = "Calque_1" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px"
                viewBox = "0 0 750 3154" style = "enable-background:new 0 0 750 3154;" xml: space = "preserve" >
                    <path style="fill:${
                      student_short_journey.includes(8) &&
                      engineering_course === 1 && // Vérifie si le parcours ingénieur de 4a correspond à Université étrangère
                      select_diplome === 2 && // Vérifie si le diplôme choisi est double diplôme à l'étranger
                      cursus_fifth_Year === 0 // Vérifie si le cursus de 5a est classique
                        ? "#821F1D"
                        : "#EFD992"
                    };" d="M397,2375.6h-20v-25.7c0-22.1,17.9-40,40-40h219.1c11,0,20-9,20-20v-48.9h20v48.9
                c0,22.1-17.9,40-40,40H417c-11,0-20,9-20,20V2375.6z"/>
            </svg > `,
          }}
        />
        {/* parcours du candidature stage de fin d'études au dessus de DD TSM  jusqu'au panneau DA */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className="parcour-5-options"
              style={{
                fill:
                  cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                  select_diplome === 0 // Vérifie si le diplôme diplôme choisi est classique
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M126.6,1797.6h-20v-95.9c0-22.1,17.9-40,40-40h103.9v20H146.6c-11,0-20,9-20,20V1797.6z"
            />
          </svg>
        </div>
        {/* parcours du candidature stage de fin d'études au dessus de DD UT2J ENSFEA  jusqu'au panneau DD TSM */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex:
              cursus_fifth_Year === 0 &&
              select_diplome === 1 &&
              select_double_diplome === 0
                ? 2
                : 1,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className="parcour-5-options"
              style={{
                fill:
                  cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                  select_diplome === 1 && // Vérifie si le double diplôme est choisi
                  select_double_diplome === 0 // Vérifie si double diplôme choisi est TSM
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M366.5,1870H265.4v-20h92.8c3.2-9.5,10.7-17,20.2-20.1v-89.1h20v97.5c0,5.5-4.5,10-10,10h-0.1c-6.4,0-11.7,5.2-11.7,11.7C376.5,1865.5,372.1,1870,366.5,1870z"
            />
          </svg>
        </div>

        {/* rond panneau  DD TSM */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 4,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle
              style={{ fill: "#FFFFFF" }}
              cx="265.4"
              cy="1858.9"
              r="24.6"
            />
          </svg>
        </div>

        {/*  rond  DD UT2J ENSFEA */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex:
              cursus_fifth_Year === 0 &&
              select_diplome === 1 &&
              select_double_diplome === 2
                ? 4
                : 3,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle style={{ fill: "#FFFFFF" }} cx="499" cy="1983" r="24.6" />
          </svg>
        </div>

        {/* panneau  DD UT2J ENSFEA */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex:
              cursus_fifth_Year === 0 &&
              select_diplome === 1 &&
              select_double_diplome === 2
                ? 4
                : 3,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              style={{
                fill:
                  cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                  select_diplome === 1 && // Vérifie si le double diplôme est choisi
                  select_double_diplome === 2 // Vérifie si double diplôme choisi est UT2J ENSFEA
                    ? "#821F1D"
                    : "#F6C459",
              }}
              d="M550.2,1942.9v-61.5H442.6v61.5H495v24.7c-6.8,1.8-11.8,8-11.8,15.3c0,8.8,7.1,15.9,15.9,15.9
        s15.9-7.1,15.9-15.9c0-7.4-5-13.6-11.9-15.3v-24.7H550.2z"
            />
            <g>
              <text
                transform="matrix(1 0 0 1 456.3814 1907.7556)"
                style={{
                  fill:
                    cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                    select_diplome === 1 && // Vérifie si le double diplôme est choisi
                    select_double_diplome === 2 // Vérifie si double diplôme choisi est UT2J ENSFEA
                      ? "white"
                      : "#213D33",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                }}
              >
                <tspan x="0" y="0">
                  DD UT2J
                </tspan>
                <tspan x="5.1" y="24">
                  ENSFEA
                </tspan>
              </text>
            </g>
          </svg>
        </div>
        {/*  rond  DD ENSFEA */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex:
              cursus_fifth_Year === 0 &&
              select_diplome === 1 &&
              select_double_diplome === 1
                ? 4
                : 3,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle style={{ fill: "#FFFFFF" }} cx="266.1" cy="1983" r="24.6" />
          </svg>
        </div>

        {/* parcours du second rond point en 5ème année  jusqu'au panneau DD ENSFEA  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex:
              cursus_fifth_Year === 0 &&
              select_diplome === 1 &&
              select_double_diplome === 1
                ? 2
                : 1,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              style={{
                fill:
                  cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                  select_diplome === 1 && // Vérifie si le double diplôme est choisi
                  select_double_diplome === 1 // Vérifie si double diplôme choisi est ENSFEA
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M273.2,1992.7l-14.1-14.2l102.2-101.8c-3.1-4.9-4.8-10.7-4.8-16.7c0-14,9.2-25.9,21.8-30.1v-89.1h20
            v97.5c0,5.5-4.5,10-10,10h-0.1c-6.4,0-11.7,5.2-11.7,11.7c0,3.7,1.7,7,4.6,9.3c2.3,1.8,3.7,4.4,3.9,7.3c0.2,2.9-0.9,5.7-2.9,7.7
            L273.2,1992.7z"
            />
          </svg>
        </div>
        {/* parcours du second rond point en 5ème année  jusqu'au panneau DD  UT2J ENSFEA  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex:
              cursus_fifth_Year === 0 &&
              select_diplome === 1 &&
              select_double_diplome === 2
                ? 2
                : 1,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              style={{
                fill:
                  cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                  select_diplome === 1 && // Vérifie si le double diplôme est choisi
                  select_double_diplome === 2 // Vérifie si double diplôme choisi est UT2J ENSFEA
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M491.7,1989.8l-97.4-106c-1.9-2-2.8-4.8-2.6-7.6s1.6-5.3,3.8-7.1c2.8-2.2,4.4-5.6,4.4-9.2
                c0-6.4-5.2-11.7-11.6-11.7c-5.5,0-9.9-4.5-9.9-10v-97.5h20v89.1c12.5,4.2,21.6,16.1,21.6,30c0,6.1-1.7,11.9-4.8,16.8l91.3,99.4
                L491.7,1989.8z"
            />
          </svg>
        </div>
        {/* parcours DD TSM  jusqu'au  panneau stage de fin d'études avant DA  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex:
              cursus_fifth_Year === 0 &&
              select_diplome === 1 &&
              select_double_diplome === 0 &&
              student_short_journey.includes(8)
                ? 3
                : 1,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className="parcour-5-options parcour-5-options-fin"
              style={{
                fill:
                  cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                  select_diplome === 1 && // Vérifie si le double diplôme est choisi
                  select_double_diplome === 0 && // Vérifie si double diplôme choisi est TSM
                  student_short_journey.includes(8) // Vérifie si le parcours contient l'étape 8
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M397,2360.2h-20v-197c0-11-9-20-20-20H204.1c-22.1,0-40-17.9-40-40v-213.4c0-10.7,4.2-20.8,11.8-28.3
        c7.6-7.5,17.6-11.7,28.2-11.7c0,0,0.1,0,0.1,0l61.2,0.2l-0.1,20l-61.2-0.2c0,0,0,0-0.1,0c-5.3,0-10.3,2.1-14.1,5.8
        c-3.8,3.8-5.9,8.8-5.9,14.2v213.4c0,11,9,20,20,20H357c22.1,0,40,17.9,40,40V2360.2z"
            />
          </svg>
        </div>
        {/* parcours DD ENSFEA  jusqu'au  panneau stage de fin d'études avant DA  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex:
              cursus_fifth_Year === 0 &&
              select_diplome === 1 &&
              select_double_diplome === 1 &&
              student_short_journey.includes(8)
                ? 3
                : 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className="parcour-5-options parcour-5-options-fin"
              style={{
                fill:
                  cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                  select_diplome === 1 && // Vérifie si le double diplôme est choisi
                  select_double_diplome === 1 && // Vérifie si double diplôme choisi est ENSFEA
                  student_short_journey.includes(8) // Vérifie si le parcours contient l'étape 8
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M397,2384h-20v-220.8c0-11-9-20-20-20h-60.9c-22.1,0-40-17.9-40-40v-117.6h20v117.6c0,11,9,20,20,20
        H357c22.1,0,40,17.9,40,40V2384z"
            />
          </svg>
        </div>
        {/* parcours du DD UT2J ENSFEA jusqu'au stage de fin d'études avant DA */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex:
              cursus_fifth_Year === 0 &&
              select_diplome === 1 &&
              select_double_diplome === 2 &&
              student_short_journey.includes(8)
                ? 3
                : 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `
                            <svg svg svg
                        version = "1.1"
                        id = "Calque_1"
                        xmlns = "http://www.w3.org/2000/svg"
                        xmlns: xlink = "http://www.w3.org/1999/xlink"
                        x = "0px" y = "0px"
                        viewBox = "0 0 750 3154"
                        style = "enable-background:new 0 0 750 3154;"
                        xml: space = "preserve" >
                            <path  className='parcour-5-options parcour-5-options-fin' style=fill:${
                              cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                              select_diplome === 1 && // Vérifie si le double diplôme est choisi
                              select_double_diplome === 2 && // Vérifie si double diplôme choisi est UT2J ENSFEA
                              student_short_journey.includes(8) // Vérifie si le parcours contient l'étape 8
                                ? "#821F1D"
                                : "#EFD992"
                            }; d="M397,2384h-20v-220.8c0-22.1,17.9-40,40-40h52c11,0,20-9,20-20V1983h20v120.2c0,22.1-17.9,40-40,40
                                                    h-52c-11,0-20,9-20,20V2384z"/>
                                                    </svg > `,
          }}
        />
        {/*  rond  stage de fin d'études  avant DA */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle
              style={{ fill: "#FFFFFF" }}
              cx="387.7"
              cy="2384.1"
              r="24.6"
            />
          </svg>
        </div>

        {/*  rond  panneau DA  sur parcours Double dipôme en france*/}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle
              style={{ fill: "#FFFFFF" }}
              cx="387.7"
              cy="2484.2"
              r="24.6"
            />
          </svg>
        </div>

        {/* petit parcours stage de fin d'études avant DA jusqu'au DA  */}

        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <rect
              x="377.7"
              className="parcour-5-options parcour-5-options-fin fin-parcour"
              y="2384.1"
              style={{
                fill:
                  (cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                    select_diplome === 1 && // Vérifie si le double diplôme est choisi
                    student_short_journey.includes(10) && // Vérifie si le parcours contient l'étape 10
                    // Vérifie si le double diplôme choisi est différent de TBS
                    (select_double_diplome === 0 ||
                      select_double_diplome === 1 ||
                      select_double_diplome === 2)) ||
                  (student_short_journey.includes(8) && // Vérifie si le parcours contient l'étape 8
                    engineering_course === 1 && // Vérifie si le parcours ingénieur de 4a est en Université étrangère
                    select_diplome === 2 && // Vérifie si le diplôme choisis est un double diplôme à l'étranger
                    cursus_fifth_Year === 0) // Vérifie si le cursus de 5a est classique
                    ? "#821F1D"
                    : "#EFD992",
              }}
              width="20"
              height="100.1"
            />
          </svg>
        </div>
        {/* parcours du panneau DA  jusqu'au  panneau stage de fin d'études */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <rect
              className="parcour-5-options"
              x="105.6"
              y="1860"
              style={{
                fill:
                  cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                  select_diplome === 0 && // Vérifie si le diplôme choisis est classique
                  student_short_journey.includes(8) // Vérifie si le parcours contient l'étape 8
                    ? "#821F1D"
                    : "#EFD992",
              }}
              width="20"
              height="327.3"
            />
          </svg>
        </div>

        {/* parcours du panneau stage de fin d'études après DA jusqu'au  Diplômation */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex:
              cursus_fifth_Year === 0 &&
              select_diplome === 0 &&
              (current_page === 13 ||
                current_page === 14 ||
                current_page === 15 ||
                current_page === 16)
                ? 3
                : 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              className="parcour-5-options"
              style={{
                fill:
                  cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                  select_diplome === 0 && // Vérifie si le diplôme choisi est classique
                  // Vérifie la page actuelle
                  (current_page === 13 ||
                    current_page === 14 ||
                    current_page === 15 ||
                    current_page === 16)
                    ? "#821F1D"
                    : "#EFD992",
              }}
              d="M238,2638.6h-20v-43.2c0-11-9-20-20-20h-52.5c-22.1,0-40-17.9-40-40V2188h20v347.3c0,11,9,20,20,20
	H198c22.1,0,40,17.9,40,40V2638.6z"
            />
          </svg>
        </div>
        {/*  rond  stage de fin d'études sur parcours DA */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle
              style={{ fill: "#FFFFFF" }}
              cx="115.7"
              cy="2187.3"
              r="24.6"
            />
          </svg>
        </div>
        {/* rond panneau MSI */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: engineering_course === 2 ? 3 : 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle style={{ fill: "#FFFFFF" }} cx="487.2" cy="1173" r="24.6" />
            <circle style={{ fill: "#FFFFFF" }} cx="487.2" cy="1173" r="24.6" />
          </svg>
        </div>
        {/* rond  panneau recherche */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: engineering_course === 0 ? 3 : 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle style={{ fill: "#FFFFFF" }} cx="288.2" cy="1173" r="24.6" />
            <circle style={{ fill: "#FFFFFF" }} cx="288.2" cy="1173" r="24.6" />
          </svg>
        </div>

        {/* rond panneau université étrangère */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <circle style={{ fill: "#FFFFFF" }} cx="666.2" cy="1173" r="24.6" />
          </svg>
        </div>

        {/*  panneau candidature stage de fin d'études  diplôme classique*/}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 3,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <text
              transform="matrix(1 0 0 1 200.0382 1716.2747)"
              style={{
                fill: "#FFFFFF",
                fontFamily: "Poppins",
                fontWeight: "Bold",
                fontSize: "15px",
              }}
            >
              <tspan x="0" y="0">
                Candidature
              </tspan>
              <tspan x="28.8" y="18">
                stage
              </tspan>
              <tspan x="-6.1" y="36">
                de fin d’études
              </tspan>
            </text>
            <path
              style={{ fill: "#213D33" }}
              d="M260.3,1671.2c0-5.2-4.2-9.3-9.3-9.3c-5.2,0-9.3,4.2-9.3,9.3c0,4.5,3.1,8.2,7.3,9.1v16.4h4v-16.4C257.1,1679.4,260.3,1675.7,260.3,1671.2z"
            />
          </svg>
        </div>
        {/*  panneau candidature stage de fin d'études */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 4,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <text
              transform="matrix(1 0 0 1 423.5614 1729.4915)"
              style={{
                fill: "#FFFFFF",
                fontFamily: "Poppins",
                fontWeight: "Bold",
                fontSize: "15px",
              }}
            >
              <tspan x="0" y="0">
                Candidature
              </tspan>
              <tspan x="28.8" y="18">
                stage
              </tspan>
              <tspan x="-6.1" y="36">
                de fin d’études
              </tspan>
            </text>
            <path
              style={{ fill: "#213D33" }}
              d="M414,1736.8h-16.4c-0.9-4.2-4.7-7.3-9.1-7.3c-5.2,0-9.3,4.2-9.3,9.3c0,5.2,4.2,9.3,9.3,9.3c4.5,0,8.2-3.1,9.1-7.3H414V1736.8z"
            />
          </svg>
        </div>
        {/*  panneau candidature stage de fin d'études  options double dipôme à l'étranger  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <text
              transform="matrix(1 0 0 1 528.7059 1484.6638)"
              style={{
                fill: "#FFFFFF",
                fontFamily: "Poppins",
                fontWeight: "Bold",
                fontSize: "15px",
              }}
            >
              <tspan x="0" y="0">
                Candidature
              </tspan>
              <tspan x="28.8" y="18">
                stage
              </tspan>
              <tspan x="-6.1" y="36">
                de fin d’études
              </tspan>
            </text>
            <path
              style={{ fill: "#213D33" }}
              d="M665.7,1485.4c-4.5,0-8.2,3.1-9.1,7.3h-16.4v4h16.4c0.9,4.2,4.7,7.3,9.1,7.3c5.2,0,9.3-4.2,9.3-9.3 S670.8,1485.4,665.7,1485.4z"
            />
          </svg>
        </div>

        {/*  rond point diplôme classique et double diplôme en france */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              style={{ fill: "#EFD992" }}
              d="M388.2,1703.4c-17.5,0-31.7-14.2-31.7-31.7s14.2-31.7,31.7-31.7s31.7,14.2,31.7,31.7 S405.7,1703.4,388.2,1703.4z M388.2,1660.1c-6.4,0-11.7,5.2-11.7,11.7s5.2,11.7,11.7,11.7s11.7-5.2,11.7-11.7 S394.7,1660.1,388.2,1660.1z"
            />
          </svg>
        </div>
        {/* 2ème rond point après le rond point diplôme classique et double diplôme en france */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 1,
          }}
        >
          <svg
            version="1.1"
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 750 3154"
            style={{ enableBackground: "new 0 0 750 3154" }}
            xmlSpace="preserve"
          >
            <path
              style={{ fill: "#EFD992" }}
              d="M388.2,1891.7c-17.5,0-31.7-14.2-31.7-31.7s14.2-31.7,31.7-31.7s31.7,14.2,31.7,31.7 S405.7,1891.7,388.2,1891.7z M388.2,1848.3c-6.4,0-11.7,5.2-11.7,11.7s5.2,11.7,11.7,11.7s11.7-5.2,11.7-11.7 S394.7,1848.3,388.2,1848.3z"
            />
          </svg>
        </div>
        {/* Panneau candidature de fin d'études  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 5,
          }}
          dangerouslySetInnerHTML={{
            __html: `  <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 750 3154" style="enable-background:new 0 0 750 3154;" xml:space="preserve">
                   <text transform="matrix(1 0 0 1 618.5546 2494.9421)"><tspan x="0" y="0" style="fill:#FFFFFF; font-family:'Poppins'; font-Weight:bold; font-size:15px;">${textTranslation[language]["map"].application}</tspan><tspan x="28.8" y="18" style="fill:#FFFFFF; font-family:'Poppins';font-Weight:bold; font-size:15px;">stage</tspan><tspan x="-6.1" y="36" style="fill:#FFFFFF; font-family:'Poppins'; font-Weight:bold; font-size:15px;">de fin d’études</tspan></text>
                   <path style="fill:#213D33;" d="M602,2507.4h-16.4c-0.9-4.2-4.7-7.3-9.1-7.3c-5.2,0-9.3,4.2-9.3,9.3c0,5.2,4.2,9.3,9.3,9.3
                       c4.5,0,8.2-3.1,9.1-7.3H602V2507.4z"/>
                   </svg>`,
          }}
        />

        {/* dernier parcours double diplôme jusqu'au  diplômation  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 750 3154" style="enable-background:new 0 0 750 3154;" xml:space="preserve">
                     <path style="fill:${
                       ((cursus_fifth_Year === 0 && // Vérifie si le cursus de 5a est classique
                         select_diplome === 1 && // Vérifie si le double diplôme est choisi
                         // Vérifie si le double diplôme choisi est différent de TBS
                         (select_double_diplome === 0 ||
                           select_double_diplome === 1 ||
                           select_double_diplome === 2)) ||
                         (engineering_course === 1 && // Vérifie si le parcour ingénieur de 4a est en Université étrangère
                           select_diplome === 2 && // Vérifie si le double diplôme à l'étranger est sélectionné
                           cursus_fifth_Year === 0)) && // Vérifie si le cursus de 5a est classique
                       // Vérifie la page actuelle
                       (current_page === 13 ||
                         current_page === 14 ||
                         current_page === 15 ||
                         current_page === 16)
                         ? "#821F1D"
                         : "#EFD992"
                     };" d="M238,2640.9h-20v-45.5c0-22.1,17.9-40,40-40h99.6c11,0,20-9,20-20v-52.8h20v52.8
                   c0,22.1-17.9,40-40,40H258c-11,0-20,9-20,20V2640.9z"/>
               </svg>
               `,
          }}
        />
        {/* Rond stage de fin d'études 6ème */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 5,
          }}
          dangerouslySetInnerHTML={{
            __html: ` <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 750 3154" style="enable-background:new 0 0 750 3154;" xml:space="preserve">
                   <circle style="fill:#FFFFFF;" cx="576.6" cy="2803.4" r="24.6"/>
                   </svg>`,
          }}
        />

        {/* parcours panneau 6ème année jusqu'au stage de fin d'études   */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: ` <svg svg svg version = "1.1" id = "Calque_1" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px"
    viewBox = "0 0 750 3154" style = "enable-background:new 0 0 750 3154;" xml: space = "preserve" >
        <rect x="566.4" y="2678.9" class= " ${
          student_short_journey.includes(11) // Vérifie si le parcours contient l'étape 11
            ? "parcour-6 parcour-5-options-tbs-fin"
            : ""
        };" style="fill:#EFD992;" width="20" height="123.7" />
               </svg > `,
          }}
        />
        {/* parcours panneau stage de fin d'études jusqu'au jusqu'au  Diplômation   */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `
        <svg svg svg version = "1.1" id = "Calque_1" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px"
    viewBox = "0 0 750 3154"  style = "enable-background:new 0 0 750 3154;" xml: space = "preserve" >
        <rect x="566.4" class= " ${
          // Vérifier la page actuelle
          current_page === 13 ||
          current_page === 14 ||
          current_page === 15 ||
          current_page === 16
            ? "parcour-6 parcour-5-options-tbs-fin"
            : ""
        };" y="2802.6" style="fill:#EFD992;" width="20" height="215.5" />
</svg > `,
          }}
        />

        {/* dernier parcours de diplômation  après 6 ème année
         */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `<svg svg svg version = "1.1" id = "Calque_1" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px"
    viewBox = "0 0 750 3154" style = "enable-background:new 0 0 750 3154;" xml: space = "preserve" >
        <rect x="566.4" y="3055.5" style=fill:${
          // Vérifier la page actuelle
          (current_page === 14 || current_page === 15 || current_page === 16) &&
          select_double_diplome === 3 // Vérifier si le double diplôme TBS est choisi
            ? "#821F1D"
            : "#EFD992"
        }; width="20" height="98.4" />
               </svg > `,
          }}
        />
        {/* panneau diplômation 4ème année  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `<svg svg svg version = "1.1" id = "Calque_1" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px"
    viewBox = "0 0 750 3154" style = "enable-background:new 0 0 750 3154;" xml: space = "preserve" >
               <rect x="154.8" y="2640.9" style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;" width="146.5" height="40"/>
               <text transform="matrix(1 0 0 1 172.3084 2666.2991)" style="fill:#213D33; font-family:'Poppins'; font-size:18px;">Diplômation</text>
               </svg > `,
          }}
        />
        {/* panneau diplômation 6ème année  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `<svg svg svg version = "1.1" id = "Calque_1" xmlns = "http://www.w3.org/2000/svg" xmlns: xlink = "http://www.w3.org/1999/xlink" x = "0px" y = "0px"
    viewBox = "0 0 750 3154" style = "enable-background:new 0 0 750 3154;" xml: space = "preserve" >
               <rect x="503.8" y="3017.1" style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;" width="146.5" height="40"/>
               <text transform="matrix(1 0 0 1 521.297 3042.4683)" style="fill:#213D33; font-family:'Poppins'; font-size:18px;">Diplômation</text>
               </svg > `,
          }}
        />
        {/* Dernier parcours après diplômation DA  */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 2,
          }}
          dangerouslySetInnerHTML={{
            __html: `<svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 750 3154" style="enable-background:new 0 0 750 3154;" xml:space="preserve">
<rect x="218" y="2680.4" style=fill:${
              // Vérifier la page actuelle
              (current_page === 14 ||
                current_page === 15 ||
                current_page === 16) &&
              select_double_diplome !== 3 // Vérifier si le double diplôme choisis est différent de TBS
                ? "#821F1D"
                : "#EFD992"
            }; width="20" height="473.5"/>
</svg>`,
          }}
        />
      </div>
    </>
  );
}