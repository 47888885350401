import React from "react";
import { useRecoilValue } from "recoil";
import { currentPage } from "../recoil/Atomes";
import { TitleComponent } from "./TitleComponent";
import { ImageHeaderComponent } from "./ImageHeaderComponent";
import { SelectCharacter } from "./SelectCharacter";
import { SelectCursus } from "./SelectCursus";
import { SelectYear } from "./SelectYear";
import { ProgressBarComponent } from "./ProgressBarComponent";
import { CursusComponent } from "./CursusComponent";
import { SelectThirdYearCursus } from "./SelectThirdYearCursus";
import { SelectDiplome } from "./SelectDiplome";
import { SelectCursusFifth } from "./SelectCursusFifth";
import { SelectFourthYearSpeciality } from "./SelectCursusFourth";
import { GraduationComponent } from "./GraduationComponent";
import { SelectEngineerCourse } from "./SelectEngineerCourse";
import { SelectDoubleDiploma } from "./SelectDoubleDiploma";
import { HomePage } from "./HomePage";
import { PresentationPage } from "./PresentationPage";
import { CursusSelected } from "./CursusSelected.js";
import { OptionSelected } from "./OptionSelected";
import { DisplayEngineerCourse } from "./DisplayEngineerCourse";
import { ToSelectDaFourth } from "./ToSelectDaFourth";
import { SelectAgroOption } from "./SelectAgroOption";

export default function Content() {
  //  Le component qui centralise à l'aide du switch quelle component est à utiliser pour afficher une page du json
  const current_page = useRecoilValue(currentPage);

  // Afficher le composant adéquat en fonction de la page actuelle
  const renderComponentBasedOnPage = () => {
    switch (current_page) {
      // Page d'accueil
      case 0:
        return <HomePage />;

      // Page de sélection du personnage
      case 1:
        return <SelectCharacter />;

      // Page de présentation de l'école
      case 2:
        return <PresentationPage />;

      // Page de choix du cursus (agro ou ingé)
      case 3:
        return <SelectCursus />;

      // Page de sélection de l'année de départ
      case 4:
        return <SelectYear />;

      // Pages affichant les barres de compétences
      case 5:
      case 7:
      case 9:
      case 18:
      case 660:
      case 110:
      case 170:
      case 183:
      case 184:
      case 1844:
      case 185:
      case 186:
        return <ProgressBarComponent />;

      // Affiche les pages les plus classiques sans aucun choix
      case 6:
      case 770:
      case 55:
      case 77:
      case 8:
      case 80:
      case 89:
      case 12:
      case 173:
      case 182:
      case 101:
        return <CursusComponent />;

      // Pages de sélection pour le cursus de 3a
      case 10:
      case 81:
      case 93:
        return <SelectThirdYearCursus />;

      // Pages de sélection pour l'option liée au cursus de 3a
      case 11:
      case 94:
        return <SelectAgroOption />;

      // Page de sélection de spécialité en 4a
      case 111:
        return <SelectFourthYearSpeciality />;

      // Page de sélection du cursus en 5a
      case 180:
        return <SelectCursusFifth />;

      // Choix du diplôme 
      case 171:
      case 181:
        return <SelectDiplome />;

      // Choix du double diplôme si double diplôme est sélectionné
      case 172:
        return <SelectDoubleDiploma />;

      // Choix du DA à réaliser
      case 174:
        return <ToSelectDaFourth />;

      // Affichage du cours d'ingénieur choisis précédement
      case 175:
      case 175.1:
        return <DisplayEngineerCourse />;

      // Affichage du cursus choisi sur le parcours Agro
      case 82:
      case 91:
        return <CursusSelected />;

      // Affichage de l'option choisie sur le parcours Agro
      case 92:
        return <OptionSelected />;

      // Page de diplômation + après étude et formulaire
      case 13:
      case 14:
      case 15:
      case 16:
        return <GraduationComponent />;

      // Page de sélection du parcours Ingé en 4a (cours d'ingénieur)
      case 17:
        return <SelectEngineerCourse />;
      default:
        return null; // Retourne null si la page n'a pas de composant correspondant
    }
  };

  return (
    <>
      {current_page > 1 && current_page !== 19 && <ImageHeaderComponent />}

      <div className="row w-100 justify-content-center m-0 py-3">
        <TitleComponent />
        {renderComponentBasedOnPage()} {/* Appel de la fonction de rendu */}
      </div>
    </>
  );
}
