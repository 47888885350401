import React from "react";
import { useRecoilState } from "recoil";
import { AllLanguages, textTranslation } from "../config/Multilangues";
import { languageSelected } from "../recoil/Atomes";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useLocation } from 'react-router-dom'

export default function Header() {
  const [language] = useRecoilState(languageSelected);
  const location = useLocation();
 
  return (
    <Navbar className="app-header position-relative d-none">
      <Container className={location.pathname !== "/maps" ? "d-flex justify-content-between pt-4" : "d-flex justify-content-between p-4 nav-bg"}>
        <Navbar.Brand className="h1 m-0 text-white">{textTranslation[language].title_header}</Navbar.Brand>
        {/* <select
          className="select-lang"
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        > */}
        <NavDropdown title={language} className="select-lang">
          {AllLanguages.map((lng, key) => (
            <NavDropdown.Item key={key}>{lng}</NavDropdown.Item>
          ))}
        </NavDropdown>
        {/* {AllLanguages.map((lng, key) => (
            <option key={key} value={lng}>
              {lng}
            </option>
          ))}
        </select> */}
      </Container>
    </Navbar>
  );
}
