import { useRecoilValue } from "recoil";
import {
  currentPage,
  languageSelected,
  cursusIdSelected,
} from "../recoil/Atomes";
import { textTranslation } from "../config/Multilangues";
import { Card } from "react-bootstrap";
import { switchDisplayLogo } from "./Pictures";
import { cursusThirdYear } from "../recoil/Atomes";

// Le component qui définie quel est le cursus sélectionné par l'user
import { stylizeJsonText } from "../services/stylizeJsonText";
export const CursusSelected = () => {
  const cursusId = useRecoilValue(cursusIdSelected);
  const current_page = useRecoilValue(currentPage);
  const language = useRecoilValue(languageSelected);
  const cursusThird = useRecoilValue(cursusThirdYear);

  const valueTextCursus =
    cursusId && current_page > 3
      ? textTranslation[language][cursusId][current_page]
      : textTranslation[language]["general"][current_page];
  return (
    <div className="px-4">
      <Card
        className="home-card mt-3 p-2 text-white fw-bold"
        style={{
          backgroundColor: `${valueTextCursus.options[cursusThird].background + '99'}`,
        }}>
        <Card.Body className="d-flex justify-content-start align-items-center">
          <div className="d-flex align-items-center">
            <div
              className={"image-card-bg"}
              style={{
                backgroundColor: `${valueTextCursus.options[cursusThird].background}33`,
              }}>
              <img
                src={switchDisplayLogo(valueTextCursus.options[cursusThird].logo)}
                alt="..."
                className=""
              />
            </div>
            <Card.Text className="m-0 p-2 w-75 text-start">{valueTextCursus.options[cursusThird].title}</Card.Text>
          </div>
        </Card.Body>
      </Card>
      {valueTextCursus.options[cursusThird] && valueTextCursus.options[cursusThird].paragraph ? (
        <Card className="home-card mt-3 p-3 text-white">
          <Card.Body className="text-start">
            <Card.Text dangerouslySetInnerHTML={{ __html: stylizeJsonText(valueTextCursus.options[cursusThird].paragraph) }} />
          </Card.Body>
        </Card>
      ) : (
        ""
      )}
    </div>
  );
};
