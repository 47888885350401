import { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  currentPage,
  languageSelected,
  cursusFourthYear,
  cursusIdSelected,
  cursusFifthYear,
  selectDiplome,
  selectDoubleDiplome,
  selectDaFourthYear,
  engineeringCourse,
  isDoingTBS,
} from "../recoil/Atomes";
import { textTranslation } from "../config/Multilangues";
import { Card } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { MdClose } from "react-icons/md";
import { switchDisplayLogo } from "./Pictures";
import { stylizeJsonText } from "../services/stylizeJsonText";

// Sélection de la spécialité de 4a
export const SelectFourthYearSpeciality = () => {
  const cursusId = useRecoilValue(cursusIdSelected);
  const current_page = useRecoilValue(currentPage);
  const language = useRecoilValue(languageSelected);
  const [cursusFourth, setCursusFourth] = useRecoilState(cursusFourthYear);
  const setCursusFifth = useSetRecoilState(cursusFifthYear);
  const setSelectedDiplome = useSetRecoilState(selectDiplome);
  const setSelectDD = useSetRecoilState(selectDoubleDiplome);
  const setSelectDaFourth = useSetRecoilState(selectDaFourthYear);
  const setEngineerCourse = useSetRecoilState(engineeringCourse);
  const setisTBS = useSetRecoilState(isDoingTBS);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const valueTextCursus =
    cursusId && current_page > 3
      ? textTranslation[language][cursusId][current_page]
      : textTranslation[language]["general"][current_page];
  return (
    <div className="col-sm-12">
      <div className="row text-center px-4">
        {valueTextCursus.options.map((opt, key) => (
          <Card
            key={key}
            disabled={opt.disabled}
            className="home-card mt-3 px-3 py-2 fw-bold text-white cursor"
            style={{
              backgroundColor: `${key === cursusFourth ? opt.background + '99' : ""}`
            }}
            onClick={() => {
              if (key !== cursusFourth) {
                setCursusFifth("");
                setSelectedDiplome("");
                setSelectDD("");
                setisTBS("");
                setSelectDaFourth("");
                setEngineerCourse("");
              }
              if (key === 1){setEngineerCourse(0);}
              setCursusFourth(key);
            }}
          >
            <Card.Body className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div
                  className="image-card-bg"
                  style={{
                    backgroundColor: `${opt.background}33`,
                  }}>
                  <img
                    src={switchDisplayLogo(opt.logo)}
                    alt="..."
                    className=""
                  />
                </div>
                <Card.Text className="m-0 p-2 w-75 text-start">
                  {opt.title}
                </Card.Text>
              </div>
              {(opt.tooltipTitle || opt.tooltip) && (
                <div>
                  <img
                    src={switchDisplayLogo("tooltip")}
                    alt="tooltip"
                    onClick={() => setShow(key)}
                    className='cursor'
                  />
                  <Modal
                    show={show === key}
                    onHide={handleClose}
                    animation={true}
                    centered
                    size='lg'
                    dialogClassName='popin-bg'
                    contentClassName='popin-content'
                  >
                    <Modal.Header className='border-0'>
                      <Modal.Title
                        className='fw-bold'
                        as={"p"}
                        dangerouslySetInnerHTML={{ __html: stylizeJsonText(opt.tooltipTitle) }}
                      />
                      <MdClose size={27} color='#FFC43E' className='cursor' onClick={() => setShow(false)} />
                    </Modal.Header>
                    <Modal.Body
                      className='pt-0'
                      dangerouslySetInnerHTML={{ __html: stylizeJsonText(opt.tooltip) }}
                    />
                  </Modal>
                </div>
              )}
            </Card.Body>
          </Card>
        ))}
        {cursusFourth !== "" ? (
          cursusId !== "engineer" &&
            valueTextCursus[0][cursusFourth].paragraph ? (
            <div className="col-sm-12">
              <Card style={{ width: "18rem" }} className="m-auto mt-3">
                <Card.Body>
                  <Card.Text>
                    {valueTextCursus[0][cursusFourth].paragraph}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          ) : (
            <Card className="home-card mt-3 p-3 text-white">
              <Card.Body className="text-start">
                <Card.Text
                  dangerouslySetInnerHTML={{
                    __html: stylizeJsonText(
                      valueTextCursus.options[cursusFourth].paragraph
                    ),
                  }}
                />
              </Card.Body>
            </Card>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
