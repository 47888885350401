import { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  currentPage,
  languageSelected,
  cursusThirdYear,
  cursusFourthYear,
  afterDegree,
  cursusIdSelected,
  engineeringCourse,
  cursusFifthYear,
  selectDiplome,
  selectDoubleDiplome,
  selectDaFourthYear,
  isDoingTBS,
} from "../recoil/Atomes";
import { textTranslation } from "../config/Multilangues";
import { Card } from "react-bootstrap";
import { switchDisplayLogo } from "./Pictures";
import { stylizeJsonText } from "../services/stylizeJsonText";
import Modal from 'react-bootstrap/Modal';
import { MdClose } from "react-icons/md";

// Sélection du cursus de 3a
export const SelectThirdYearCursus = () => {
  const current_page = useRecoilValue(currentPage);
  const language = useRecoilValue(languageSelected);
  const cursusId = useRecoilValue(cursusIdSelected);
  const [cursusThird, setCursusThird] = useRecoilState(cursusThirdYear);
  const setCursusFourth = useSetRecoilState(cursusFourthYear);
  const setEngineerCourse = useSetRecoilState(engineeringCourse);
  const setAfterDegree = useSetRecoilState(afterDegree);
  const setCursusFifth = useSetRecoilState(cursusFifthYear);
  const setSelectedDiplome = useSetRecoilState(selectDiplome);
  const setSelectDD = useSetRecoilState(selectDoubleDiplome);
  const setSelectDaFourth = useSetRecoilState(selectDaFourthYear);
  const setisTBS = useSetRecoilState(isDoingTBS);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const valueTextCursus =
    cursusId && current_page > 3
      ? textTranslation[language][cursusId][current_page]
      : textTranslation[language]["general"][current_page];
  return (
    <div className="col-sm-12">
      <div className="row text-center px-4">
        {valueTextCursus.options.map((opt, key) => (
          <Card
            key={key}
            className={"home-card mt-3 px-3 py-2 fw-bold text-white cursor"}
            style={{
              backgroundColor: `${key === cursusThird ? opt.background + '99' : ""}`,
              opacity: `${opt.disabled ? 0.5 : ""}`,
            }}
            onClick={
              opt.disabled
                ? null
                : () => {
                  if (key !== cursusThird) {
                    setCursusFourth("");
                    setCursusFifth("");
                    setSelectedDiplome("");
                    setSelectDD("");
                    setSelectDaFourth("");
                    setAfterDegree("");
                    setEngineerCourse("");
                    setisTBS("");
                  }
                  if(key === 1 && cursusId === 'engineer'){setEngineerCourse(0);}
                  setCursusThird(key);
                }
            }
          >
            <Card.Body className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div
                  className="image-card-bg"
                  style={{
                    backgroundColor: `${opt.background}33`,
                  }}>
                  <img
                    src={switchDisplayLogo(opt.logo)}
                    alt="..."
                    className="cursor"
                  />
                </div>
                <Card.Text className="m-0 p-2 w-75 text-start">
                  {opt.title}
                </Card.Text>
              </div>
              {(opt.tooltipTitle || opt.tooltip) && (
                <div>
                  <img src={switchDisplayLogo("tooltip")} alt="tooltip" onClick={() => setShow(key)} />
                  <Modal
                    show={show === key}
                    onHide={handleClose}
                    animation={true}
                    centered
                    size='lg'
                    dialogClassName='popin-bg'
                    contentClassName='popin-content'
                  >
                    <Modal.Header className='border-0'>
                      <Modal.Title
                        className='fw-bold'
                        as={"p"}
                        dangerouslySetInnerHTML={{ __html: stylizeJsonText(opt.tooltipTitle) }}
                      />
                      <MdClose size={27} color='#FFC43E' className='cursor' onClick={() => setShow(false)} />
                    </Modal.Header>
                    <Modal.Body
                      className='pt-0'
                      dangerouslySetInnerHTML={{ __html: stylizeJsonText(opt.tooltip) }}
                    />
                  </Modal>
                </div>

              )}
            </Card.Body>
          </Card>
        ))}
        {cursusThird !== "" &&
          valueTextCursus.options[cursusThird].paragraph ? (
          <Card className="home-card mt-3 p-3 text-white">
            <Card.Body className="text-start">
              <Card.Text
                dangerouslySetInnerHTML={{
                  __html: stylizeJsonText(
                    valueTextCursus.options[cursusThird].paragraph
                  ),
                }}
              />
            </Card.Body>
          </Card>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
