import { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  currentPage,
  languageSelected,
  yearSelected,
  cursusThirdYear,
  cursusFourthYear,
  afterDegree,
  cursusIdSelected,
  engineeringCourse,
  studentJourney,
  studentShortJourney,
  cursusFifthYear,
  selectDiplome,
  selectDoubleDiplome,
  selectDaFourthYear,
  isDoingTBS,
} from "../recoil/Atomes";
import { textTranslation } from "../config/Multilangues";
import { Card } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { switchDisplayLogo } from "./Pictures";
import { MdClose } from "react-icons/md";
import { formatExposant } from '../services/stylizeJsonText';
import { stylizeJsonText } from '../services/stylizeJsonText';

// Sélection de l'année de départ
export const SelectYear = () => {
  const current_page = useRecoilValue(currentPage);
  const language = useRecoilValue(languageSelected);
  const cursusId = useRecoilValue(cursusIdSelected);
  const setCursusThird = useSetRecoilState(cursusThirdYear);
  const setCursusFourth = useSetRecoilState(cursusFourthYear);
  const setEngineerCourse = useSetRecoilState(engineeringCourse);
  const setAfterDegree = useSetRecoilState(afterDegree);
  const setStudentJourney = useSetRecoilState(studentJourney);
  const setStudentShortJourney = useSetRecoilState(studentShortJourney);
  const setCursusFifth = useSetRecoilState(cursusFifthYear);
  const setSelectedDiplome = useSetRecoilState(selectDiplome);
  const setSelectDD = useSetRecoilState(selectDoubleDiplome);
  const setSelectDaFourth = useSetRecoilState(selectDaFourthYear);
  const [year, setYear] = useRecoilState(yearSelected); // PAGE 4 ONLY
  const setisTBS = useSetRecoilState(isDoingTBS);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const valueTextCursus =
    cursusId && current_page > 3
      ? textTranslation[language][cursusId][current_page]
      : "";

  return (
    <div className="col-sm-12">
      <div className="row text-center px-4">
        {valueTextCursus.options.map((opt, key) => (
          <Card
            key={key}
            className={"home-card p-2 mt-3 fw-bold text-white cursor"}
            style={{
              backgroundColor: `${key + 1 === year ? opt.background + '99' : ""}`
            }}
            onClick={() => {
              if (key + 1 !== year) {
                setCursusFourth("");
                setAfterDegree("");
                setEngineerCourse("");
                setCursusThird("");
                setCursusFifth("");
                setSelectedDiplome("");
                setSelectDD("");
                setisTBS("");
                setSelectDaFourth("");
                setStudentJourney({ years: [] });
                setStudentShortJourney([]);
              }
              setYear(key + 1);
              setStudentJourney({ years: [key + 1] });
            }}
          >
            <Card.Body className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div
                  className={"p-4 image-card-bg"}
                  style={{
                    backgroundColor: `${opt.background}33`,
                  }}>
                  <img
                    src={switchDisplayLogo(opt.logo)}
                    alt={key + 1}
                    className="cursor"
                  />
                </div>
                <Card.Text className="m-0 p-2">
                  {formatExposant(opt.title)}
                </Card.Text>
                <Modal
                  show={show === key}
                  onHide={handleClose}
                  animation={true}
                  centered
                  size='lg'
                  dialogClassName='popin-bg'
                  contentClassName='popin-content'
                >
                  <Modal.Header className='border-0'>
                    <Modal.Title
                      className='fw-bold'
                      as={"p"}
                      dangerouslySetInnerHTML={{ __html: stylizeJsonText(opt.tooltipTitle) }}
                    />
                    <MdClose size={27} color='#FFC43E' className='cursor' onClick={() => setShow(false)} />
                  </Modal.Header>
                  <Modal.Body
                    className='pt-0'
                    dangerouslySetInnerHTML={{ __html: stylizeJsonText(opt.tooltip) }}
                  />
                </Modal>
              </div>
              <img src={switchDisplayLogo("tooltip")} alt="tooltip" onClick={() => setShow(key)} />
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
};
