import { useRecoilValue } from "recoil";
import { useState, useEffect } from "react";
import {
  currentPage,
  languageSelected,
  cursusIdSelected,
  cursusThirdYear,
  engineeringCourse,
} from "../recoil/Atomes";
import { textTranslation } from "../config/Multilangues";
import { switchDisplayLogo } from "./Pictures";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { stylizeJsonText } from "../services/stylizeJsonText";

//  Le component qui est utilisé lorsqu'on a une page qui souhaite afficher le % de progression de savoir-être...
export const ProgressBarComponent = () => {
  const cursusId = useRecoilValue(cursusIdSelected);
  const cursusThird = useRecoilValue(cursusThirdYear);
  const engineerCourse = useRecoilValue(engineeringCourse);
  const current_page = useRecoilValue(currentPage);
  const language = useRecoilValue(languageSelected);

  const valueTextCursus =
    cursusId && current_page > 3
      ? textTranslation[language][cursusId][current_page]
      : textTranslation[language]["general"][current_page];

  // Animation progressBar
  const initialProgressValues = [
    { current: 0, target: valueTextCursus.experience.percentage },
    { current: 0, target: valueTextCursus.knowledge.percentage },
    { current: 0, target: valueTextCursus.speciality.percentage }
  ];
  const [progressValues, setProgressValues] = useState(initialProgressValues);

  // Actualiser valeurs lors du chargement du même composant lors d'un changement de page
  useEffect(()=> {
    setProgressValues(initialProgressValues);
  }, [current_page]);

  useEffect(() => {
    // Vérifiez si toutes les valeurs de progression ont atteint leurs valeurs cibles
    const allValuesReachedTarget = progressValues.every(
      (progress) => progress.current >= progress.target
    );

    if (allValuesReachedTarget) {
      return; // Arrêtez l'effet si toutes les valeurs ont atteint leurs cibles
    }

    const updateProgress = () => {
      const updatedValues = progressValues.map((progress) => {
        if (progress.current < progress.target) {
          return { ...progress, current: progress.current + 5 };
        }
        return progress;
      });

      setProgressValues(updatedValues);
    };

    const interval = setInterval(updateProgress, 20);
    
    return () => {
      clearInterval(interval);
    };
  }, [progressValues]);

  return (
    <div className="px-4">
      {/* Vérifie si la card doit contenir un logo */}
      {valueTextCursus.logo && (
        <Card className="text-white fw-bold home-card px-3 py-2 mt-3 selected">
          <Card.Body className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div className="image-card-bg p-2">
                <img
                  src={switchDisplayLogo(valueTextCursus.logo)}
                  alt="..."
                  className=""
                />
              </div>
              <Card.Text className="m-0 p-2 text-start">
                {valueTextCursus.title2}
              </Card.Text>
            </div>
            {/* Vérifie si la card doit contenir un tooltip */}
            {valueTextCursus.tooltip && (
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="button-tooltip-2">
                    <div className="text-start p-2">
                      <Card.Text className="fw-bold">
                        {valueTextCursus.tooltipTitle}
                      </Card.Text>
                      <Card.Text>{valueTextCursus.tooltip}</Card.Text>
                    </div>
                  </Tooltip>
                }
              >
                <img src={switchDisplayLogo("tooltip")} alt="tooltip" />
              </OverlayTrigger>
            )}
          </Card.Body>
        </Card>
      )}
      {/* Vérifie si les données de la barre de compétence existe */}
      {valueTextCursus.experience && (
        <Card className="home-card mt-3 p-3 text-white fw-bold">
          <Card.Body className="d-flex flex-row">
            <div className="col-6 d-flex flex-column justify-content-around">
              <p className="d-flex justify-content-start m-0">
                {valueTextCursus.experience.name} :{" "}
              </p>
              <p className="d-flex justify-content-start m-0">
                {valueTextCursus.knowledge.name} :{" "}
              </p>
              <p className="d-flex justify-content-start m-0">
                {valueTextCursus.speciality.name} :{" "}
              </p>
            </div>
            <div className="col-6 d-flex flex-column justify-content-around">
              {/* Affichage des progress bar */}
              {progressValues.map((progress, index) => (
                  <ProgressBar
                    key={index}
                    now={progress.current}
                    max={100}
                  />
              ))}
            </div>
          </Card.Body>
        </Card>
      )}
      <Card className="home-card m-auto mt-3 p-3 text-white text-start">
        <Card.Body>
          <Card.Text dangerouslySetInnerHTML={{
            __html: stylizeJsonText(current_page === 12
              ? valueTextCursus.paragraph[cursusThird]
              : current_page === 18
                ? valueTextCursus.paragraph[engineerCourse]
                : valueTextCursus.paragraph)
          }} />
        </Card.Body>
      </Card>
    </div>
  );
};
