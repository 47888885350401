import { useRecoilValue } from "recoil";
import {
  currentPage,
  languageSelected,
  cursusIdSelected,
} from "../recoil/Atomes";
import { textTranslation } from "../config/Multilangues";
import { Card } from "react-bootstrap";
import { stylizeJsonText } from "../services/stylizeJsonText";

// Page de présentation de l'école
export const PresentationPage = () => {
  const current_page = useRecoilValue(currentPage);
  const language = useRecoilValue(languageSelected);
  const cursusId = useRecoilValue(cursusIdSelected);

  const valueTextCursus =
    cursusId && current_page > 3
      ? textTranslation[language][cursusId][current_page]
      : textTranslation[language]["general"][current_page];

  return (
    <div className="col-sm-12 px-4">
      <Card className="m-auto text-start home-card py-3 px-3">
        <Card.Body>
          <Card.Text className="text-white" dangerouslySetInnerHTML={{__html: stylizeJsonText(valueTextCursus.paragraph)}}></Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};
