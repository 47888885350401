import { useRecoilValue, useRecoilState } from "recoil";
import React, { useEffect } from 'react';
import {
  currentPage,
  languageSelected,
  cursusIdSelected,
  characterSelected,
  studentJourney,
  isDoingTBS,
  cursusFifthYear,
  engineeringCourse,
  cursusThirdYear,
  cursusFourthYear,
  studentShortJourney,
  yearSelected,
  parcours,
  selectDiplome,
  selectDoubleDiplome,
  selectDaFourthYear,

} from "../recoil/Atomes";
import { filterYears } from "../services/filterYears";
import { switchDisplayImage } from "./Pictures";
import { textTranslation } from "../config/Multilangues";
import FIRST from "../img/logo/first.svg";
import SECOND from "../img/logo/second.svg";
import THIRD from "../img/logo/third.svg";
import FOURTH from "../img/logo/fourth.svg";
import FIFTH from "../img/logo/fifth.svg";
import SIXTH from "../img/logo/sixth.svg";
import STAR from "../img/logo/graduation_star.svg";

//  Le component  qui permet d'afficher l'image et la tenue du personnage sélectionné selon l'image en background
export const ImageHeaderComponent = () => {
  const [parcoursEnCours, setParcoursEnCours] = useRecoilState(parcours);
  const cursusId = useRecoilValue(cursusIdSelected);
  const [current_page, setcurrent_page] = useRecoilState(currentPage);
  const language = useRecoilValue(languageSelected);
  const character = useRecoilValue(characterSelected);
  const cursusFith = useRecoilValue(cursusFifthYear);
  const [tbs, setIsTbs] = useRecoilState(isDoingTBS);
  const [engineering, setEngineeringCourse] = useRecoilState(engineeringCourse);
  const [, setCursusFifthYear] = useRecoilState(cursusFifthYear);
  const [, setSelectDiplome] = useRecoilState(selectDiplome);
  const [, setSelectDoubleDiplome] = useRecoilState(selectDoubleDiplome);
  const [, setDA] = useRecoilState(selectDaFourthYear);

  const [, setStudentShortJourney] = useRecoilState(studentShortJourney);
  const [student_journey, setStudentJourney] = useRecoilState(studentJourney);
  const [, setCursusFourthYear] = useRecoilState(cursusFourthYear)
  const [, setCursusThirdYear] = useRecoilState(cursusThirdYear)
  const [selectedYear, setSelectedYear] = useRecoilState(yearSelected)

  const valueTextCursus =
    cursusId && current_page > 3
      ? textTranslation[language][cursusId][current_page]
      : textTranslation[language]["general"][current_page];


  useEffect(() => { // Ceci permet de mettre à jour la var parcoursEnCours qui est envoyé au pdf lorsqu'on parcours les pages 
    // Trouver l'index de l'élément avec l'ID X
    const index = parcoursEnCours.findIndex(
      (item) => item.page === current_page
    );

    if (index !== -1) {
      // Si l'ID X existe dans le tableau, supprimer les éléments jusqu'à cet index
      const newData = parcoursEnCours.slice(0, index); // +1 pour inclure l'élément avec l'ID X

      // Mettre à jour le tableau avec le nouveau tableau résultant
      setParcoursEnCours(newData);
    }
  }, [current_page, parcoursEnCours, setParcoursEnCours]);


  // Réinitialiser les information envoyés dans le PDF lors du clic sur une icône année
  const resetPdfData = (yearIndex) => {

    // Récupérer les valeurs en fonction de l'année de départ
    const valueYear =
      cursusId && current_page > 3
        ? textTranslation[language][cursusId][4]
        : textTranslation[language]["general"][4];

    // Ajouter les informations d'entrée de l'année sélectionnée dans le récap du PDF
    let newData = parcoursEnCours.slice(0, 4)
    newData = [
      ...newData,
      {
        page: 4,
        title: valueYear.title,
        logo: valueYear.options[yearIndex].logo,
        titleResponse: valueYear.options[yearIndex].title,
        paragraph: valueYear.options[yearIndex].paragraph,
      },]
    return newData;
  }

  // Changer d'année lors du clic sur une icône année
  const switchYear = (year) => {
    switch (year) {
      // Lors du clic sur l'année 1
      case 1:
        if (cursusId === "agro") { //Si le parcours est en Agro
          setCursusFourthYear("");
          setCursusThirdYear("");
        }
        else { // Si le parcours est en Ingé
          setCursusFourthYear("");
          setCursusThirdYear("");
          setCursusFifthYear("");
          setEngineeringCourse("");
          setSelectDiplome("");
          setSelectDoubleDiplome("");
        }
        setcurrent_page(5);
        setStudentShortJourney([]);
        setStudentJourney({ years: [1] });
        setSelectedYear(1);
        setParcoursEnCours(resetPdfData(year - 1));
        break
      // Lors du clic sur l'année 2
      case 2:
        if (cursusId === "agro") { //Si le parcours est en Agro
          setCursusFourthYear("");
        }
        else { //Si le parcours est en Ingé
          setCursusThirdYear("");
          setCursusFourthYear("");
          setCursusFifthYear("");
          setEngineeringCourse("");
          setSelectDiplome("");
          setSelectDoubleDiplome("");
        }
        // Reset sans vérifier le cursus
        setStudentShortJourney([]);
        setStudentJourney({ years: [2] });
        setcurrent_page(7);
        setSelectedYear(2);
        setParcoursEnCours(resetPdfData(year - 1));
        break
      // Lors du clic sur l'année 3
      case 3:
        if (cursusId === "agro") { //Si le parcours est en Agro
          setCursusFourthYear("");
          setCursusThirdYear("");
        }
        else { //Si le parcours est en Ingé
          setCursusFourthYear("");
          setCursusThirdYear("");
          setCursusFifthYear("");
          setSelectDiplome("");
          setEngineeringCourse("");
        }
        // Reset sans vérifier le parcours
        setSelectedYear(3);
        setcurrent_page(9);
        setStudentShortJourney([]);
        setStudentJourney({ years: [3] });
        setParcoursEnCours(resetPdfData(year - 1));
        break
      // Lors du clic sur l'année 4
      case 4:
        setStudentShortJourney([1, 2, 3, 4, 5, 6, 7]);
        setCursusFifthYear("");
        setStudentJourney({ years: filterYears([1, 2, 3, 4], selectedYear) });
        setcurrent_page(170);
        setSelectDiplome("");
        setSelectDoubleDiplome("");
        setDA("");
        setIsTbs("");
        break
      // Lors du clic sur l'année 5
      case 5:
        setStudentShortJourney([1, 2, 3, 4, 5, 6, 7, 9]);
        setCursusFifthYear("");
        setStudentJourney({ years: filterYears([1, 2, 3, 4, 5], selectedYear) });
        setcurrent_page(18);
        break
      // Lors du clic sur l'année 6
      case 6:
        setcurrent_page(185);
        setStudentShortJourney([1, 2, 3, 4, 5, 6, 7, 9, 10]);
        break
      default:
    }
  }


  // Changer la tenue du personnage en fonction de l'image de fond
  const switchCharacterCosplay = () => {
    if (valueTextCursus.image) {
      if (
        valueTextCursus.image.includes("hall") ||
        valueTextCursus.image.includes("ext")
      ) {
        return (
          <img
            src={require(`../img/${character.name}.png`)}
            alt="..."
            className="position-absolute h-75"
            style={{ bottom: -1, right: '30%' }}
          />
        );
      } else if (valueTextCursus.image.includes("farm")) {
        return (
          <img
            src={require(`../img/${character.name}_farm.png`)}
            alt="..."
            className="position-absolute img-fluid h-75"
            style={{ bottom: 0, right: '30%' }}
          />
        );
      } else if (valueTextCursus.image.includes("abroad")) {
        return (
          <img
            src={require(`../img/${character.name}_abroad.png`)}
            alt="..."
            className="position-absolute img-fluid h-75"
            style={{ bottom: 0, right: '30%' }}
          />
        );
      } else if (valueTextCursus.image.includes("internship")) {
        return (
          <img
            // src={require(`../img/${character.name}_entreprise.png`)}
            src={engineering === 0 ? require(`../img/${character.name}_research.png`) : require(`../img/${character.name}_entreprise.png`)}
            alt="..."
            className="position-absolute img-fluid h-75"
            style={{ bottom: 0, right: '30%' }}
          />
        );
      } else if (valueTextCursus.image.includes("solidarity")) {
        return (
          <img
            src={require(`../img/${character.name}_ped.png`)}
            // src={engineering === 0 ? require(`../img/${character.name}_research.png`) : require(`../img/${character.name}_entreprise.png`)}
            alt="..."
            className="position-absolute img-fluid h-75"
            style={{ bottom: 0, right: '30%' }}
          />
        );
      }
      else if (valueTextCursus.image.includes("graduation")) {
        return (
          <img
            src={require(`../img/${character.name}_graduation.png`)}
            alt="..."
            className="position-absolute img-fluid h-75"
            style={{ bottom: 0, right: '30%' }}
          />
        );
      } else if (current_page === 15) {
        return (
          <img
            src={require(`../img/${character.name}.png`)}
            alt="..."
            className="position-absolute img-fluid"
            style={{ bottom: 0, left: 0 }}
          />
        );
      }
      // return <img src={EABROAD} alt="..." className="position-absolute" style={{ bottom: -0, left: 0 }} />;
    }
  };

  return (
    <>
      {/* Afficher le personnage et l'image de fond si la page est différente de 15 et 16 */}
      {current_page !== 15 && current_page !== 16 && (
        <div
          className="col-sm-12 image-header"
          style={{
            // top: -49,
            backgroundImage: `url(${switchDisplayImage(valueTextCursus.image)}`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          {switchCharacterCosplay()}
          {current_page >= 5 && current_page !== 14 && (
            <div
              className="d-flex position-absolute align-items-center fw-bold fs-6 allYears"
              style={{ bottom: "26vh", left: 20 }}
            >
              <div className="d-flex">
                <img
                  src={FIRST}
                  onClick={() => switchYear(1)}
                  className="cursor"
                  alt="First year icone"
                >

                </img>
                <img
                  src={SECOND}
                  onClick={() => switchYear(2)}
                  className={
                    valueTextCursus.year >= 2 || current_page === 13
                      ? "year cursor"
                      : "mr-5 opacity-50 year cursor"
                  }
                  alt="Second year icone"
                >
                </img>
                <img
                  src={THIRD}
                  onClick={() => switchYear(3)}
                  className={
                    valueTextCursus.year >= 3 || current_page === 13
                      ? "year cursor"
                      : "year opacity-50 cursor"
                  }
                  alt="Third year icone"
                ></img>
              </div>

              <div className={cursusId === "engineer" ? "d-flex" : "d-none"}>
                <img
                  src={FOURTH}
                  onClick={
                    student_journey.years.includes(4)
                      ? () => switchYear(4)
                      : null
                  }
                  className={
                    valueTextCursus.year >= 4 || current_page === 13
                      ? "year cursor"
                      : "year opacity-50"
                  }
                  alt="Fourth year icone"
                ></img>
                <img
                  src={FIFTH}
                  onClick={
                    student_journey.years.includes(5)
                      ? () => switchYear(5)
                      : null
                  }
                  className={
                    valueTextCursus.year >= 5 || current_page === 13
                      ? "year cursor"
                      : "year opacity-50"
                  }
                  alt="Fifth year icone"
                ></img>
                {tbs === 1 && cursusFith !== 1 && (
                  <img
                    src={SIXTH}
                    onClick={
                      student_journey.years.includes(6)
                        ? () => switchYear(6)
                        : null
                    }
                    className={
                      valueTextCursus.year >= 6 || current_page === 13
                        ? "year cursor"
                        : "year opacity-50"
                    }
                    alt="Sixth year icone"
                  ></img>
                )}
              </div>
              <img
                src={STAR}
                className={current_page === 13 ? "year" : "year opacity-50"}
                alt="Star icone"
              ></img>
            </div>
          )}
        </div>
      )}
      {/* Image circulaire si la page est égale à 15 */}
      {current_page === 15 && (
        <div className="col-sm-12 mx-auto mt-5 image-form-header">
          {switchCharacterCosplay()}
        </div>
      )}
      {current_page === 16 && <div className="col-sm-12 mt-5"></div>}
    </>
  );
};
