import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  currentPage,
  languageSelected,
  cursusFourthYear,
  afterDegree,
  cursusIdSelected,
} from "../recoil/Atomes";
import { textTranslation } from "../config/Multilangues";
import { Card } from "react-bootstrap";
import { switchDisplayLogo } from "./Pictures";
import { stylizeJsonText } from "../services/stylizeJsonText";

export const SelectAgroOption = () => {
  const current_page = useRecoilValue(currentPage);
  const language = useRecoilValue(languageSelected);
  const cursusId = useRecoilValue(cursusIdSelected);
  const [cursusFourth, setCursusFourth] = useRecoilState(cursusFourthYear);
  const setAfterDegree = useSetRecoilState(afterDegree);

  const valueTextCursus =
    cursusId && current_page > 3
      ? textTranslation[language][cursusId][current_page]
      : textTranslation[language]["general"][current_page];

  return (
    <div className="col-sm-12 ">
      <div className="row text-center px-4">
        {valueTextCursus.options.map((opt, key) => (
          <Card
            key={key}
            className="home-card mt-3 px-3 py-2 fw-bold text-white cursor"
            style={{
              backgroundColor: `${key === cursusFourth ? opt.background + '99' : ""}`
            }}
            onClick={() => {
              if (key !== cursusFourth) {
                setAfterDegree("");
              }
              setCursusFourth(key);
            }}
          >
            <Card.Body className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="image-card-bg"
                  style={{
                    backgroundColor: `${opt.background}33`,
                  }}>
                  <img
                    src={switchDisplayLogo(opt.logo)}
                    alt="..."
                    className="cursor"
                  />
                </div>
                <p className="m-0 p-2 w-75 text-start">{opt.title}</p>
              </div>
            </Card.Body>
          </Card>
        ))}
        {/* Vérifie si le cursus de 4a existe*/}
        {cursusFourth !== "" &&
          valueTextCursus.options[cursusFourth].paragraph ? (
          <Card className="home-card mt-3 p-3 text-white">
            <Card.Body className="text-start">
              <Card.Text dangerouslySetInnerHTML={{ __html: stylizeJsonText(valueTextCursus.options[cursusFourth].paragraph) }} />
            </Card.Body>
          </Card>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
