/*
  Voici quelques règles et conseils, ainsi que des explications des schémas actuels : 

  ATTENTION : On évite d'utiliser des identifiants identiques pour le parcours agro et ingénieur sauf si la page qu'on souhaite afficher est "banal" ou avec le même fonctionnement

  Dans le json, ce qui est dans le général est le contenu afficher lors des 3 premières pages et qui sont exactement identiques pour les deux
  Ce qu'on appelle un parcours ici, est ce qui est contenu dans les valeurs "general", "agro" et "ingenieur" 

  Voici plusieurs exemples de ce qui a été faits avec leur explications : 

  Avant tout, certains champs sont optionnelles, on est pas obligé de tout le temps avec un paragraph, un tooltip...

  Exemple de page avec les barres de progressions qui est affiché avec le component ProgressBarComponent : 

  exempleTYPEID => identifiant unique dans un parcours : {
        previous: 4,  => renvoie à cette page lorsqu'on clique précèdent
        next: 55, => renvoie à cette page lorsqu'on clique suivant
        image: "purpan_ext", => le code du background d'image qu'on veut afficher est définie dans le fichier Pictures.js
        title: "La 1ère année 🤓", => C'est le premier titre en dessous de l'image
        experience: { name: "Savoir-faire", percentage: 25 }, => la première barre de progression
        knowledge: { name: "Savoir-être", percentage: 30 }, => la deuxième barre de progression
        speciality: { name: "Savoir s'adapter", percentage: 20 }, => la troisième barre de progression
        paragraph: "exemple de paragrapheparagraphe", => paragraphe afficher dans la card
  },

  Exemple de page avec les barres de progressions qui est affiché avec le component ProgressBarComponent : 

  exempleTYPEID => identifiant unique dans un parcours : {
        image: "purpan_ext", => le code du background d'image qu'on veut afficher est définie dans le fichier Pictures.js
        title: "La 1ère année 🤓", => C'est le premier titre en dessous de l'image
        experience: { name: "Savoir-faire", percentage: 25 }, => la première barre de progression
        knowledge: { name: "Savoir-être", percentage: 30 }, => la deuxième barre de progression
        speciality: { name: "Savoir s'adapter", percentage: 20 }, => la troisième barre de progression
        paragraph: "exemple de paragrapheparagraphe", => paragraphe afficher dans la card
        options: [ => le previous et next sont désormais dans le champ options, car selon les options choisies par l'utilisateur, il n'aura pas la même navigation depuis cette page, donc il faut aller dans la page Home.js et gérer ce cas individuellement
          { previous: 8, next: 10 },
          { previous: 8, next: 10 },
          { previous: 4, next: 10 },
        ],
  },

  Exemple de page avec un deuxième titre et un logo, on peut mettre ou non un paragraphe ou un tooltip :

  exempleTYPEID => identifiant unique dans le parcours :{
        previous: 77,  => renvoie à cette page lorsqu'on clique précèdent
        next: 79, => renvoie à cette page lorsqu'on clique suivant
        image: "purpan_ext", => le code du background d'image qu'on veut afficher est définie dans le fichier Pictures.js
        title: "Au second semestre", => C'est le premier titre en dessous de l'image
        logo: "plane_yellow", => c'est le code du logo à afficher qui est définis dans Pictures.js,
        title2: "Je postule pour mon stage en pays anglophone", => c'est le titre à droite du logo
        tooltipTitle: "Stage en pays anglophone", => titre à afficher dans le tooltip 
        tooltip: "texte tooltip", => texte à afficher dans le tooltip
        paragraph: "exemple de paragrapheparagraphe", => paragraphe afficher dans la card
      },

  Exemple de page à choix : 

  exempleTYPEID => identifiant unique dans le parcours :{
        image: "purpan_ext", => le code du background d'image qu'on veut afficher est définie dans le fichier Pictures.js
        title: "Au second semestre", => C'est le premier titre en dessous de l'image
        options: [ le previous et next sont désormais dans le champ options, car selon les options choisies par l'utilisateur, il n'aura pas la même navigation depuis cette page, donc il faut aller dans la page Home.js et gérer ce cas individuellement
          {
            previous: 9, => renvoie à cette page lorsqu'on clique précèdent
            next: 100,  => renvoie à cette page lorsqu'on clique suivant
            logo: "agriculture_environment",  => c'est le code du logo à afficher qui est définis dans Pictures.js,
            title: "Agriculture et environnement", => c'est le titre à droite du logo
            tooltipTitle: "Agriculture et environnement",  => titre à afficher dans le tooltip 
            tooltip: "tooltip à afficher", => texte à afficher dans le tooltip 
            paragraph: "exemple de para", => paragraphe afficher dans la card
          },
          {
            previous: 9, => renvoie à cette page lorsqu'on clique précèdent
            next: 100,  => renvoie à cette page lorsqu'on clique suivant
            logo: "foodproduction_supplychain", => c'est le code du logo à afficher qui est définis dans Pictures.js,
            title: "Food production & Supply chain",  => c'est le titre à droite du logo
            tooltipTitle: "Food production & Supply chain", => titre à afficher dans le tooltip 
            tooltip: "tooltip à afficher", => texte à afficher dans le tooltip 
            paragraph: "exemple de para", => paragraphe afficher dans la card
          },
        ],
      },    

  Exemple de page qui affiche le même contenu malgré les diffèrentes précèdentes réponses, mais réalise une navigation diffèrente
  exempleTYPEID => identifiant unique dans le parcours : {
        0: [ // Selon si je fais le parcours FISA OU FISE ma navigation est différente
          {
            previous: 182, => renvoie à cette page lorsqu'on clique précèdent
            next: 184, => renvoie à cette page lorsqu'on clique suivant
          },
        ],
        1: [ // Si je fais le parcours FISA, la navigation change selon change si c'est depuis la 5 ème année ou avant, si c'est la 5ème année la valeur est égale à 1 sinon 0
          {
            previous: 18, => renvoie à cette page lorsqu'on clique précèdent
            next: 13, => renvoie à cette page lorsqu'on clique suivant
          },
          {
            previous: 180, => renvoie à cette page lorsqu'on clique précèdent
            next: 13, => renvoie à cette page lorsqu'on clique suivant
          },
        ],
        image: "purpan_ext", => le code du background d'image qu'on veut afficher est définie dans le fichier Pictures.js
        title: "Je suis mon DA 💪", => C'est le premier titre en dessous de l'image
        experience: { name: "Savoir-faire", percentage: 90 },
        knowledge: { name: "Savoir-être", percentage: 90 },
        speciality: { name: "Savoir s'adapter", percentage: 92 },
        paragraph: "exemple de para", => paragraphe afficher dans la card
      },

      À noter qu'il est également possible d'ajouter des sauts à la ligne et de mettre en gras le texte à partir du json.
      
      - Dans le cas où je souhaite ajouter un saut de ligne, je dois écrire « \n » là où je veux aller à la ligne. Par exemple dans le texte ci-contre : 

      paragraph: "exemple \n de paragraphe" -> le saut de ligne se fera juste après le mot « exemple ». Il est recommandé de laisser un espace entre le mot qui précéde et succéde le « \n »

      - Dans le cas où je souhaite mettre en gras le texte du json, je dois encercler le texte que je souhaite modifier par **. Par exemple : 

      paragraph: "exemple de **paragraphe**" -> le mot « paragraphe » sera en gras sur l'application. À noter que les titres sont déjà en gras sur le site web. Cette fonctionnalité s'adresse particulièrement aux paragraphe.
  
*/

import ParcoursFR from "./ParcoursFR.json";
import ParcoursEN from "./ParcoursEN.json";

export const AllLanguages = ["FR", "EN"];

export const textTranslation = {
  FR: ParcoursFR,
  EN: ParcoursEN,
};
