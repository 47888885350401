// Ajouter des sauts de lignes aux paragraphes et mettre texte en gras
export const stylizeJsonText = (text) => {
    text = text.replace(/\n/g, "<br />");
    text = text.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
    text = formatExposant(text); // Appel de la fonction pour changer en exposant
    text = formatAgro(text.props.dangerouslySetInnerHTML.__html); // Appel de la fonction pour changer le numero qui suit agro en exposant
    return text.props.dangerouslySetInnerHTML.__html;
};

// Transformer le texte en exposant
export const formatExposant = (text) => {
    const formattedText = text.replace(
        /(\d+)(ème|re)|(\d+)e/g,
        (match, p1, p2, p3) => {
            if (p1) {
                return `${p1}<sup class="exposant">${p2}</sup>`;
            } else if (p3) {
                return `${p3}<sup class="exposant">e</sup>`;
            }
            return match;
        }
    );
    return <span dangerouslySetInnerHTML={{ __html: formattedText }} />;
};

// Rendre le numero qui suit "agro" au format exposant
export const formatAgro = (text) => {
    const formatedAgro = text.replace(
        /Agro(\d+)/g,
        (match, p1) => {
            return `Agro<sup class="exposant">${p1}</sup>`;
        }
    );
    return <span dangerouslySetInnerHTML={{ __html: formatedAgro }} />;
};
