import { useState } from 'react';
import { useRecoilState, useRecoilValue } from "recoil";
import {
  currentPage,
  languageSelected,
  cursusFifthYear,
  cursusIdSelected,
  selectDiplome,
} from "../recoil/Atomes";
import { textTranslation } from "../config/Multilangues";
import { Card } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { MdClose } from "react-icons/md";
import { switchDisplayLogo } from "./Pictures";
import { stylizeJsonText } from "../services/stylizeJsonText";

// Sélection du cursus de 5a
export const SelectCursusFifth = () => {
  const cursusId = useRecoilValue(cursusIdSelected);
  const current_page = useRecoilValue(currentPage);
  const language = useRecoilValue(languageSelected);
  const [cursusFifth, setCursusFifth] = useRecoilState(cursusFifthYear);
  const selectedDiplome = useRecoilValue(selectDiplome);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const valueTextCursus =
    cursusId && current_page > 3
      ? textTranslation[language][cursusId][current_page]
      : textTranslation[language]["general"][current_page];
  return (
    <div className="col-sm-12">
      <div className="row text-center px-4">
        {valueTextCursus.options.map((opt, key) => (
          <Card
            key={key}
            className="home-card mt-3 px-3 py-2 fw-bold text-white cursor"
            style={{
              backgroundColor: `${key === cursusFifth ? opt.background + '99' : ""}`,
              opacity: `${selectedDiplome >= 1 && opt.disabled ? 0.5 : ""}`
            }}
            onClick={
              opt.disabled && selectedDiplome >= 1
                ? null
                : () => {
                  setCursusFifth(key);
                }
            }
          >
            <Card.Body className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="image-card-bg"
                  style={{
                    backgroundColor: `${opt.background}33`,
                  }}>
                  <img
                    src={switchDisplayLogo(opt.logo)}
                    alt="..."
                    className=""
                  />
                </div>
                <Card.Text className="m-0 p-2 w-75 text-start">
                  {opt.title}
                </Card.Text>
              </div>
              {(opt.tooltipTitle || opt.tooltip) && (
                <div>
                  <img src={switchDisplayLogo("tooltip")} alt="tooltip" onClick={opt.disabled && selectedDiplome >= 1
                    ? null
                    : () => {
                      setShow(key);
                    }} className='cursor'/>
                  <Modal
                    show={show === key}
                    onHide={handleClose}
                    animation={true}
                    centered
                    size='lg'
                    dialogClassName='popin-bg'
                    contentClassName='popin-content'
                  >
                    <Modal.Header className='border-0'>
                      <Modal.Title
                        className='fw-bold'
                        as={"p"}
                        dangerouslySetInnerHTML={{ __html: stylizeJsonText(opt.tooltipTitle) }}
                      />
                      <MdClose size={27} color='#FFC43E' className='cursor' onClick={() => setShow(false)} />
                    </Modal.Header>
                    <Modal.Body
                      className='pt-0'
                      dangerouslySetInnerHTML={{ __html: stylizeJsonText(opt.tooltip) }}
                    />
                  </Modal>

                </div>
              )}
            </Card.Body>
          </Card>
        ))}
        {cursusFifth !== "" ? (
          cursusId !== "engineer" &&
            valueTextCursus[0][cursusFifth].paragraph ? (
            <Card className={"home-card mt-3 px-3 py-2 text-white text-start"}>
              <Card.Body>
                <Card.Text
                  dangerouslySetInnerHTML={{ __html: stylizeJsonText(valueTextCursus[0][cursusFifth]) }}
                />
              </Card.Body>
            </Card>
          ) : (
            <Card className={"home-card mt-3 p-3 text-white text-start"}>
              <Card.Body>
                <Card.Text
                  dangerouslySetInnerHTML={{
                    __html: stylizeJsonText(
                      valueTextCursus.options[cursusFifth].paragraph
                    )
                  }}
                />
              </Card.Body>
            </Card>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
