import { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  currentPage,
  languageSelected,
  selectDoubleDiplome,
  cursusIdSelected,
  cursusFifthYear,
  selectDaFourthYear,
  isDoingTBS,
} from "../recoil/Atomes";
import { textTranslation } from "../config/Multilangues";
import { Card } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { MdClose } from "react-icons/md";
import { switchDisplayLogo } from "./Pictures";
import { stylizeJsonText } from '../services/stylizeJsonText';

// Sélection du double diplôme
export const SelectDoubleDiploma = () => {
  const cursusId = useRecoilValue(cursusIdSelected);
  const current_page = useRecoilValue(currentPage);
  const language = useRecoilValue(languageSelected);
  const [selectDD, setSelectDD] = useRecoilState(selectDoubleDiplome);
  const setCursusFifth = useSetRecoilState(cursusFifthYear);
  const setSelectDaFourth = useSetRecoilState(selectDaFourthYear);
  const setisTBS = useSetRecoilState(isDoingTBS);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);


  const valueTextCursus =
    cursusId && current_page > 3
      ? textTranslation[language][cursusId][current_page]
      : textTranslation[language]["general"][current_page];
  return (
    <div className="col-sm-12 px-0">
      <div className="row text-center px-4">
        {valueTextCursus.options.map((opt, key) => (
          <Card
            key={key}
            className="home-card p-2 mt-3 fw-bold text-white cursor"
            style={{
              backgroundColor: `${key === selectDD ? opt.background + '99' : ""}`
            }}
            onClick={() => {
              if (key !== selectDD) {
                setCursusFifth("");
                setSelectDaFourth("");
              }
              if (opt.logo === "tbs") {
                setisTBS(1);
              } else {
                setisTBS("");
              }
              setSelectDD(key);
            }}
          >
            {" "}
            <Card.Body className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="image-card-bg"
                  style={{
                    backgroundColor: `${opt.background}33`,
                  }}>
                  <img src={switchDisplayLogo(opt.logo)} alt="..." className="" />
                </div>
                <Card.Text className="m-0 p-2 text-start">{opt.title}</Card.Text>
              </div>
              {(opt.tooltipTitle || opt.tooltip) && (
                <div>
                  <img
                    src={switchDisplayLogo("tooltip")}
                    alt="tooltip"
                    onClick={() => (setShow(key))}
                    className='cursor'
                  />
                  <Modal
                    show={show === key}
                    onHide={handleClose}
                    animation={true}
                    centered
                    size='lg'
                    dialogClassName='popin-bg'
                    contentClassName='popin-content'
                  >
                    <Modal.Header className='border-0'>
                      <Modal.Title className='fw-bold'
                        as={"p"}
                        dangerouslySetInnerHTML={{ __html: stylizeJsonText(opt.tooltipTitle) }}
                      />
                      <MdClose size={27} color='#FFC43E' className='cursor' onClick={() => setShow(false)} />
                    </Modal.Header>
                    <Modal.Body
                      className='pt-0'
                      dangerouslySetInnerHTML={{ __html: stylizeJsonText(opt.tooltip) }}
                    />
                  </Modal>
                </div>
              )}
            </Card.Body>
          </Card>
        ))}
        {selectDD !== "" && valueTextCursus.options[selectDD].paragraph ? (
          <Card className="home-card p-3 mt-3 text-white text-start">
            <Card.Body>
              <Card.Text dangerouslySetInnerHTML={{ __html: stylizeJsonText(valueTextCursus.options[selectDD].paragraph) }}
              />
            </Card.Body>
          </Card>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
