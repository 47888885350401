import { useRecoilState, useRecoilValue } from "recoil";
import {
  currentPage,
  languageSelected,
  cursusSelected,
  cursusThirdYear,
  cursusFourthYear,
  afterDegree,
  cursusIdSelected,
  engineeringCourse,
  email,
  rgpd,
  errorPdf,
  lname,
  fname,
  isDoingTBS,
} from "../recoil/Atomes";
import { switchDisplayImage, switchDisplayLogo } from "./Pictures";
import { stylizeJsonText } from "../services/stylizeJsonText";
import { textTranslation } from "../config/Multilangues";
import { Card, InputGroup, Form } from "react-bootstrap";
import { MdTaskAlt } from "react-icons/md";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";
import ReactConfetti from 'react-confetti'
//  Le component qui est utilisé pour afficher les dernières pages jusqu'à l'envoei du formulaire
export const GraduationComponent = () => {
  const cursusId = useRecoilValue(cursusIdSelected);
  const current_page = useRecoilValue(currentPage);
  const language = useRecoilValue(languageSelected);
  const cursus = useRecoilValue(cursusSelected);
  const cursusThird = useRecoilValue(cursusThirdYear);
  const cursusFourth = useRecoilValue(cursusFourthYear);
  const engineerCourse = useRecoilValue(engineeringCourse);
  const isTBS = useRecoilValue(isDoingTBS);

  const [afDegree, setAfterDegree] = useRecoilState(afterDegree);
  const [mail, setEmail] = useRecoilState(email);
  const [checked, setRgpd] = useRecoilState(rgpd);
  const [lastname, setLastname] = useRecoilState(lname);
  const [firstname, setFirstname] = useRecoilState(fname);

  const errorMessage = useRecoilValue(errorPdf);
  const valueTextCursus =
    cursusId && current_page > 3
      ? textTranslation[language][cursusId][current_page]
      : textTranslation[language]["general"][current_page];
  console.log(valueTextCursus)
  return (
    <>
    {/* Page félicitant l'élève */}
      {current_page === 13 && (
        <div className="col-sm-12 px-4">
          <ReactConfetti numberOfPieces={150} width={window.innerWidth} height={window.innerHeight} />
          <div className="d-flex justify-content-around p-0 px-4 fw-bold text-white">
            {
              <img
                src={switchDisplayImage(valueTextCursus.starLeft)}
                alt="..."
                className="img-fluid"
              />
            }
            <p>{valueTextCursus.diploma}</p>
            {
              <img
                src={switchDisplayImage(valueTextCursus.starRight)}
                alt="..."
                className="img-fluid"
              />
            }
          </div>

          <Card className="home-card mt-3 p-3 text-white">
            <Card.Body className="d-flex justify-content-between align-items-center text-start">
              <Card.Text dangerouslySetInnerHTML={{
                __html: stylizeJsonText(cursus === 0
                  ? cursusThird === 0
                    ? valueTextCursus.paragraph[cursusThird][cursusFourth]
                    : valueTextCursus.paragraph[cursusThird]
                  : isTBS
                    ? valueTextCursus.tbsParagraph
                    : valueTextCursus.paragraph[engineerCourse])
              }}>

              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      )}
      {/* Page de sélection du parcours après l'école de Purpan */}
      {current_page === 14 && (
        <div className="col-sm-12 px-4">
          {valueTextCursus.options.map((opt, key) => (
            <Card
              key={key}
              className="home-card p-2 mt-3 fw-bold text-white cursor"
              style={{
                backgroundColor: `${key === afDegree ? opt.background + '99' : ""}`
              }}
              onClick={() => {
                setAfterDegree(key);
              }}
            >
              <Card.Body className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div
                    className="image-card-bg"
                    style={{
                      backgroundColor: `${opt.background}33`,
                    }}>
                    <img
                      src={switchDisplayLogo(opt.logo)}
                      alt="..."
                      className=""
                    />
                  </div>
                  <Card.Text className="m-0 p-2 text-start">
                    {opt.title}
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>
          ))}
          <div>
            {afDegree !== "" && valueTextCursus.options[afDegree].paragraph ? (
              <div className="col-sm-12">
                <Card className="home-card mt-3 p-3 text-white">
                  <Card.Body>
                    <Card.Text className="text-start" dangerouslySetInnerHTML={{ __html: stylizeJsonText(valueTextCursus.options[afDegree].paragraph) }} />
                  </Card.Body>
                </Card>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      {/* Page du formulaire */}
      {current_page === 15 && (
        <>
          <div className="col-sm-12 px-4">
            <Card className="home-card mt-3 p-3">
              <Card.Body>
                <Card.Title className="mb-2 fs-4 text-start home-card-title-color-a">
                  {valueTextCursus.title}
                </Card.Title>
                <Card.Text className="text-start text-white" dangerouslySetInnerHTML={{ __html: stylizeJsonText(valueTextCursus.paragraph) }} />
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-12 px-4">
            <Card className="home-card mt-3 p-3">
              <Card.Body>
                <div className="text-danger">{errorMessage}</div>
                <InputGroup>
                  <Form.Control
                    bsPrefix="my-form-input"
                    type="text"
                    placeholder="Nom"
                    value={lastname}
                    required
                    name="lastname"
                    onChange={(e) => setLastname(e.target.value)}
                  />
                </InputGroup>
                <InputGroup className="mt-1">
                  <Form.Control
                    bsPrefix="my-form-input"
                    type="text"
                    placeholder="Prénom"
                    value={firstname}
                    required
                    name="firstname"
                    onChange={(e) => setFirstname(e.target.value)}
                  />
                </InputGroup>
                <InputGroup className="mt-1">
                  <Form.Control
                    bsPrefix="my-form-input"
                    type="email"
                    placeholder="Email"
                    value={mail}
                    pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                    as={"input"}
                    required
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
                <Form.Group>
                  <Form.Check
                    bsPrefix="my-form-checkbox"
                    className="d-flex mt-3"
                    required
                    type="checkbox"
                    label={"RGPD"}
                  >
                    <FormCheckInput
                      type="checkbox"
                      className="col-sm-12 cursor"
                      onChange={() => setRgpd(!checked)}
                      id="rgpd"
                      checked={checked}
                    ></FormCheckInput>
                    <FormCheckLabel className="text-start w-100">
                      <p>J’accepte de recevoir des informations sur les cursus de l’École d’Ingénieurs de PURPAN.</p>
                      <p style={{ fontSize: 10 }}>NB : Les données recueillies sont enregistrées dans un fichier informatisé par l’École d’Ingénieurs de PURPAN et destinées uniquement à son équipe communication, et, ce pendant 2 ans. Vous pouvez demander leur effacement anticipé par mail à
<a style={{color: "#FFC43E"}} href="mailto: communication@purpan.fr"> communication@purpan.fr</a>
                      </p>
                    </FormCheckLabel>
                  </Form.Check>
                </Form.Group>
              </Card.Body>
            </Card>
          </div>
        </>
      )}
      {current_page === 16 && (
        <div className="col-sm-12 px-4">
          <Card className="text-white home-card px-5 py-3 my-5">
            <Card.Body className="">
              <Card.Title className="d-flex flex-column fw-bold">
                <MdTaskAlt size={62} className="mx-auto" />
                <p className="fs-3 mt-2">{valueTextCursus.subtitle}</p>
              </Card.Title>
              <Card.Text>{valueTextCursus.paragraph}</Card.Text>
            </Card.Body>
          </Card>
        </div>
      )}
    </>
  );
};
