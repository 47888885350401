import { useRecoilValue } from "recoil";
import {
  currentPage,
  languageSelected,
  cursusIdSelected,
  cursusThirdYear,
  cursusFourthYear,
  engineeringCourse,
} from "../recoil/Atomes";
import { textTranslation } from "../config/Multilangues";
import { Card } from "react-bootstrap";
import { switchDisplayLogo } from "./Pictures";

// Le component qui définie le parcours réalisé en 4ème par l'étudiant
export const DisplayEngineerCourse = () => {
  const cursusId = useRecoilValue(cursusIdSelected);
  const cursusThird = useRecoilValue(cursusThirdYear);
  const cursusFourth = useRecoilValue(cursusFourthYear);
  const engineerCourse = useRecoilValue(engineeringCourse);
  const current_page = useRecoilValue(currentPage);
  const language = useRecoilValue(languageSelected);

  const valueTextCursus =
    cursusId && current_page > 3
      ? textTranslation[language][cursusId][current_page]
      : textTranslation[language]["general"][current_page];
  return (
    <div className="col-sm-12">
      <div className="row text-center px-4">
        <Card className="home-card mt-3 px-3 py-2 fw-bold text-white"
        style={{
          backgroundColor: `${valueTextCursus[cursusFourth || cursusThird][engineerCourse]
            .background + '99'}`
        }}>
          <Card.Body className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div className="image-card-bg"
              style={{
                backgroundColor: `${valueTextCursus[cursusFourth || cursusThird][engineerCourse]
                  .background}33`,
              }}>
                <img
                  src={switchDisplayLogo(
                    valueTextCursus[cursusFourth || cursusThird][engineerCourse]
                      .logo
                  )}
                  alt="..."
                  className=""
                />
              </div>
              <Card.Text className="m-0 p-2 w-75 text-start">
                {
                  valueTextCursus[cursusFourth || cursusThird][engineerCourse]
                    .title
                }
              </Card.Text>
            </div>
          </Card.Body>
        </Card>
        {valueTextCursus[cursusFourth || cursusThird][engineerCourse] &&
        valueTextCursus[cursusFourth || cursusThird][engineerCourse]
          .paragraph ? (
          <Card className="home-card mt-3 px-3 py-2 fw-bold text-white">
            <Card.Body>
              <Card.Text>
                {
                  valueTextCursus[cursusFourth || cursusThird][engineerCourse]
                    .paragraph
                }
              </Card.Text>
            </Card.Body>
          </Card>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
