import React from "react";
import AgroBachelorMap from "../components/AgroBachelorMap";
import EngineerMap from "../components/EngineerMap";
import BottomBar from "./BottomBar";
import { useRecoilValue } from "recoil";
import {
  languageSelected,
  cursusSelected,
} from "../recoil/Atomes";

export default function Maps() {
  const language = useRecoilValue(languageSelected);
  const cursus_selected = useRecoilValue(cursusSelected);


  return (
    <div style={{ height: "100vh", backgroundColor: "#305549" }}>
      {cursus_selected === 0 ?
        <AgroBachelorMap
          language={language}
        />
        : cursus_selected === 1 ?
          <EngineerMap
            language={language}
          /> : ""
      }
      <BottomBar />
    </div>
  );
}
